import React from 'react';
import image from '../../assets/images/dummyImage.png';
import { useFormik } from 'formik';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import classNames from 'classnames';
import { Dropdown } from 'primereact/dropdown';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { MultiSelect } from 'primereact/multiselect';

function RolesAndRights() {
    const formik = useFormik({
        initialValues: {
            officeId: '',
            name: '',
            role: '',
            routes: ''
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const rolesLov = [
        {
            label: 'User',
            value: '1'
        },
        {
            label: 'Admin',
            value: '2'
        },
        {
            label: 'Super Admin',
            value: '3'
        }
    ];

    const routesList = [
        {
            label: 'Dashboard',
            value: '1'
        },
        {
            label: 'Annexure',
            value: '2'
        },
        {
            label: 'Project PNL',
            value: '3'
        },
        {
            label: 'Resource PNL',
            value: '4'
        },
        {
            label: 'Resource Data',
            value: '5'
        },
        {
            label: 'Consolidation Data',
            value: '6'
        },
        {
            label: 'Role and Rights',
            value: '7'
        }
    ];

    const operationList = [
        {
            label: 'View Only',
            value: '1'
        },
        {
            label: 'Add',
            value: '2'
        },
        {
            label: 'Edit',
            value: '3'
        },
        {
            label: 'Delete',
            value: '4'
        }
    ];
    return (
        <>
            <div className="card Card__Round">
                <h4 className="mt-3">Update Roles and Rights</h4>
            </div>
            <div className="card">
                <div className="grid">
                    <div className="col-12">
                        <div className="Card__Round">
                            <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                                <div className="p-fluid p-formgrid grid mb-5">
                                    <div className="p-field col-6 md:col-3">
                                        <div className="p-field">
                                            <label htmlFor="email" className={classNames({ 'p-error': isFormFieldValid('email') }, 'Label__Text')}>
                                                Email<span className="Label__Required">*</span>
                                            </label>
                                            <InputText
                                                maxLength={20}
                                                id="email"
                                                placeholder="Please Enter Email"
                                                name="email"
                                                value={formik?.values?.email || ''}
                                                onChange={formik.handleChange}
                                                className={classNames({ 'p-invalid': isFormFieldValid('email') }, 'Input__Round')}
                                            />

                                            {getFormErrorMessage('email')}
                                        </div>
                                    </div>
                                    <div className="p-field col-6 md:col-3">
                                        <div className="p-field">
                                            <label htmlFor="role" className={classNames({ 'p-error': isFormFieldValid('role') }, 'Label__Text')}>
                                                Role<span className="Label__Required">*</span>
                                            </label>
                                            <MultiSelect
                                                options={rolesLov}
                                                optionLabel="label"
                                                optionValue="value"
                                                maxLength={20}
                                                id="role"
                                                placeholder="Please Select Role"
                                                name="role"
                                                value={formik?.values?.role || ''}
                                                onChange={formik.handleChange}
                                                className={classNames({ 'p-invalid': isFormFieldValid('role') }, 'Dropdown__Round')}
                                            />

                                            {getFormErrorMessage('role')}
                                        </div>
                                    </div>
                                    <div className="p-field col-6 md:col-3">
                                        <div className="p-field">
                                            <label htmlFor="routes" className={classNames({ 'p-error': isFormFieldValid('routes') }, 'Label__Text')}>
                                                Allowed Modules<span className="Label__Required">*</span>
                                            </label>
                                            <MultiSelect
                                                options={routesList}
                                                optionLabel="label"
                                                optionValue="value"
                                                maxLength={20}
                                                id="routes"
                                                placeholder="Please Select routes"
                                                name="routes"
                                                value={formik?.values?.routes || ''}
                                                onChange={formik.handleChange}
                                                className={classNames({ 'p-invalid': isFormFieldValid('routes') }, 'Dropdown__Round')}
                                            />

                                            {getFormErrorMessage('routes')}
                                        </div>
                                    </div>
                                    <div className="p-field col-6 md:col-3">
                                        <div className="p-field">
                                            <label htmlFor="permissions" className={classNames({ 'p-error': isFormFieldValid('permissions') }, 'Label__Text')}>
                                                Permissions<span className="Label__Required">*</span>
                                            </label>
                                            <MultiSelect
                                                options={operationList}
                                                optionLabel="label"
                                                optionValue="value"
                                                maxLength={20}
                                                id="permissions"
                                                placeholder="Please Select permissions"
                                                name="permissions"
                                                value={formik?.values?.permissions || ''}
                                                onChange={formik.handleChange}
                                                className={classNames({ 'p-invalid': isFormFieldValid('permissions') }, 'Dropdown__Round')}
                                            />

                                            {getFormErrorMessage('permissions')}
                                        </div>
                                    </div>
                                    <div className="p-field col-6 md:col-12">
                                        <div className="p-field flex justify-content-center">
                                            <Button label="Update Role" className="update__button mt-4" style={{ width: '300px' }} />
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default RolesAndRights;
