import { createSlice } from '@reduxjs/toolkit';

const slice = createSlice({
    name: 'showNotificationSlice',
    initialState: {
        notificationData: []
    },
    reducers: {
        NOTIFICATION_SUCCESS: (state, action) => {
            // Push the new notification data into the notificationData array
            state.notificationData.push(action.payload);
        },
        REMOVE_NOTIFICATION: (state, action) => {
            state.notificationData = state.notificationData.filter((notification) => notification.actionId !== action.payload);
        }
    }
});

export const { NOTIFICATION_SUCCESS, REMOVE_NOTIFICATION } = slice.actions;
export default slice.reducer;
