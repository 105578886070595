import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { handleGetRequest } from '../../services/GetTemplate';

const getLkpAccountLevelSlice = createSlice({
    name: "lkpAccountLevel",
    initialState: {
        isLoading: false,
        data: [],
        isError: false
    },
    extraReducers: (builder) => {
        builder.addCase(getLkpAccountLevel.pending, (state, action) => {
            state.isLoading = true;
        })
        builder.addCase(getLkpAccountLevel.fulfilled, (state, action) => {
            state.isLoading = false;
            state.data = action.payload;
        })
        builder.addCase(getLkpAccountLevel.rejected, (state, action) => {
            state.isError = true;
        })
    }
})

export const getLkpAccountLevel = createAsyncThunk("getLkpAccountLevel", async () => {
    const res = await handleGetRequest(`/configuration/v1/lookups/getaccounttypes/LKP_ACCOUNT_LEVEL:*`);
    return res?.payLoad;
});

export default getLkpAccountLevelSlice.reducer