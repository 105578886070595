import React, { useState, useEffect, useRef } from 'react';
import { classNames } from 'primereact/utils';
import { Route, Routes, useNavigate } from 'react-router-dom';

import AppTopbar from './AppTopbar';
import AppConfig from './AppConfig';
import AppRightMenu from './AppRightMenu';
import AppBreadcrumb from './AppBreadcrumb';
import AppMenu from './AppMenu';

import Dashboard from './components/Dashboard';
import Login from './pages/Login';
import PrimeReact from 'primereact/api';
import { Tooltip } from 'primereact/tooltip';

import '@fortawesome/fontawesome-free/css/all.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import './App.scss';
import './assets/fonts/fonts.css';

import ShowMessageDialog from './utilities/ShowMessageDialog';
import { useSelector, useDispatch } from 'react-redux';
import { showMessageAction } from './redux/actions/showMessageAction';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LoadingOverlay from 'react-loading-overlay';
import ProjectsStats from './pages/ProjectStats/ProjectsStats';
import Annexure from './pages/Annexure/Annexure';
import ResourceData from './pages/ResourceData/ResourceData';
import ConsolidatedData from './pages/ConsolidatedData/ConsolidatedData';
import ResourcePNL from './pages/ResourcePNL/ResourcePNL';
import RolesAndRights from './pages/RolesandRights/RolesAndRights';
import Projects from './pages/Projects/Projects';
import Finance from './pages/Finance/Finance';
import GeneralData from './pages/GeneralData/GeneralData';

const App = (props) => {
    const [userMenu, setUserMenu] = useState(JSON.parse(sessionStorage.getItem('menu')));
    const [path, setPath] = useState(window?.location?.href?.split('/#')?.pop());
    // const [foundMenu, setFoundMenu] = useState(null);
    const [rightMenuActive, setRightMenuActive] = useState(false);
    const [configActive, setConfigActive] = useState(false);
    const [menuMode, setMenuMode] = useState('sidebar');
    const [overlayMenuActive, setOverlayMenuActive] = useState(false);
    const [ripple, setRipple] = useState(true);
    const [sidebarStatic, setSidebarStatic] = useState(false);
    const [staticMenuDesktopInactive, setStaticMenuDesktopInactive] = useState(false);
    const [staticMenuMobileActive, setStaticMenuMobileActive] = useState(false);
    const [menuActive, setMenuActive] = useState(false);
    const [searchActive, setSearchActive] = useState(false);
    const [topbarMenuActive, setTopbarMenuActive] = useState(false);
    const [sidebarActive, setSidebarActive] = useState(false);
    const [pinActive, setPinActive] = useState(false);
    const [activeInlineProfile, setActiveInlineProfile] = useState(false);
    const [resetActiveIndex, setResetActiveIndex] = useState(null);
    const [token, setToken] = useState(false);

    const copyTooltipRef = useRef();

    const navigate = useNavigate();
    const dispatch = useDispatch();

    require('dotenv').config();

    PrimeReact.ripple = true;
    LoadingOverlay.propTypes = undefined;

    const isLoading = useSelector((state) => state?.utilitySlice?.isLoading);
    const auth = useSelector((state) => state?.authenticationSlice?.token);
    const isNewUser = sessionStorage.getItem('isNewUser');

    // Response Dialog Data
    const showModal = useSelector((state) => state?.showMessageSlice?.showModal);
    const message = useSelector((state) => state?.showMessageSlice?.message);
    const isError = useSelector((state) => state?.showMessageSlice?.isError);
    const url = useSelector((state) => state?.showMessageSlice?.url);

    const menu = [
        {
            label: 'Dashboard',
            icon: 'pi pi-home',
            to: '/'
        },
        {
            label: 'Annexure',
            icon: 'pi pi-calculator',
            to: '/annexure'
        },
        {
            label: 'Project PNL',
            icon: 'pi pi-chart-line',
            to: '/projectstats'
        },
        {
            label: 'Resource PNL',
            icon: 'pi pi-chart-bar',
            to: '/resourcepnl'
        },
        {
            label: 'Resource Data',
            icon: 'pi pi-user',
            to: '/resources'
        },
        {
            label: 'Projects',
            icon: 'pi pi-tablet',
            to: '/projects'
        },
        {
            label: 'Consolidated Data',
            icon: 'pi pi-book',
            to: '/consolidateddata'
        },
        {
            label: 'Roles and Rights',
            icon: 'pi pi-users',
            to: '/roles'
        },
        {
            label: 'Finance',
            icon: 'pi pi-dollar',
            to: '/finance'
        },
        {
            label: 'General Data',
            icon: 'pi pi-plus',
            to:'/generalData'
        }
    ];

    const routes = [{ label: 'Dashboard' }, { label: 'Users Data' }, { label: 'Add Point Redemption' }, { label: 'Edit Point Redemption' }, { label: 'View Point Redemption' }, { label: 'Edit Zmiles Voucher' }];

    let rightMenuClick;

    let configClick;
    let menuClick;
    let searchClick = false;
    let topbarItemClick;

    useEffect(() => {
        setResetActiveIndex(true);
        setMenuActive(false);
    }, [menuMode]); // eslint-disable-line react-hooks/exhaustive-deps

    const onDocumentClick = () => {
        if (!searchClick && searchActive) {
            onSearchHide();
        }

        if (!topbarItemClick) {
            setTopbarMenuActive(false);
        }

        if (!menuClick) {
            if (isHorizontal() || isSlim()) {
                setMenuActive(false);
                setResetActiveIndex(true);
            }

            if (overlayMenuActive || staticMenuMobileActive) {
                setOverlayMenuActive(false);
                setStaticMenuMobileActive(false);
            }

            hideOverlayMenu();
            unblockBodyScroll();
        }

        if (!rightMenuClick) {
            setRightMenuActive(false);
        }

        if (configActive && !configClick) {
            setConfigActive(false);
        }

        topbarItemClick = false;
        menuClick = false;
        configClick = false;
        rightMenuClick = false;
        searchClick = false;
    };

    const onSearchHide = () => {
        setSearchActive(false);
        searchClick = false;
    };

    const onMenuModeChange = (menuMode) => {
        setMenuMode(menuMode);
        setOverlayMenuActive(false);
    };

    const onRightMenuButtonClick = () => {
        rightMenuClick = true;
        setRightMenuActive(true);
    };

    const onRightMenuClick = () => {
        rightMenuClick = true;
    };

    const onRightMenuActiveChange = (active) => {
        setRightMenuActive(active);
    };

    const onConfigClick = () => {
        configClick = true;
    };

    const onConfigButtonClick = (event) => {
        setConfigActive((prevState) => !prevState);
        configClick = true;
        event.preventDefault();
    };

    const onRippleChange = (e) => {
        PrimeReact.ripple = e.value;
        setRipple(e.value);
    };

    const onMenuButtonClick = (event) => {
        menuClick = true;

        if (isOverlay()) {
            setOverlayMenuActive((prevState) => !prevState);
        }

        if (isDesktop()) {
            setStaticMenuDesktopInactive((prevState) => !prevState);
        } else {
            setStaticMenuMobileActive((prevState) => !prevState);
        }

        event.preventDefault();
    };

    const hideOverlayMenu = () => {
        setOverlayMenuActive(false);
        setStaticMenuMobileActive(false);
    };

    const onTopbarItemClick = (event) => {
        topbarItemClick = true;
        setTopbarMenuActive((prevState) => !prevState);
        hideOverlayMenu();
        event.preventDefault();
    };

    const onToggleMenu = (event) => {
        menuClick = true;

        if (overlayMenuActive) {
            setOverlayMenuActive(false);
        }

        if (sidebarActive) {
            setSidebarStatic((prevState) => !prevState);
        }

        event.preventDefault();
    };

    const onSidebarMouseOver = () => {
        if (menuMode === 'sidebar' && !sidebarStatic) {
            setSidebarActive(isDesktop());
            setTimeout(() => {
                setPinActive(isDesktop());
            }, 200);
        }
    };

    const onSidebarMouseLeave = () => {
        if (menuMode === 'sidebar' && !sidebarStatic) {
            setTimeout(() => {
                setSidebarActive(false);
                setPinActive(false);
            }, 250);
        }
    };

    const onMenuClick = () => {
        menuClick = true;
    };

    const onChangeActiveInlineMenu = (event) => {
        setActiveInlineProfile((prevState) => !prevState);
        event.preventDefault();
    };

    const onRootMenuItemClick = () => {
        setMenuActive((prevState) => !prevState);
    };

    const onMenuItemClick = (event) => {
        if (!event.item.items) {
            hideOverlayMenu();
            setResetActiveIndex(true);
        }

        if (!event.item.items && (isHorizontal() || isSlim())) {
            setMenuActive(false);
        }
    };

    const isHorizontal = () => {
        return menuMode === 'horizontal';
    };

    const isSlim = () => {
        return menuMode === 'slim';
    };

    const isOverlay = () => {
        return menuMode === 'overlay';
    };

    const isDesktop = () => {
        return window.innerWidth > 991;
    };

    const onInputClick = () => {
        searchClick = true;
    };

    const breadcrumbClick = () => {
        searchClick = true;
        setSearchActive(true);
    };

    const unblockBodyScroll = () => {
        if (document.body.classList) {
            document.body.classList.remove('blocked-scroll');
        } else {
            document.body.className = document.body.className.replace(new RegExp('(^|\\b)' + 'blocked-scroll'.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
        }
    };

    const layoutClassName = classNames('layout-wrapper', {
        'layout-static': menuMode === 'static',
        'layout-overlay': menuMode === 'overlay',
        'layout-overlay-active': overlayMenuActive,
        'layout-slim': menuMode === 'slim',
        'layout-horizontal': menuMode === 'horizontal',
        'layout-active': menuActive,
        'layout-mobile-active': staticMenuMobileActive,
        'layout-sidebar': menuMode === 'sidebar',
        'layout-sidebar-static': menuMode === 'sidebar' && sidebarStatic,
        'layout-static-inactive': staticMenuDesktopInactive && menuMode === 'static',
        'p-ripple-disabled': !ripple
    });


    const handleShowModal = () => {
        dispatch(showMessageAction({ isShowModal: false, message: '', isError }));
    };

    const loadUrl = (url) => {
        navigate(url);
    };

    const dialogProps = {
        showModal,
        message,
        isError,
        handleShowModal,
        loadUrl,
        url
    };

    useEffect(() => {
        const tok = localStorage.getItem('userInfo');
        setToken(tok);
    }, [token]);
    return (
        <div className={layoutClassName} onClick={onDocumentClick}>
            <LoadingOverlay active={isLoading} spinner text="Please Wait ...">
                <Tooltip ref={copyTooltipRef} target=".block-action-copy" position="bottom" content="Copied to clipboard" event="focus" />
                <ShowMessageDialog {...dialogProps} />
                {/* <NotificationComponent /> */}
                <ToastContainer />
                {token === null ? (
                    <Routes>
                        <Route path="/" element={<Login />} />
                    </Routes>
                ) : (
                    <div className="layout-main">
                        <AppTopbar
                            items={menu}
                            menuMode={menuMode}
                            colorScheme={props.colorScheme}
                            menuActive={menuActive}
                            topbarMenuActive={topbarMenuActive}
                            activeInlineProfile={activeInlineProfile}
                            onTopbarItemClick={onTopbarItemClick}
                            onMenuButtonClick={onMenuButtonClick}
                            onSidebarMouseOver={onSidebarMouseOver}
                            onSidebarMouseLeave={onSidebarMouseLeave}
                            onToggleMenu={onToggleMenu}
                            onChangeActiveInlineMenu={onChangeActiveInlineMenu}
                            onMenuClick={onMenuClick}
                            onMenuItemClick={onMenuItemClick}
                            onRootMenuItemClick={onRootMenuItemClick}
                            resetActiveIndex={resetActiveIndex}
                        />

                        <AppMenu
                            model={menu}
                            onRootMenuItemClick={onRootMenuItemClick}
                            onMenuItemClick={onMenuItemClick}
                            onToggleMenu={onToggleMenu}
                            onMenuClick={onMenuClick}
                            menuMode={menuMode}
                            colorScheme={props.colorScheme}
                            menuActive={menuActive}
                            sidebarActive={sidebarActive}
                            sidebarStatic={sidebarStatic}
                            pinActive={pinActive}
                            onSidebarMouseLeave={onSidebarMouseLeave}
                            onSidebarMouseOver={onSidebarMouseOver}
                            activeInlineProfile={activeInlineProfile}
                            onChangeActiveInlineMenu={onChangeActiveInlineMenu}
                            resetActiveIndex={resetActiveIndex}
                        />

                        <AppBreadcrumb
                            routes={routes}
                            onMenuButtonClick={onMenuButtonClick}
                            menuMode={menuMode}
                            onRightMenuButtonClick={onRightMenuButtonClick}
                            onInputClick={onInputClick}
                            searchActive={searchActive}
                            breadcrumbClick={breadcrumbClick}
                        />

                        <div className="layout-main-content">
                            <Routes>
                                <Route path="/" exact="true" element={<Dashboard />} />
                                {/* <Route path="/login" exact="true" element={<Login />} /> */}
                                <Route path="/projectstats" exact="true" element={<ProjectsStats />} />
                                <Route path="/annexure" exact="true" element={<Annexure />} />
                                <Route path="/resources" exact="true" element={<ResourceData />} />
                                <Route path="/consolidateddata" exact="true" element={<ConsolidatedData />} />
                                <Route path="/resourcepnl" exact="true" element={<ResourcePNL />} />
                                <Route path="/roles" exact="true" element={<RolesAndRights />} />
                                <Route path="/projects" exact="true" element={<Projects />} />
                                <Route path="/finance" exact="true" element={<Finance />} />
                                    <Route path="/generalData" exact='true' element={<GeneralData/>} />
                            </Routes>
                        </div>

                        {/* <AppFooter colorScheme={props.colorScheme} /> */}
                    </div>
                )}
                {/* )} */}
                <AppRightMenu rightMenuActive={rightMenuActive} onRightMenuClick={onRightMenuClick} onRightMenuActiveChange={onRightMenuActiveChange} />
                <AppConfig
                    configActive={configActive}
                    onConfigButtonClick={onConfigButtonClick}
                    onConfigClick={onConfigClick}
                    menuMode={menuMode}
                    changeMenuMode={onMenuModeChange}
                    colorScheme={props.colorScheme}
                    changeColorScheme={props.onColorSchemeChange}
                    theme={props.theme}
                    changeTheme={props.onMenuThemeChange}
                    componentTheme={props.componentTheme}
                    changeComponentTheme={props.onComponentThemeChange}
                    ripple={ripple}
                    onRippleChange={onRippleChange}
                />
            </LoadingOverlay>
        </div>
    );
};

export default App;
