import { useFormik } from 'formik';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import React from 'react';
import classNames from 'classnames';
import { InputTextarea } from 'primereact/inputtextarea';
import { useDispatch } from 'react-redux';
import { handlePostRequest } from '../../services/PostTemplate';
import { Dropdown } from 'primereact/dropdown';

function AddProjectDialog({ hideDialog }) {
    const dispatch = useDispatch()
    const formik = useFormik({
        initialValues: {
            employeeId: '75',
            projectName: '',
            description: '',
            startDate: '',
            endDate: '',
            description: '',
            pmoemployeeId:''
        },
        validate: (data) => {
            let errors = {}
            if (!data.projectName) {
                errors.projectName = 'This field is required';
            } else if (!data.description) {
                errors.description = 'This field is required';
            } else if (!data.startDate) {
                errors.startDate = 'This field is required';
            } else if (!data.endDate) {
                errors.endDate = 'This field is required';
            } else if (!data.pmoemployeeId) {
                errors.pmoemployeeId = 'This field is required';
            }
            return errors
        },
        onSubmit: async (data) => {
            const response = await dispatch(handlePostRequest(data, '/projects/saveProject', true));
            if (response.responseCode === 200) {
                hideDialog()
            }
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };
    return (
        <>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-6 md:col-12">
                            <div className="p-field">
                                <label htmlFor="projectName" className={classNames({ 'p-error': isFormFieldValid('projectName') }, 'Label__Text')}>
                                    Project Name<span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    maxLength={20}
                                    id="projectName"
                                    placeholder="Please Enter name"
                                    name="projectName"
                                    value={formik?.values?.projectName || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('projectName') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('projectName')}
                            </div>
                        </div>
                        <div className="p-field col-6 md:col-12">
                            <div className="p-field">
                                <label htmlFor="description" className={classNames({ 'p-error': isFormFieldValid('description') }, 'Label__Text')}>
                                    Description<span className="Label__Required">*</span>
                                </label>
                                <InputTextarea
                                    maxLength={600}
                                    id="description"
                                    placeholder="Please Enter Description"
                                    name="description"
                                    value={formik?.values?.description || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('description') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('description')}
                            </div>
                        </div>
                        <div className="p-field col-6 md:col-12">
                            <div className="p-field">
                                <label htmlFor="pmoemployeeId" className={classNames({ 'p-error': isFormFieldValid('pmoemployeeId') }, 'Label__Text')}>
                                    PMO<span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    options={[]}
                                    optionValue="value"
                                    optionLabel="label"
                                    maxLength={20}
                                    id="pmoemployeeId"
                                    placeholder="Please Assign Project PMO"
                                    name="pmoemployeeId"
                                    value={formik?.values?.pmoemployeeId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('pmoemployeeId') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('pmoemployeeId')}
                            </div>
                        </div>
                        <div className="p-field col-6 md:col-12">
                            <div className="p-field">
                                <label htmlFor="startDate" className={classNames({ 'p-error': isFormFieldValid('startDate') }, 'Label__Text')}>
                                    Start Date<span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    type="date"
                                    id="startDate"
                                    placeholder="Please Enter Start Date"
                                    name="startDate"
                                    value={formik?.values?.startDate || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('startDate') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('startDate')}
                            </div>
                        </div>
                        <div className="p-field col-6 md:col-12">
                            <div className="p-field">
                                <label htmlFor="endDate" className={classNames({ 'p-error': isFormFieldValid('endDate') }, 'Label__Text')}>
                                    End Date<span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    type="date"
                                    id="endDate"
                                    placeholder="Please Enter End Date"
                                    name="endDate"
                                    value={formik?.values?.endDate || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('endDate') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('endDate')}
                            </div>
                        </div>
                    </div>
                    <div className="flex justify-content-center">
                        <Button className="update__button" label="Add" type="submit" style={{ border: 'none' }} />
                        <Button className="cancel__button ml-2" label="Cancel" onClick={() => hideDialog()} />
                    </div>
                </form>
            </div>
        </>
    );
}

export default AddProjectDialog;
