import { toast } from 'react-toastify';
import { login } from '../../services/Auth';
import { LOGIN_SUCCESS, LOGIN_ERROR } from '../slices/authenticationSlice';

export const loginAction = (authData) => async (dispatch) => {
    const res = await login(authData);
    if (res?.responseCode === 200) {
        localStorage.setItem('userInfo', res?.token)
        window.location.reload()
    }
    else {
        return res;
    }
};
