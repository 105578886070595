import { createSlice } from '@reduxjs/toolkit';

const slice = createSlice({
    name: 'showMessageSlice',
    initialState: {
        showModal: false,
        message: '',
        isError: false,
        url: ''
    },
    reducers: {
        SETSHOWMESSAGE_SUCCESS: (state, action) => {
            return {
                ...state,
                showModal: action.payload?.isShowModal,
                message: action.payload?.message,
                isError: action.payload?.isError,
                url: action.payload?.url
            };
        }
    }
});

export const { SETSHOWMESSAGE_SUCCESS } = slice.actions;
export default slice.reducer;
