import { useFormik } from 'formik';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import React, { useEffect } from 'react';
import classNames from 'classnames';
import { handlePostRequest } from '../../services/PostTemplate';
import { useDispatch } from 'react-redux';

function AnnexureDialog({ editable, onHideDialog }) {
    const dispatch = useDispatch()
    const formik = useFormik({
        initialValues: {
            department: '',
            experience: '',
            totalHours: '',
            ratePerHour: ''
        },
        onSubmit: async (data) => {
            const response = await dispatch(handlePostRequest(data, '/annexure/saveannexure', true));
            if (response.responseCode === 200) {
                onHideDialog();
                setTimeout(() => {
                    window.location.reload();
                }, 1000);
            }
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    return (
        <>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-6 md:col-6">
                            <div className="p-field">
                                <label htmlFor="department" className={classNames({ 'p-error': isFormFieldValid('department') }, 'Label__Text')}>
                                    Department<span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    maxLength={20}
                                    id="department"
                                    placeholder="Please Enter Department Name"
                                    name="department"
                                    value={formik?.values?.department?.toString() || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('department') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('department')}
                            </div>
                        </div>
                        <div className="p-field col-6 md:col-6">
                            <div className="p-field">
                                <label htmlFor="experience" className={classNames({ 'p-error': isFormFieldValid('experience') }, 'Label__Text')}>
                                    Experience<span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    maxLength={20}
                                    id="experience"
                                    placeholder="Please Enter Experience"
                                    name="experience"
                                    value={formik?.values?.experience?.toString() || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('experience') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('experience')}
                            </div>
                        </div>
                        <div className="p-field col-6 md:col-6">
                            <div className="p-field">
                                <label htmlFor="totalHours" className={classNames({ 'p-error': isFormFieldValid('totalHours') }, 'Label__Text')}>
                                    Total Hours<span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    maxLength={20}
                                    id="totalHours"
                                    placeholder="Please Enter Total Hours"
                                    name="totalHours"
                                    value={formik?.values?.totalHours || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('totalHours') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('totalHours')}
                            </div>
                        </div>
                        <div className="p-field col-6 md:col-6">
                            <div className="p-field">
                                <label htmlFor="ratePerHour" className={classNames({ 'p-error': isFormFieldValid('ratePerHour') }, 'Label__Text')}>
                                    Rate Per Hour<span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    maxLength={20}
                                    id="ratePerHour"
                                    placeholder="Please Enter Rate Per Hour"
                                    name="ratePerHour"
                                    value={formik?.values?.ratePerHour || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('ratePerHour') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('ratePerHour')}
                            </div>
                        </div>
                    </div>
                    <div className="flex justify-content-center">
                        <Button className="update__button" label="Add" type="submit" style={{ border: 'none' }} />
                        <Button className="cancel__button ml-2" label="Cancel" onClick={() => onHideDialog()} />
                    </div>
                </form>
            </div>
        </>
    );
}

export default AnnexureDialog;
