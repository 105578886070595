import { useFormik } from "formik";
import { InputText } from "primereact/inputtext";
import React, { useEffect } from "react";
import classNames from "classnames";
import { Button } from "primereact/button";
import * as Yup from 'yup'
import { useDispatch } from "react-redux";
import { handlePostRequest } from "../../services/PostTemplate";

function AdditionDialog({ onHideAdditionDialog, dialogData }) {
    console.log(dialogData)

    
    const dispatch= useDispatch()
    const validationSchema = Yup.object().shape({
        month: Yup.string().required('This fiels is required')
    });
    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            incrementFTM: null,
            incrementActual: null,
            bonus: null,
            overTime: null,
            arrears: null,
            month: ''
        },
        onSubmit: async(data)=>{
            data['employeeId'] = dialogData?.id
             const date = new Date(data?.month);
             const month = date.getMonth() + 1;
             const year = date.getFullYear();
             data['month'] = `${month}/${year}`;
            const response = await dispatch(handlePostRequest(data, '/payroll/savesalaryaddition', true));
             if (response?.responseCode === 200) {
                 setTimeout(() => {
                     onHideAdditionDialog();
                     window.location.reload();
                }, 2000)
             }
        }
    });
    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };
    const mapValues = () => {
        if (dialogData?.additions) {
            formik.setFieldValue('bonus', dialogData?.additions?.bonus)
            formik.setFieldValue('arrears', dialogData?.additions?.arrears)
            formik.setFieldValue('incrementFTM', dialogData?.additions?.incrementFTM);
            formik.setFieldValue('incrementActual', dialogData?.additions?.incrementActual);
            formik.setFieldValue('overTime', dialogData?.additions?.overTime);
        }
    }
    useEffect(() => {
        mapValues()
    }, [dialogData])
    return (
        <>
            <div className="card">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-12">
                            <div className="p-field mb-2">
                                <label htmlFor="incrementFTM" className={classNames({ 'p-error': isFormFieldValid('incrementFTM') }, 'Label__Text')}>
                                    Increment for Month
                                </label>
                                <InputText
                                    keyfilter="int"
                                    maxLength={6}
                                    id="incrementFTM"
                                    placeholder="Please Enter Increment for Month"
                                    name="incrementFTM"
                                    onChange={formik.handleChange}
                                    value={formik?.values?.incrementFTM || ''}
                                    className={classNames({ 'p-invalid': isFormFieldValid('incrementFTM') }, 'Input__Round')}
                                />
                            </div>
                            <div className="p-field mb-2">
                                <div className="p-field">
                                    <label htmlFor="incrementActual" className={classNames({ 'p-error': isFormFieldValid('incrementActual') }, 'Label__Text')}>
                                        Actual Increment
                                    </label>
                                    <InputText
                                        keyfilter="int"
                                        maxLength={6}
                                        id="incrementActual"
                                        placeholder="Please Enter Actual Increment"
                                        name="incrementActual"
                                        value={formik?.values?.incrementActual || ''}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('incrementActual') }, 'Input__Round')}
                                    />
                                </div>
                            </div>
                            <div className="p-field mb-2">
                                <div className="p-field">
                                    <label htmlFor="bonus" className={classNames({ 'p-error': isFormFieldValid('bonus') }, 'Label__Text')}>
                                        Bonus
                                    </label>
                                    <InputText
                                        keyfilter="int"
                                        maxLength={6}
                                        id="bonus"
                                        placeholder="Please Enter Bonus"
                                        name="bonus"
                                        value={formik?.values?.bonus || ''}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('bonus') }, 'Input__Round')}
                                    />
                                </div>
                            </div>
                            <div className="p-field mb-2">
                                <div className="p-field">
                                    <label htmlFor="overTime" className={classNames({ 'p-error': isFormFieldValid('overTime') }, 'Label__Text')}>
                                        Over Time
                                    </label>
                                    <InputText
                                        keyfilter="int"
                                        maxLength={6}
                                        id="overTime"
                                        placeholder="Please Enter Overtime"
                                        name="overTime"
                                        value={formik?.values?.overTime || ''}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('overTime') }, 'Input__Round')}
                                    />
                                </div>
                            </div>
                            <div className="p-field mb-2">
                                <div className="p-field">
                                    <label htmlFor="arrears" className={classNames({ 'p-error': isFormFieldValid('arrears') }, 'Label__Text')}>
                                        Arrears
                                    </label>
                                    <InputText
                                        keyfilter="int"
                                        maxLength={6}
                                        id="arrears"
                                        placeholder="Please Enter Arrears"
                                        name="arrears"
                                        value={formik?.values?.arrears || ''}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('arrears') }, 'Input__Round')}
                                    />
                                </div>
                            </div>
                            <div className="p-field mb-2">
                                <div className="p-field">
                                    <label htmlFor="month" className={classNames({ 'p-error': isFormFieldValid('month') }, 'Label__Text')}>
                                        Select Month
                                    </label>
                                    <InputText
                                        type="date"
                                        id="month"
                                        placeholder="Please Enter month"
                                        name="month"
                                        value={formik?.values?.month || ''}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('month') }, 'Input__Round')}
                                    />
                                </div>
                                {getFormErrorMessage('month')}
                            </div>
                        </div>
                    </div>
                    <div className="flex justify-content-center">
                        <Button className="update__button" label="Save" type="submit" style={{ border: 'none' }} />
                        <Button
                            className="cancel__button ml-2"
                            label="Cancel"
                              onClick={(e) => {
                                  e.preventDefault();
                                  onHideAdditionDialog();
                              }}
                        />
                    </div>
                </form>
            </div>
        </>
    );
}

export default AdditionDialog;
