import { useFormik } from 'formik';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { Dropdown } from 'primereact/dropdown';
import { handleGetRequest } from '../../services/GetTemplate';
import { toast } from 'react-toastify';
import { handlePostRequest } from '../../services/PostTemplate';
import { useDispatch } from 'react-redux';

function AssignResourceDialog({ hideResourceDialog }) {
    const [departmentList, setDepartmentList] = useState()
    const [employeesList, setEmployeeList] = useState()
    const [projectsList, setProjectsList] = useState()
    const [hours, setHours] = useState()
    
    const dispatch = useDispatch()

    const formik = useFormik({
        initialValues: {
            employeeId: '',
            departmentId: '',
            allocatedHours: '',
            projectId: '',
            isActive:true
        },
        onSubmit: async (data) => {
            delete data['departmentId']
            const response = await dispatch( handlePostRequest(data, '/projects/assignemployee', true))

        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };
    
    const getAllDepartments = async () => {
        const response = await handleGetRequest('/list/alldepartments', true);
        setDepartmentList(response?.data)
    }
    const getDepartmentalEmployees = async () => {
        const response = await handleGetRequest(`/list/allemployees/${formik.values.department}`)
        setEmployeeList(response?.data)
    }
    const getProjectsById = async () => {
        const response = await handleGetRequest(`/list/projects/75`);
        setProjectsList(response?.data)
    }
    const getEmployeeHours = async () => {
        const response = await handleGetRequest(`/list/employees/hours/${formik.values.employeeName}`);
        if (response) {
            setHours(response?.data)
            formik.setFieldValue('allocatedHours', response?.data);
        }
    }

    useEffect(() => {
        getAllDepartments()
        getProjectsById()
    }, [])
    useEffect(() => {
        if (formik.values.department) {
            getDepartmentalEmployees();
       }
    }, [formik.values.department])
    useEffect(() => {
        if (formik.values.employeeName) {
            getEmployeeHours();
        }
    }, [formik.values.employeeName])

    useEffect(() => {
        if (formik.values.allocatedHours > hours) {
            toast.warn('Can not assign hours more than available')
        }
    }, [formik.values.allocatedHours])

    
    return (
        <>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-12">
                            <div className="p-field">
                                <label htmlFor="department" className={classNames({ 'p-error': isFormFieldValid('department') }, 'Label__Text')}>
                                    Department<span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    filter
                                    id="department"
                                    placeholder="Please select employee department"
                                    options={departmentList}
                                    optionLabel="departmentName"
                                    optionValue="id"
                                    name="department"
                                    value={formik.values.department || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('department') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('department')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-12">
                            <div className="p-field">
                                <label htmlFor="employeeId" className={classNames({ 'p-error': isFormFieldValid('employeeId') }, 'Label__Text')}>
                                    Employee Name<span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    filter
                                    id="employeeId"
                                    placeholder="Please select employee name"
                                    options={employeesList}
                                    optionLabel="employeeName"
                                    optionValue="employeeId"
                                    name="employeeId"
                                    value={formik.values.employeeId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('employeeId') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('employeeId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-12">
                            <div className="p-field">
                                <label htmlFor="projectId" className={classNames({ 'p-error': isFormFieldValid('projectId') }, 'Label__Text')}>
                                    Project<span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    filter
                                    id="projectId"
                                    placeholder="Please select project"
                                    options={projectsList}
                                    optionLabel="projectName"
                                    optionValue="id"
                                    name="projectId"
                                    value={formik.values.projectId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('projectId') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('projectId')}
                            </div>
                        </div>
                        <div className="p-field col-6 md:col-12">
                            <div className="p-field">
                                <label htmlFor="allocatedHours" className={classNames({ 'p-error': isFormFieldValid('allocatedHours') }, 'Label__Text')}>
                                    Allocate Hours<span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    maxLength={3}
                                    keyfilter="int"
                                    id="allocatedHours"
                                    placeholder="Please Enter Allocated Hours"
                                    name="allocatedHours"
                                    value={formik?.values?.allocatedHours || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('allocatedHours') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('allocatedHours')}
                            </div>
                        </div>
                    </div>
                    <div className="flex justify-content-center">
                        <Button className="update__button" label="Add" type='submit' style={{ border: 'none' }} />
                        <Button className="cancel__button ml-2" label="Cancel" onClick={() => hideResourceDialog()} />
                    </div>
                </form>
            </div>
        </>
    );
}

export default AssignResourceDialog;
