import { combineReducers } from 'redux';
import authenticationSlice from './slices/authenticationSlice';
import utilitySlice from './slices/utilitySlice';
import parserSlice from './slices/parserSlice';
import showMessageSlice from './slices/showMessageSlice';
import notificationSlice from './slices/notificationSlice';
import LkpStatusLovSlice from './slices/LkpStatusLovSlice';
import LkpAccountStatusSlice from './slices/LkpAccountStatusSlice';
import LkpAccountLevelSlice from './slices/LkpAccountLevelSlice';
import LkpCitySlice from './slices/LkpCitySlice';
import TblUserSlice from './slices/TblUserSlice';

export default combineReducers({
    authenticationSlice,
    utilitySlice,
    parserSlice,
    showMessageSlice,
    notificationSlice,
    LkpAccountStatusSlice,
    LkpAccountLevelSlice,
    LkpCitySlice,
    LkpStatusLovSlice,
    TblUserSlice
});
