import { createSlice } from '@reduxjs/toolkit';

const slice = createSlice({
    name: 'authenticationSlice',
    initialState: {
        // loginData: '',
        token: sessionStorage.getItem('token'),
        // menu: sessionStorage.getItem('menu'),
        // isNewUser: sessionStorage.getItem('isNewUser'),
        // userId: sessionStorage.getItem('userId'),
        // expiryTime: sessionStorage.getItem('expiryTime'),
        // loginAttempts: sessionStorage.getItem('loginAttempts'),
        // fullUserDetails: sessionStorage.getItem('fullUserDetails'),
        // userDetails: sessionStorage.getItem('userDetails')
        // isNewUser: '',
        // userId: ''
        // loggedIn: sessionStorage.getItem("loggedIn"),
    },
    reducers: {
        LOGIN_SUCCESS: (state, action) => {
            return {
                ...state,
                // loginData: action.payload,
                // token: sessionStorage.getItem('token'),
                menu: sessionStorage.getItem('menu'),
                isNewUser: sessionStorage.getItem('isNewUser'),
                userId: sessionStorage.getItem('userId'),
                expiryTime: sessionStorage.getItem('expiryTime'),
                loginAttempts: sessionStorage.getItem('loginAttempts'),
                fullUserDetails: sessionStorage.getItem('fullUserDetails'),
                userDetails: sessionStorage.getItem('userDetails')
                // isNewUser: sessionStorage.setItem('isNewUser', action?.payload?.tblAppUser?.passwordUpdateFlag),
                // userId: sessionStorage.setItem('userId', action?.payload?.tblUser?.userId)
                // loggedIn: sessionStorage.getItem("loggedIn"),
            };
        },
        LOGIN_ERROR: (state) => {
            return {
                ...state,
                // menu: '',
                // token: '',
                // isNewUser: '',
                // userId: '',
                // expiryTime: '',
                // loginAttempts: '',
                // fullUserDetails: '',
                // userDetails: ''
                // password: '',
                // email: ''
            };
        }
    }
});

export const { LOGIN_SUCCESS, LOGIN_ERROR } = slice.actions;
export default slice.reducer;
