import React, { useEffect, useState } from 'react';
import image from '../../assets/images/dummyImage.png';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { FilterMatchMode } from 'primereact/api';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import AnnexureDialog from './AnnexureDialog';
import { handleGetRequest } from '../../services/GetTemplate';

function Annexure() {
    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(5);
    const [showDialog,setShowDialog]=useState(false)
    const [globalFilter, setGlobalFilter] = useState('');
    const [editable, setEditable] = useState(false)
    const [annexureList , setAnnexureList] = useState()
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS }
    });

    const getAllAnnexure = async () => {
        const response = await handleGetRequest('/annexure/getAll', true);
        if (response.responseCode === 200) {
            setAnnexureList(response.data);
        }
    }

    useEffect(() => {
        getAllAnnexure()
    }, [])

     const onGlobalFilterChange = (e) => {
         const value = e.target.value;
         let _filters = { ...filters };
         _filters['global'].value = value;
         setFilters(_filters);
         setGlobalFilterValue(value);
     };

     const renderHeader = () => {
         return (
             <div className="Global__Header">
                 <h5 className="m-0"></h5>
                 <span className="block mt-2 md:mt-0 p-input-icon-left">
                     <i className="pi pi-search" />
                     <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Search..." />
                 </span>
             </div>
         );
     };

     const header = () => renderHeader();

    const handleEditDialog = (rowData) => {
        setShowDialog(true)
        setEditable(true)
    }
    const handleDetailDialog = (rowData) => {
          setShowDialog(true);
     }
    const actionTemplate = (rowData) => {
        return (
            <div className="flex">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-info mr-1" onClick={() => handleEditDialog(rowData)} />
                <Button icon="pi pi-eye" style={{border:'none'}} className="p-button-rounded p-button-primary mr-1" onClick={() => handleDetailDialog(rowData)} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-danger"/>
            </div>
        );
    };
    const onHideDialog = () => {
        setShowDialog(false);
        setEditable(false)
    }
    const onPageChange = (e) => {
        setFirst(e.first);
        setRows(e.rows);
    };

    return (
        <>
            <Dialog header="Annexure Details" visible={showDialog} onHide={onHideDialog}>
                <AnnexureDialog editable={editable} onHideDialog={onHideDialog} />
            </Dialog>
            <div className="card">
                <div className="flex justify-content-between align-items-center">
                    <h4>Annexure</h4>
                    <Button label="Add Annexure" className="Btn__Add__" onClick={()=>setShowDialog(true)}/>
                </div>
                <DataTable
                    value={annexureList}
                    paginator
                    rows={rows}
                    first={first}
                    onPage={onPageChange}
                    globalFilterFields={['key', 'department', 'experience', 'totalHours', 'ratePerHour', 'monthlyBill']}
                    filters={filters}
                    filterDisplay="menu"
                    header={header}
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                    rowsPerPageOptions={[5, 10, 20]}
                >
                    <Column field="key" header="Key" />
                    <Column field="department" header="Department" />
                    <Column field="experience" header="Experience" />
                    <Column field="totalHours" header="Total Hours" />
                    <Column field="ratePerHour" header="Per Hour Rate" />
                    <Column field="monthlyBill" header="Monthly Bill" />
                    <Column body={actionTemplate} header="Actions" />
                </DataTable>
            </div>
        </>
    );
}

export default Annexure;
