import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { handlePostRequest } from "../../services/PostTemplate";
import { useDispatch } from "react-redux";
import classNames from 'classnames';
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { handleGetRequest } from "../../services/GetTemplate";
import { Dropdown } from "primereact/dropdown";

function AddNewDepartmentDialog({ hideDepartmentDialog }) {
    const [verticalList , setVerticalList] = useState()
    
    
    const dispatch = useDispatch();
    const formik = useFormik({
        initialValues: {
            departmentName: '',
            verticalId:''
        },
        onSubmit: async (data) => {
            const response = await dispatch(handlePostRequest(data, '/generaldata/savedepartment', true));
            if (response?.responseCode === 201) {
                setTimeout(() => {
                    hideDepartmentDialog();
                    window.location.reload()
                }, 1000);

            }
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const getVerticals = async () => {
        const response = await handleGetRequest('/list/getverticals', true)
        setVerticalList(response?.data)
    }
    useEffect(() => {
        getVerticals()
    }, [])
    return (
        <div className="card Card__Round">
            <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                <div className="p-fluid p-formgrid grid mb-5">
                    <div className="p-field col-12 md:col-12">
                        <div className="p-field">
                            <label htmlFor="departmentName" className={classNames({ 'p-error': isFormFieldValid('departmentName') }, 'Label__Text')}>
                                Department Name<span className="Label__Required">*</span>
                            </label>
                            <InputText
                                maxLength={20}
                                id="departmentName"
                                placeholder="Please Enter Department Name"
                                name="departmentName"
                                value={formik?.values?.departmentName || ''}
                                onChange={formik.handleChange}
                                // onBlur={formik.handleBlur}
                                className={classNames({ 'p-invalid': isFormFieldValid('departmentName') }, 'Input__Round')}
                            />
                            {getFormErrorMessage('departmentName')}
                            {/* <small className="p-error">{formik.errors.departmentName}</small> */}
                        </div>
                    </div>
                    <div className="p-field col-12 md:col-12">
                        <div className="p-field">
                            <label htmlFor="grade" className={classNames({ 'p-error': isFormFieldValid('grade') }, 'Label__Text')}>
                                Vertical<span className="Label__Required">*</span>
                            </label>
                            <Dropdown
                                options={verticalList}
                                optionLabel="verticalName"
                                optionValue="id"
                                maxLength={20}
                                id="verticalId"
                                placeholder="Please Select Vertical"
                                name="verticalId"
                                value={formik?.values?.verticalId || ''}
                                onChange={formik.handleChange}
                                className={classNames({ 'p-invalid': isFormFieldValid('verticalId') }, 'Dropdown__Round')}
                            />

                            {getFormErrorMessage('verticalId')}
                        </div>
                    </div>
                </div>
                <div className="flex justify-content-center">
                    <Button className="update__button" label="Save" type="submit" style={{ border: 'none' }} />
                    <Button
                        className="cancel__button ml-2"
                        label="Cancel"
                        onClick={(e) => {
                            e.preventDefault();
                            hideDepartmentDialog();
                        }}
                    />
                </div>
            </form>
        </div>
    );
}

export default AddNewDepartmentDialog;
