import React, { useEffect, useState } from 'react';
import image from '../../assets/images/dummyImage.png';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputSwitch } from 'primereact/inputswitch';
import { Dialog } from 'primereact/dialog';
import ResourcesDialog from './ResourcesDialog';
import { FilterMatchMode } from "primereact/api";
import { InputText } from 'primereact/inputtext';
import ResourceViewDialog from './ResourceViewDialog';
import jsPDF from 'jspdf';
import * as XLSX from 'xlsx';
import { handleGetRequest } from '../../services/GetTemplate';
import { useDispatch } from 'react-redux';
import { handlePatchRequest } from '../../services/PutTemplate';

function ResourceData() {
    const [checked1, setChecked1] = useState(false);
    const [showDialog, setShowDialog] = useState(false);
    const [showViewDialog , setShowViewDialog] = useState(false)
    const [tblRowData, setTblRowData] = useState();
    const [expandedRows, setExpandedRows] = useState(null);
    const [locations , setLocations] = useState()
    const [globalFilter, setGlobalFilter] = useState(''); 
    const [first, setFirst] = useState(0); 
    const [rows, setRows] = useState(5); 
    const [tableData, setTableData] = useState()
    const [editable , setEditable] = useState(false)
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS } 
    });

    const dispatch = useDispatch()

    const imageTemplate = () => {
        return <img src={image} style={{ height: '50px', width: '50px' }} />;
    };

    const onHideDialog = () => {
        setShowDialog(false);
        setTblRowData();
        setEditable(false)
    };
    const onHideViewDialog = () => {
        setShowViewDialog(false);
        setTblRowData({});
    };
    const handleRowData = (rowData) => {
        setShowDialog(true);
        setTblRowData(rowData);
        setEditable(true)
    };
    const handleViewModal = (rowData) => {
          setShowViewDialog(true);
          setTblRowData(rowData);
    }

    const handlePdfDownload = () => {
         const doc = new jsPDF();
         const headers = [['Name', 'CNIC', 'Department', 'Designation', 'Experience']];
        // const reportData = resourcesData?.map((item) => [item?.name, item?.cnic, item?.department, item?.designation, item?.experience]);
        doc.autoTable({
            head: headers,
            // body: reportData
        });
         doc.save('resource-data.pdf');
    }
  const handleExcelDownload = () => {
      const worksheetData = tableData?.map((item) => ({
          Name: item?.name,
          CNIC: item?.cnic,
          Department: item?.department,
          Designation: item?.designation,
          Experience: item?.experience
      }));

      const worksheet = XLSX.utils.json_to_sheet(worksheetData);

      const workbook = XLSX.utils.book_new();

      XLSX.utils.book_append_sheet(workbook, worksheet, 'Resources');

      XLSX.writeFile(workbook, 'Resources.xlsx');

  };
   
    const actionTemplate = (rowData) => {
        return (
            <div style={{ display: 'flex' }}>
                <Button className="p-button-rounded p-button-info mr-1" style={{ background: '#6495ED' }} icon="pi pi-pencil" tooltip="Edit Resource" onClick={() => handleRowData(rowData)} />
                <Button className="p-button-rounded p-button-success" tooltip="View Resource" icon="pi pi-eye" onClick={() => handleViewModal(rowData)} />
            </div>
        );
    };
    const allowExpansion = (rowData) => {
        return rowData?.PersonaltDetails?.length > 0;
    };


    const rowExpansionTemplate = (rowData) => {
        const projectsDetails = rowData?.ProjectDetails?.map((item) => {
            return item?.project;
        });
        const personalDetails = rowData?.PersonaltDetails;
        const educationalDetails = rowData?.EducationDetails;
        const certificationDetails = rowData?.CertificationDetails;
        const experienceDetails = rowData?.ExperienceDetails;
        const familyDetails = rowData?.FamilyDetails;
        const kidsDetails = rowData?.KidsDetails;
        const marriageTemplate = (rowData) => {
            return <p>{rowData?.maritalStatus === "true" ? 'Married' : 'Single'}</p>
        }
        return (
            <>
                <div className="p-3">
                    <div className="flex justify-content-center">
                        <h5>Personal Details</h5>
                    </div>
                    <DataTable value={personalDetails}>
                        <Column field="dob" header="Date of Birth" />
                        <Column field="mobileNo" header="Contact No." />
                        <Column field="employeeEmail" header="Email" />
                        <Column field="bloodGroup" header="Blood Group" />
                        <Column field="address" header="Address" />
                        <Column body={marriageTemplate} header="Marital Status" />
                    </DataTable>
                </div>
                <div className="p-3">
                    <div className="flex justify-content-center">
                        <h5>Family Details</h5>
                    </div>
                    <DataTable value={familyDetails}>
                        <Column field="fatherName" header="Father Name" />
                        <Column field="spouseName" header="Spouse Name" />
                        <Column field="spouseContact" header="Spouse Contact" />
                        <Column field="nextOfKin" header="Next of Kin" />
                        <Column field="nextOfKinContact" header="NOK Contact" />
                    </DataTable>
                </div>
                <div className="p-3">
                    <div className="flex justify-content-center">
                        <h5>Kids Details</h5>
                    </div>
                    <DataTable value={kidsDetails}>
                        <Column field="kidName" header="Kid Name" />
                        <Column field="gender" header="Kid Gender" />
                        <Column field="dob" header="Date of Birth" />
                    </DataTable>
                </div>
                <div className="p-3">
                    <div className="flex justify-content-center">
                        <h5>Educational Details</h5>
                    </div>
                    <DataTable value={educationalDetails}>
                        <Column field="matricInstitute" header="Matric" />
                        <Column field="matricPassingYear" header="Passing Year" />
                        <Column field="matricResult" header="Matric Result" />
                        <Column field="interInstitute" header="Inter" />
                        <Column field="interPassingYear" header="Passing Year" />
                        <Column field="interResult" header="Inter Result" />
                        <Column field="bachelorsInstitute" header="Bachelors" />
                        <Column field="bachelorsPassingYear" header="Passing" />
                        <Column field="bachelorsCgpa" header="CGPA" />
                        {/* <Column field="cgpa" header="CGPA" /> */}
                    </DataTable>
                </div>
                <div className="p-3">
                    <div className="flex justify-content-center">
                        <h5>Certification Details</h5>
                    </div>
                    <DataTable value={certificationDetails}>
                        <Column field="certificationName" header="Certification Name" />
                        <Column field="startDate" header="Start Date" />
                        <Column field="endDate" header="End Date" />
                    </DataTable>
                </div>
                <div className="p-3">
                    <div className="flex justify-content-center">
                        <h5>Experience Details</h5>
                    </div>
                    <DataTable value={experienceDetails}>
                        <Column field="companyName" header="Company" />
                        <Column field="designation" header="Designation" />
                        <Column field="fromDate" header="Date of Joining" />
                        <Column field="toDate" header="Date of Resign" />
                    </DataTable>
                </div>
            </>
        );
    };

      const onGlobalFilterChange = (e) => {
          const value = e.target.value;
          let _filters = { ...filters };
          _filters['global'].value = value;
          setFilters(_filters);
          setGlobalFilterValue(value);
      };

       const renderHeader = () => {
           return (
               <div className="Global__Header">
                   <h5 className="m-0"></h5>
                   <span className="block mt-2 md:mt-0 p-input-icon-left">
                       <i className="pi pi-search" />
                       <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Search..." />
                   </span>
               </div>
           );
       };

    const header = () => renderHeader();

    const getAllEmployess = async () => {
        const response = await handleGetRequest('/employee/');
        if (response?.responseCode === 200) {
            setTableData(response?.data)
        }
    }
    useEffect(() => {
        getAllEmployess()
    }, [])
    const statusTemplate = (rowData) => {
        return (
            <>
                <InputSwitch checked={rowData.isActive} onChange={(e) => handleSwitchChange(rowData, e.value)} />
            </>
        );
    };
    const nameTemplate = (rowData) => {
        return <p>{rowData?.firstName}&nbsp;{rowData?.lastName}</p>
    }
    const handleSwitchChange = async (rowData, value) => {
        const date = new Date()
        const data = {
            isActive: value,
            dateOfDischarge: value ? null : date
         }
         try {
             const response = await dispatch(handlePatchRequest(data, `/employee/updateemployeestatus/${rowData?.id}`));
             const updatedData = tableData.map((item) => {
                 if (item.id === rowData.id) {
                     return { ...item, isActive: value };
                 }
                 return item;
             });
             setTableData(updatedData);
         } catch (error) {
             console.error('Failed to update status:', error);
         }
    };
    const onPageChange = (e) => {
        setFirst(e.first);
        setRows(e.rows);
    };

    const getLocation = async () => {
        const response = await handleGetRequest('/list/getlocations', true)
        if (response?.responseCode === 201) {
            setLocations(response?.data)
        }
    }
    useEffect(() => {
        getLocation()
    }, [])

    const locationTemplate = (rowData) => {
        const locationName = locations?.filter((item) => item?.id === rowData?.location)
        console.log(locationName)
        return <p>{locationName?.[0]?.locationName}</p>
    }
    
    return (
        <>
            <Dialog onHide={onHideDialog} header="Resource Details" visible={showDialog} style={{ width: '80vw' }}>
                <ResourcesDialog tblRowData={tblRowData} onHideDialog={onHideDialog} editable={editable} />
            </Dialog>
            <Dialog header="Resource Details" visible={showViewDialog} style={{ width: '80vw' }} onHide={onHideViewDialog}>
                <ResourceViewDialog tblRowData={tblRowData} onHideViewDialog={onHideViewDialog} />
            </Dialog>
            <div className="Top__Btn Top__Btn__Border">
                <div>
                    <Button label="Add Resource" className="Btn__Add__" icon="pi pi-plus" iconPos="left" onClick={() => setShowDialog(true)} />
                    <Button label="Download Excel" icon="pi pi-download" className="Btn__Add__ ml-1" onClick={handleExcelDownload} />
                </div>
            </div>
            <div className="card">
                <div className="flex justify-content-between align-items-center pb-5">
                    <h4 className="m-0">Resources Details</h4>
                </div>
                <DataTable
                    value={tableData}
                    paginator
                    rows={rows} 
                    first={first} 
                    onPage={onPageChange} 
                    globalFilterFields={['firstName', 'lastName', 'cnic', 'officialEmail', 'department', 'rank', 'location']}
                    filters={filters} 
                    filterDisplay="menu"
                    header={header}
                    expandedRows={expandedRows}
                    onRowToggle={(e) => setExpandedRows(e.data)}
                    rowExpansionTemplate={rowExpansionTemplate}
                    dataKey="id"
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" 
                    rowsPerPageOptions={[5, 10, 20]}
                >
                    <Column expander={allowExpansion} style={{ width: '3rem' }} />
                    <Column body={imageTemplate} header="Image" />
                    <Column field="cnic" header="CNIC" />
                    <Column body={nameTemplate} header="Name" />
                    <Column field="officialEmail" header="Email" />
                    <Column field="Department.departmentName" header="Department" />
                    <Column field="rank" header="Designation" />
                    <Column field="reportingManager" header="Reporting Manager" />
                    <Column body={locationTemplate} header="Location" />
                    <Column field="experience" header="Experience" />
                    <Column field="emergencyNumber" header="Emergency Contact" />
                    <Column body={statusTemplate} header="Status" />
                    <Column body={actionTemplate} header="Actions" />
                </DataTable>
            </div>
        </>
    );
}

export default ResourceData;
