import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import React, { useEffect, useState } from 'react'
import { FilterMatchMode } from 'primereact/api';
import { Dialog } from 'primereact/dialog';
import AddProjectDialog from './AddProjectDialog';
import AssignResourceDialog from './AssignResourceDialog';
import { handleGetRequest } from '../../services/GetTemplate';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { handlePatchRequest } from '../../services/PutTemplate';

function Projects() {
    
    const [showDialog, setShowDialog] = useState(false)
    const [assignresourceDialog, setAssignresourceDialog] = useState(false);
    const [expandedRows, setExpandedRows] = useState(null);
    const [globalFilter, setGlobalFilter] = useState('');
    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(5); 
    const [projectsList , setProjectsList] = useState()
    const [globalFilterValue, setGlobalFilterValue] = useState('');
     const [filters, setFilters] = useState({
         global: { value: null, matchMode: FilterMatchMode.STARTS_WITH }
     });
    const [projectId, setProjectId] = useState()
    

    const dispatch = useDispatch()


    const rowExpansionTemplate = (rowData) => {
        const data = rowData?.resources;
        setProjectId(rowData.id)
        return (
            <>
                <div className="p-3">
                    <div className="flex justify-content-center">
                        <h5>Project Details</h5>
                    </div>
                    <div class="flex justify-content-end">
                        <Button label="Add New Resource" className="Btn__Add__" onClick={()=>setAssignresourceDialog(true)}/>
                    </div>
                    <DataTable value={data}>
                        <Column field="employeeName" header="Resource Name" />
                        <Column field="rank" header="Designation" />
                        <Column field="startDate" header="Start Date" />
                        <Column field="endDate" header="End Date" />
                        <Column body={actionTemplate} header="Actions" />
                    </DataTable>
                </div>
            </>
        );
    };

    const allowExpansion = (rowData) => {
        return rowData?.resources?.length > 0;
    };

     const renderHeader = () => {
         return (
             <div className="Global__Header">
                 <h5 className="m-0"></h5>
                 <span className="block mt-2 md:mt-0 p-input-icon-left">
                     <i className="pi pi-search" />
                     <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Search..." />
                 </span>
             </div>
         );
     };

    const header = () => renderHeader();
    
     const onGlobalFilterChange = (e) => {
         const value = e.target.value;
         let _filters = { ...filters };

         _filters['global'].value = value;

         setFilters(_filters);
         setGlobalFilterValue(value);
    };
    
    const handleRemove = async (row) => {
        let data;
        // const data = {
        //     isActive:false
        // }
        const response = await dispatch(handlePatchRequest(data, `/projects/removeresource/${row.employeeId}/${projectId}`, true));
        if (response?.responseCode === 200) {
            setTimeout(() => {
                window.location.reload()
            }, 1000);
        }
    }
    const actionTemplate = ( row) => {
          return (
              <div style={{ display: 'flex' }}>
                  <Button className="p-button-rounded p-button-danger" tooltip="Remove Resource" icon="pi pi-trash"  onClick={()=>handleRemove(row )}/>
              </div>
          );
    };
    

    const hideDialog = () => {
        setShowDialog(false)
    }
const hideResourceDialog = () => {
    setAssignresourceDialog(false);
    };
    
    const getProjectsById = async () => {
        const response = await handleGetRequest('/projects/75');
        if (response?.responseCode === 200) {
            setProjectsList(response?.data)
            toast.success('Projects fetched successfully')
        }
    }

    useEffect(() => {
        getProjectsById()
    }, [])

  return (
      <>
          <Dialog visible={showDialog} onHide={hideDialog} style={{ width: '50vw' }} header="Add new project">
              <AddProjectDialog hideDialog={hideDialog} />
          </Dialog>
          <Dialog visible={assignresourceDialog} onHide={hideResourceDialog} style={{ width: '50vw' }} header="Assign new resource">
              <AssignResourceDialog hideResourceDialog={hideResourceDialog} />
          </Dialog>
          <div className="Top__Btn Top__Btn__Border">
              <div>
                  <Button label="Add New Project" className="Btn__Add__" icon="pi pi-plus" iconPos="left" onClick={() => setShowDialog(true)} />
              </div>
          </div>
          <div className="card">
              <div>
                  <h4>Projects</h4>
              </div>
              <DataTable
                  globalFilterFields={['officeId', 'cnic', 'name', 'department', 'designation', 'location', 'ratePerHour', 'experience']}
                  value={projectsList}
                  className="datatable-responsive"
                  responsiveLayout="scroll"
                  stripedRows
                  emptyMessage="No records found"
                  expandedRows={expandedRows}
                  onRowToggle={(e) => setExpandedRows(e.data)}
                  rowExpansionTemplate={rowExpansionTemplate}
                  dataKey="projectName"
                  paginator
                  paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                  currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                  rows={rows}
                  rowsPerPageOptions={[5, 10, 20]}
                  first={first}
                  onPage={(e) => setFirst(e.first)}
                  globalFilter={globalFilter}
                  filterDisplay="menu"
                  header={header}
              >
                  <Column expander={allowExpansion} style={{ width: '5rem' }} />
                  <Column field="projectName" header="Project Name" />
                  <Column field="description" header="Description" />
                  <Column field="startDate" header="Start Date" />
                  <Column field="endDate" header="End Date" />
              </DataTable>
          </div>
      </>
  );
}

export default Projects