import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { handleGetRequest } from '../../services/GetTemplate';

const getLkpCitySlice = createSlice({
    name: "lkpCity",
    initialState: {
        isLoading: false,
        data: [],
        isError: false
    },
    extraReducers: (builder) => {
        builder.addCase(getLkpCity.pending, (state, action) => {
            state.isLoading = true;
        })
        builder.addCase(getLkpCity.fulfilled, (state, action) => {
            state.isLoading = false;
            state.data = action.payload;
        })
        builder.addCase(getLkpCity.rejected, (state, action) => {
            state.isError = true;
        })
    }
})

export const getLkpCity = createAsyncThunk("getLkpCity", async () => {
    const res = await handleGetRequest(`/configuration/v1/lookups/getAllCity/LKP_CITY:*`);
    return res?.payLoad;
});

export default getLkpCitySlice.reducer