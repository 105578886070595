import { useFormik } from "formik";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { Divider } from "primereact/divider";
import { Dropdown } from "primereact/dropdown";
import { useDispatch } from "react-redux";
import { handlePostRequest } from "../../services/PostTemplate";
import { handlePatchRequest } from "../../services/PutTemplate";
import { toast } from "react-toastify";
import { handleGetRequest } from "../../services/GetTemplate";
import * as Yup from "yup";

function ResourcesDialog({ tblRowData, onHideDialog, editable }) {
  const [grades, setGrades] = useState();
  const [ranks, setRanks] = useState();
  const [departmentList, setDEpartmentList] = useState();
  const [locations, setLocations] = useState();

  const dispatch = useDispatch();

  const validationSchema = Yup.object().shape({
    dateOfJoiningAis: Yup.date()
      .required("Date of joining AIS is required")
      .test("is-greater-than-dob", "Employee must be 18 years old", function (
        value
      ) {
        const dob = this.options.context?.personalDetails?.[0]?.dob;

        if (!dob || !value) return true;

        const tenYearsLater = new Date(dob);
        tenYearsLater.setFullYear(tenYearsLater.getFullYear() + 18);

        return value >= tenYearsLater;
      }),
    probationEndDate: Yup.string().required("This field is required"),
    cnicExpirey: Yup.date()
      .required("CNIC expiry date is required")
      .test("is-future", "CNIC expiry cannot be in the past", function (value) {
        return value && new Date(value) > new Date();
      }),
    aisId: Yup.string()
      .matches(/^AiS-\d{5}$/, "AIS Id must follow the format AiS-00000")
      .required("AIS Id is required"),
    cnic: Yup.string()
      .required("CNIC is required")
      .matches(/^\d{5}-\d{7}-\d{1}$/, "CNIC must be in format 12345-1234567-1"),
    firstName: Yup.string().required("First Name is required"),
    lastName: Yup.string(),
    grade: Yup.string().required("Grade is required"),
    rank: Yup.string().required("Rank is required"),
    location: Yup.string().required("Location is required"),
    dateOfJoining: Yup.date()
      .required("Date of Joining is required")
      .max(new Date(), "Date of joining cannot be in the future"),
    emergencyNumber: Yup.string()
      .required("Emergency Number is required")
      .matches(
        /^03\d{9}$/,
        "Emergency number must be in format 03XXXXXXXXX (e.g., 03331234567)"
      ),
    officialEmail: Yup.string()
      .required("Official Email is required")
      .matches(/^[\w-\.]+@appinsnap\.com$/, "Not a valid email address"),
    salary: Yup.string().required("Salary is required"),
    reportingManager: Yup.string()
      .required("Reporting Manager is required")
      .matches(/^[\w-\.]+@appinsnap\.com$/, "Not a valid email address"),
    departmentId: Yup.string().required("Department is required"),
    isActive: Yup.boolean(),
    educationalDetails: Yup.array().of(
      Yup.object().shape({
        matricInstitute: Yup.string().required("Matric Institute is required"),
        matricPassingYear: Yup.string()
          .required("Matric Passing Year is required")
          .test(
            "is-10-years-after-dob",
            "Matric Passing Year must be at least 10 years after Date of Birth",
            function (value) {
              const dob = this.options.context?.personalDetails?.[0]?.dob;
              if (!dob) return true;
              const dobYear = new Date(dob).getFullYear();
              const passingYear = parseInt(value, 10);
              return passingYear >= dobYear + 10;
            }
          ),
        matricResult: Yup.string().required("Matric Result is required"),
        interInstitute: Yup.string().required("Inter Institute is required"),
        interPassingYear: Yup.string()
          .required("Inter Passing Year is required")
          .test(
            "is-10-years-after-dob",
            "Inter Passing Year must be at least 10 years after Date of Birth",
            function (value) {
              const dob = this.options.context?.personalDetails?.[0]?.dob;
              if (!dob) return true;
              const dobYear = new Date(dob).getFullYear();
              const passingYear = parseInt(value, 10);
              return passingYear >= dobYear + 10;
            }
          ),
        interResult: Yup.string().required("Inter Result is required"),
        bachelorsInstitute: Yup.string().required(
          "Bachelors Institute is required"
        ),
        degree: Yup.string().required("Degree is required"),
        bachelorsPassingYear: Yup.string()
          .required("Bachelors Passing Year is required")
          .test(
            "is-10-years-after-dob",
            "Bachelors Passing Year must be at least 10 years after Date of Birth",
            function (value) {
              const dob = this.options.context?.personalDetails?.[0]?.dob;
              if (!dob) return true;
              const dobYear = new Date(dob).getFullYear();
              const passingYear = parseInt(value, 10);
              return passingYear >= dobYear + 10;
            }
          ),
        bachelorsCgpa: Yup.string()
          .required("Bachelors CGPA is required")
          .matches(
            /^[0-3](\.\d{2})?$|^4(\.00)?$/,
            "CGPA must be in the format 0.00 and not exceed 4.00"
          ),
        masters: Yup.string(),
        mastersPassingYear: Yup.string()
          .nullable()
          .test(
            "is-10-years-after-dob",
            "Masters Passing Year must be at least 10 years after Date of Birth",
            function (value) {
              const { masters } = this.parent; // Get the master's degree field from the same object
              const dob = this.options.context?.personalDetails?.[0]?.dob;

              // Only apply the test if the master's degree name is provided
              if (!masters || !dob || !value) return true;

              const dobYear = new Date(dob).getFullYear();
              const passingYear = parseInt(value, 10);

              return passingYear >= dobYear + 10;
            }
          ),

        mastersInstitute: Yup.string(),
        mastersCGPA: Yup.string().matches(
          /^[0-3](\.\d{2})?$|^4(\.00)?$/,
          "CGPA must be in the format 0.00 and not exceed 4.00"
        ),
      })
    ),

    familyDetails: Yup.array().of(
      Yup.object().shape({
        fatherName: Yup.string().required("Father Name is required"),
        nextOfKin: Yup.string().required("Next of Kin is required"),
        nextOfKinAddress: Yup.string().required("This field is required"),
        nextOfKinContact: Yup.string().matches(
          /^03\d{9}$/,
          "Emergency number must be in format 03XXXXXXXXX (e.g., 03331234567)"
        ),
        spouseContact: Yup.string()
          .nullable()
          .matches(
            /^03\d{9}$/,
            "Emergency number must be in format 03XXXXXXXXX (e.g., 03331234567)"
          ),
      })
    ),

    certificationDetails: Yup.array().of(
      Yup.object().shape({
        startDate: Yup.date().test(
          "is-greater-than-dob",
          "Start Date must be at least 10 years after Date of Birth",
          function (value) {
            const dob = this.options.context?.personalDetails?.[0]?.dob;

            if (!dob || !value) return true;

            const tenYearsLater = new Date(dob);
            tenYearsLater.setFullYear(tenYearsLater.getFullYear() + 10);

            return value >= tenYearsLater;
          }
        ),
        endDate: Yup.date()
          .nullable()
          .min(Yup.ref("startDate"), "End Date cannot be before Start Date")
          .test(
            "is-greater-than-dob",
            "End Date must be at least 10 years after Date of Birth",
            function (value) {
              const dob = this.options.context?.personalDetails?.[0]?.dob;

              if (!dob || !value) return true;

              const tenYearsLater = new Date(dob);
              tenYearsLater.setFullYear(tenYearsLater.getFullYear() + 10);

              return value >= tenYearsLater;
            }
          ),
      })
    ),

    experienceDetails: Yup.array().of(
      Yup.object().shape({
        fromDate: Yup.date().test(
          "is-greater-than-doj",
          "Start Date must be after Date of Joining First Job",
          function (value) {
            const dateOfJoining = this.options.context?.dateOfJoining;

            if (!dateOfJoining || !value) return true;

            return value >= new Date(dateOfJoining);
          }
        ),
        toDate: Yup.date().test(
          "is-greater-than-doj",
          "To Date must be after Date of Joining",
          function (value) {
            const dateOfJoining = this.options.context?.dateOfJoining;

            if (!dateOfJoining || !value) return true;

            return value >= new Date(dateOfJoining);
          }
        ),
      })
    ),

    personalDetails: Yup.array().of(
      Yup.object().shape({
        dob: Yup.date().required("Date of Birth is required"),
        employeeEmail: Yup.string()
          .email("Invalid Email")
          .required("Employee Email is required"),
        mobileNo: Yup.string()
          .matches(
            /^03\d{9}$/,
            "Emergency number must be in format 03XXXXXXXXX (e.g., 03331234567)"
          )
          .required("Mobile Number is required"),
        bloodGroup: Yup.string().required("Blood Group is required"),
        maritalStatus: Yup.string()
          .nullable()
          .required("Marital status is required"),
        address: Yup.string().required("Address is required"),
      })
    ),
    incrementDetails: Yup.array().of(
      Yup.object().shape({
        increment: Yup.number().nullable(),
        incrementDate: Yup.date().test(
          "is-after-dateOfJoiningAis",
          "Increment Date must be after the Date of Joining AIS",
          function (value) {
            const dateOfJoiningAis = this.options.context?.dateOfJoiningAis;
            if (!dateOfJoiningAis || !value) {
              return true;
            }

            return new Date(value) > new Date(dateOfJoiningAis);
          }
        ),
      })
    ),
  });

  const formik = useFormik({
    // validationSchema: validationSchema,
    initialValues: {
      cnic: "",
      cnicExpirey: "",
      firstName: "",
      lastName: "",
      grade: "",
      rank: "",
      location: "",
      dateOfJoining: "",
      emergencyNumber: "",
      officialEmail: "",
      reportingManager: "",
      departmentId: "",
      salary: "",
      experience: null,
      isActive: true,
      dateOfDischarge: "",
      dateOfJoiningAis: "",
      probationEndDate: "",
      incrementDetails: [
        {
          increment: null,
          incrementDate: "",
        },
      ],
      educationalDetails: [
        {
          matricInstitute: "",
          matricPassingYear: "",
          matricResult: "",
          interInstitute: "",
          interPassingYear: "",
          interResult: "",
          bachelorsInstitute: "",
          degree: "",
          bachelorsPassingYear: "",
          bachelorsCgpa: "",
          masters: "",
          mastersPassingYear: "",
          mastersInstitute: "",
          mastersCGPA: "",
        },
      ],
      familyDetails: [
        {
          fatherName: "",
          nextOfKin: "",
          nextOfKinContact: "",
          nextOfKinAddress: "",
          spouseName: "",
          spouseContact: "",
          spouseDob: "",
        },
      ],
      certificationDetails: [
        {
          certificationName: "",
          startDate: "",
          endDate: "",
        },
      ],
      experienceDetails: [
        {
          companyName: "",
          designation: "",
          fromDate: "",
          toDate: "",
          currentlyWorkingHere: false,
          department: "",
        },
      ],
      personalDetails: [
        {
          dob: "",
          employeeEmail: "",
          mobileNo: "",
          bloodGroup: "",
          maritalStatus: "",
          address: "",
        },
      ],
      kidsInfo: [
        {
          kidName: "",
          gender: "",
          dob: "",
        },
      ],
    },
    onSubmit: async (data) => {
      if (
        data?.kidsInfo[0]?.kidName === null ||
        data?.kidsInfo[0]?.kidName === ""
      ) {
        data.kidsInfo = [];
      }
      if (
        data?.certificationDetails[0]?.certificationName === null ||
        data?.certificationDetails[0]?.certificationName === ""
      ) {
        data.certificationDetails = [];
      }
      if (
        data?.incrementDetails[0]?.increment === null ||
        data?.incrementDetails[0]?.increment === ""
      ) {
        data.incrementDetails = [];
      }
      if (
        data?.experienceDetails[0]?.companyName === null ||
        data?.experienceDetails[0]?.companyName === ""
      ) {
        data.experienceDetails = [];
      }

      delete data["degree"];
      delete data["address"];
      delete data["bachelorsInstitute"];
      delete data["bachelorsPassingYear"];
      delete data["bloodGroup"];
      delete data["employeeEmail"];
      delete data["fatherName"];
      delete data["interInstitute"];
      delete data["interResult"];
      delete data["maritalStatus"];
      delete data["matricInstitute"];
      delete data["matricResult"];
      delete data["mobileNo"];
      delete data["mobileNo"];
      delete data["nextOfKinContact"];
      delete data["spouseContact"];
      delete data["spouseName"];

      data["salary"] = parseInt(data.salary);
      if (!editable) {
        const response = await dispatch(
          handlePostRequest(data, "/employee/saveemployee", true)
        );
        if (response?.responseCode === 200) {
          onHideDialog();
        } else {
          toast.warn("Something went wrong");
        }
      } else if (editable) {
        const response = await dispatch(
          handlePatchRequest(
            data,
            `/employee/updateEmployee/${tblRowData?.id}`,
            true
          )
        );
        if (response?.responseCode === 200) {
          setTimeout(() => {
            onHideDialog();
            window.location.reload()
          }, 1000);
        } 
      }
    },
  });

  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="p-error">{formik.errors[name]}</small>
      )
    );
  };

  const genderList = [
    {
      label: "Son",
      value: "male",
    },
    {
      label: "Daughter",
      value: "female",
    },
  ];
  const locationLov = [
    {
      label: "Islamabad",
      value: "isb",
    },
    {
      label: "Lahore",
      value: "lhr",
    },
    {
      label: "Karachi",
      value: "khi",
    },
  ];
  const maritalStatusLov = [
    {
      label: "Married",
      value: "true",
    },
    {
      label: "Unmarried",
      value: "false",
    },
  ];

  const addPartner = () => {
    formik.setFieldValue("kidsInfo", [
      ...formik.values.kidsInfo,
      {
        kidName: "",
        gender: "",
        dob: "",
      },
    ]);
  };
  const addExperience = () => {
    formik.setFieldValue("experienceDetails", [
      ...formik.values.experienceDetails,
      {
        companyName: "",
        designation: "",
        fromDate: "",
        toDate: "",
        currentlyWorkingHere: false,
        department: "",
      },
    ]);
  };

  const removeCurrentCard = (index) => {
    const updatedArray = [...formik.values.kidsInfo];
    updatedArray.splice(index, 1);
    formik.setFieldValue("kidsInfo", updatedArray);
  };
  const removeExperience = (index) => {
    const updatedArray = [...formik.values.experienceDetails];
    updatedArray.splice(index, 1);
    formik.setFieldValue("experienceDetails", updatedArray);
  };
  const addIncrementCard = () => {
    formik.setFieldValue("incrementDetails", [
      ...formik.values.incrementDetails,
      {
        increment: null,
        incrementDate: "",
      },
    ]);
  };
  const removeIncrementCard = (index) => {
    const updatedArray = [...formik.values.incrementDetails];
    updatedArray.splice(index, 1);
    formik.setFieldValue("incrementDetails", updatedArray);
  };

  const addProject = () => {
    formik.setFieldValue("certificationDetails", [
      ...formik.values.certificationDetails,
      {
        certificationName: "",
        startDate: "",
        endDate: "",
      },
    ]);
  };
  const removeProject = (index) => {
    const updatedArray = [...formik.values.certificationDetails];
    updatedArray.splice(index, 1);
    formik.setFieldValue("certificationDetails", updatedArray);
  };

  const mapValues = () => {
    Object.keys(tblRowData).forEach((key) => {
      if (formik.initialValues.hasOwnProperty(key)) {
        formik.setFieldValue(key, tblRowData[key]);
      }

      const experienceData =
        tblRowData?.ExperienceDetails?.length > 0
          ? tblRowData.ExperienceDetails.map((item) => ({
              companyName: item?.companyName || "",
            designation: item?.designation || "",
              department:item?.department || "",
              fromDate: item?.fromDate || "",
              toDate: item?.toDate || "",
            }))
          : [{ companyName: "", designation: "", fromDate: "", toDate: "" }];
      const projectData = tblRowData?.CertificationDetails.length > 0 ? tblRowData?.CertificationDetails?.map((item) => {
        return {
          certificationName: item?.certificationName,
          startDate: item?.startDate?.slice(0, 10),
          endDate: item?.endDate?.slice(0, 10),
        };
      }) : [{certificationName:'', startDate:'', endDate:''}]
      const kidsData = tblRowData?.KidsDetails.length > 0 ? tblRowData?.KidsDetails?.map((item) => {
        return {
          kidName: item?.kidName,
          gender: item?.gender,
          dob: item?.dob?.slice(0, 10),
        };
      }) : [{kidName:'', gender:'', dob:''}]
      const educationalData = tblRowData?.EducationDetails?.map((item) => {
        return {
          matricInstitute: item?.matricInstitute,
          matricPassingYear: item?.matricPassingYear?.slice(0, 10),
          matricResult: item?.matricResult,
          interInstitute: item?.interInstitute,
          interPassingYear: item?.interPassingYear?.slice(0, 10),
          interResult: item?.interResult,
          bachelorsInstitute: item?.bachelorsInstitute,
          bachelorsPassingYear: item?.bachelorsPassingYear?.slice(0, 10),
          bachelorsCgpa: item?.bachelorsCgpa,
          degree: item?.degree,
        };
      });
      const personalData = tblRowData?.PersonaltDetails?.map((item) => {
        return {
          address: item?.address,
          bloodGroup: item?.bloodGroup,
          dob: item?.dob?.slice(0, 10),
          employeeEmail: item?.employeeEmail,
          maritalStatus: item?.maritalStatus,
          mobileNo: item?.mobileNo,
        };
      });
      const familyData = tblRowData?.FamilyDetails?.map((item) => {
        return {
          fatherName: item?.fatherName,
          nextOfKin: item?.nextOfKin,
          nextOfKinContact: item?.nextOfKinContact,
          nextOfKinAddress: item?.nextOfKinAddress,
          spouseName: item?.spouseName,
          spouseContact: item?.spouseContact,
          spouseDob: item?.spouseDob?.slice(0, 10),
        };
      });
      const incrementData = tblRowData?.IncrementDetails?.length > 0 ? tblRowData?.IncrementDetails?.map((item) => {
        return {
          increment: item?.increment,
          incrementDate: item?.incrementDate?.slice(0, 10),
        };
      }) : [{increment: null , incrementData:''}]
      formik.setFieldValue("familyDetails", familyData);
      formik.setFieldValue("personalDetails", personalData);
      formik.setFieldValue("educationalDetails", educationalData);
      formik.setFieldValue(
        "dateOfJoining",
        tblRowData?.dateOfJoining.slice(0, 10)
      );
      formik.setFieldValue("incrementDetails", incrementData);
      formik.setFieldValue("experienceDetails", experienceData);
      formik.setFieldValue("certificationDetails", projectData);
      formik.setFieldValue("kidsInfo", kidsData);
      formik.setFieldValue("aisId", tblRowData?.aisId);
       formik.setFieldValue('basicSalary', tblRowData?.salary * 0.6);
       formik.setFieldValue('utilityAllowance', tblRowData?.salary * 0.1);
       formik.setFieldValue('hra', tblRowData?.salary * 0.2);
       formik.setFieldValue('colaAllowance', tblRowData?.salary * 0.1);
      // formik.setFieldValue("department", tblRowData?.Department.id);
    });
  };
  useEffect(() => {
    if (editable) {
      mapValues();
    }
  }, [tblRowData]);

  const handleCNICChange = (e) => {
    let value = e.target.value.replace(/\D/g, "");
    value = `${value.slice(0, 5)}-${value.slice(5, 12)}-${value.slice(12, 13)}`;

    formik.setFieldValue("cnic", value);
  };

  const getGrades = async () => {
    const response = await handleGetRequest("/list/getAllGrades", true);
    if (response.responseCode === 200) {
      setGrades(response?.data);
    }
  };

  const getAllRanks = async () => {
    const response = await handleGetRequest("/list/getallRanks", true);
    if (response.responseCode === 200) {
      setRanks(response?.data);
    }
  };
  const getAllDepartments = async () => {
    const response = await handleGetRequest("/list/alldepartments", true);
    setDEpartmentList(response?.data);
  };

  const getLocations = async () => {
    const response = await handleGetRequest("/list/getlocations", true);
    setLocations(response?.data);
  };
  useEffect(() => {
    getGrades();
    getAllRanks();
    getAllDepartments();
    getLocations();
  }, []);

  // const date = formik.values.firstJobDate;

  function calculateMonthsSince(date) {
    const startDate = new Date(date);
    const currentDate = new Date();
    formik.setFieldValue("dateOfJoining", date);

    const yearsDifference = currentDate.getFullYear() - startDate.getFullYear();
    const monthsDifference = currentDate.getMonth() - startDate.getMonth();
    const daysDifference = currentDate.getDate() - startDate.getDate();

    let totalMonths = yearsDifference * 12 + monthsDifference;

    if (daysDifference < 0) {
      totalMonths -= 1;
    }

    const years = Math.floor(totalMonths / 12);
    const months = totalMonths % 12;
    formik.setFieldValue("experience", parseFloat(`${years}.${months}`));
  }

  const bloodGroupList = [
    {
      label: "A+",
      value: "A+",
    },
    {
      label: "A-",
      value: "A-",
    },
    {
      label: "B+",
      value: "B+",
    },
    {
      label: "B-",
      value: "B-",
    },
    {
      label: "AB+",
      value: "AB+",
    },
    {
      label: "AB-",
      value: "AB-",
    },
    {
      label: "O+",
      value: "O+",
    },
    {
      label: "O-",
      value: "O-",
    },
  ];

  return (
    <>
      <>
        <div className="card Card__Round">
          <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
            <div className="p-fluid p-formgrid grid mb-5">
              <div className="p-field col-6 md:col-4">
                <div className="p-field">
                  <label
                    htmlFor="aisId"
                    className={classNames(
                      { "p-error": isFormFieldValid("aisId") },
                      "Label__Text"
                    )}
                  >
                    AiS ID<span className="Label__Required">*</span>
                  </label>
                  <InputText
                    disabled={editable ? true : false}
                    maxLength={9}
                    id="aisId"
                    placeholder="Please Enter AIS Id"
                    name="aisId"
                    value={formik?.values?.aisId || ""}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className={classNames(
                      { "p-invalid": isFormFieldValid("aisId") },
                      "Input__Round"
                    )}
                  />
                  <small className="p-error">{formik.errors.aisId}</small>
                </div>
              </div>
              <div className="p-field col-6 md:col-4">
                <div className="p-field">
                  <label
                    htmlFor="cnic"
                    className={classNames(
                      { "p-error": isFormFieldValid("cnic") },
                      "Label__Text"
                    )}
                  >
                    CNIC<span className="Label__Required">*</span>
                  </label>
                  <InputText
                    disabled={editable ? true : false}
                    maxLength={15}
                    id="cnic"
                    placeholder="Please Enter CNIC"
                    name="cnic"
                    value={formik?.values?.cnic || ""}
                    onChange={handleCNICChange}
                    onBlur={formik.handleBlur}
                    className={classNames(
                      { "p-invalid": isFormFieldValid("cnic") },
                      "Input__Round"
                    )}
                  />
                  <small className="p-error">{formik.errors.cnic}</small>
                </div>
              </div>
              <div className="p-field col-6 md:col-4">
                <div className="p-field">
                  <label
                    htmlFor="firstName"
                    className={classNames(
                      { "p-error": isFormFieldValid("firstName") },
                      "Label__Text"
                    )}
                  >
                    First Name<span className="Label__Required">*</span>
                  </label>
                  <InputText
                    maxLength={20}
                    id="firstName"
                    placeholder="Please Enter First Name"
                    name="firstName"
                    value={formik?.values?.firstName || ""}
                    onChange={formik.handleChange}
                    className={classNames(
                      { "p-invalid": isFormFieldValid("firstName") },
                      "Input__Round"
                    )}
                  />

                  {getFormErrorMessage("firstName")}
                </div>
              </div>
              <div className="p-field col-6 md:col-4">
                <div className="p-field">
                  <label
                    htmlFor="lastName"
                    className={classNames(
                      { "p-error": isFormFieldValid("lastName") },
                      "Label__Text"
                    )}
                  >
                    Last Name<span className="Label__Required">*</span>
                  </label>
                  <InputText
                    maxLength={20}
                    id="lastName"
                    placeholder="Please Enter lastName"
                    name="lastName"
                    value={formik?.values?.lastName?.toString() || ""}
                    onChange={formik.handleChange}
                    className={classNames(
                      { "p-invalid": isFormFieldValid("lastName") },
                      "Input__Round"
                    )}
                  />

                  {getFormErrorMessage("lastName")}
                </div>
              </div>
              <div className="p-field col-6 md:col-4">
                <div className="p-field">
                  <label
                    htmlFor="fatherName"
                    className={classNames(
                      {
                        "p-error": isFormFieldValid(
                          "familyDetails[0].fatherName"
                        ),
                      },
                      "Label__Text"
                    )}
                  >
                    Employee Father Name
                    <span className="Label__Required">*</span>
                  </label>
                  <InputText
                    maxLength={30}
                    id="fatherName"
                    placeholder="Please Enter Father Name"
                    name="familyDetails[0].fatherName"
                    value={formik?.values?.familyDetails[0]?.fatherName || ""}
                    onChange={formik.handleChange}
                    className={classNames(
                      {
                        "p-invalid": isFormFieldValid(
                          "familyDetails[0].fatherName"
                        ),
                      },
                      "Input__Round"
                    )}
                  />
                  {formik.touched.familyDetails?.[0]?.fatherName &&
                  formik.errors.familyDetails?.[0]?.fatherName ? (
                    <small className="p-error">
                      {formik.errors.familyDetails[0].fatherName}
                    </small>
                  ) : null}
                </div>
              </div>
              <div className="p-field col-6 md:col-4">
                <div className="p-field">
                  <label
                    htmlFor="cnicExpirey"
                    className={classNames(
                      {
                        "p-error": isFormFieldValid(
                          "familyDetails[0].cnicExpirey"
                        ),
                      },
                      "Label__Text"
                    )}
                  >
                    Cnic expirey date<span className="Label__Required">*</span>
                  </label>
                  <InputText
                    type="date"
                    id="cnicExpirey"
                    placeholder="Please Enter CNIC Expirey"
                    name="cnicExpirey"
                    value={formik?.values?.cnicExpirey || ""}
                    onChange={formik.handleChange}
                    className={classNames(
                      { "p-invalid": isFormFieldValid("cnicExpirey") },
                      "Input__Round"
                    )}
                  />
                  {formik.touched.cnicExpirey && formik.errors.cnicExpirey ? (
                    <small className="p-error">
                      {formik.errors.cnicExpirey}
                    </small>
                  ) : null}
                </div>
              </div>
              <div className="p-field col-6 md:col-4">
                <div className="p-field">
                  <label
                    htmlFor="grade"
                    className={classNames(
                      { "p-error": isFormFieldValid("grade") },
                      "Label__Text"
                    )}
                  >
                    Grade<span className="Label__Required">*</span>
                  </label>
                  <Dropdown
                    options={grades}
                    optionLabel="gradeName"
                    optionValue="id"
                    maxLength={20}
                    id="grade"
                    placeholder="Please Select Grade"
                    name="grade"
                    value={formik?.values?.grade || ""}
                    onChange={formik.handleChange}
                    className={classNames(
                      { "p-invalid": isFormFieldValid("grade") },
                      "Dropdown__Round"
                    )}
                  />

                  {getFormErrorMessage("grade")}
                </div>
              </div>
              <div className="p-field col-6 md:col-4">
                <div className="p-field">
                  <label
                    htmlFor="rank"
                    className={classNames(
                      { "p-error": isFormFieldValid("rank") },
                      "Label__Text"
                    )}
                  >
                    Rank<span className="Label__Required">*</span>
                  </label>
                  <Dropdown
                    filter
                    options={ranks}
                    optionLabel="rankName"
                    optionValue="id"
                    maxLength={20}
                    id="rank"
                    placeholder="Please Select Rank"
                    name="rank"
                    value={formik?.values?.rank || ""}
                    onChange={formik.handleChange}
                    className={classNames(
                      { "p-invalid": isFormFieldValid("rank") },
                      "Dropdown__Round"
                    )}
                  />

                  {getFormErrorMessage("rank")}
                </div>
              </div>

              <div className="p-field col-6 md:col-4">
                <div className="p-field">
                  <label
                    htmlFor="location"
                    className={classNames(
                      { "p-error": isFormFieldValid("location") },
                      "Label__Text"
                    )}
                  >
                    Location<span className="Label__Required">*</span>
                  </label>
                  <Dropdown
                    options={locations}
                    optionLabel="locationName"
                    optionValue="id"
                    maxLength={20}
                    id="location"
                    placeholder="Please Select Location"
                    name="location"
                    value={formik?.values?.location || ""}
                    onChange={formik.handleChange}
                    className={classNames(
                      { "p-invalid": isFormFieldValid("location") },
                      "Dropdown__Round"
                    )}
                  />
                  <small className="p-error">{formik.errors.location}</small>
                </div>
              </div>
              <div className="p-field col-6 md:col-4">
                <div className="p-field">
                  <label
                    htmlFor="dateOfJoining"
                    className={classNames(
                      { "p-error": isFormFieldValid("dateOfJoining") },
                      "Label__Text"
                    )}
                  >
                    Date of Joining of First Job
                    <span className="Label__Required">*</span>
                  </label>
                  <InputText
                    type="date"
                    id="dateOfJoining"
                    name="dateOfJoining"
                    value={formik?.values?.dateOfJoining || ""}
                    onChange={(e) => calculateMonthsSince(e.target.value)}
                    className={classNames(
                      { "p-invalid": isFormFieldValid("dateOfJoining") },
                      "Input__Round"
                    )}
                  />
                  {getFormErrorMessage("dateOfJoining")}
                </div>
              </div>
              <div className="p-field col-6 md:col-4">
                <div className="p-field">
                  <label
                    htmlFor="emergencyNumber"
                    className={classNames(
                      { "p-error": isFormFieldValid("emergencyNumber") },
                      "Label__Text"
                    )}
                  >
                    Emergency Number<span className="Label__Required">*</span>
                  </label>
                  <InputText
                    maxLength={11}
                    keyfilter="int"
                    id="emergencyNumber"
                    placeholder="Please Enter Emergency Number"
                    name="emergencyNumber"
                    value={formik?.values?.emergencyNumber || ""}
                    onChange={formik.handleChange}
                    className={classNames(
                      { "p-invalid": isFormFieldValid("emergencyNumber") },
                      "Input__Round"
                    )}
                  />

                  {getFormErrorMessage("emergencyNumber")}
                </div>
              </div>
              <div className="p-field col-6 md:col-4">
                <div className="p-field">
                  <label
                    htmlFor="officialEmail"
                    className={classNames(
                      { "p-error": isFormFieldValid("officialEmail") },
                      "Label__Text"
                    )}
                  >
                    Official Email<span className="Label__Required">*</span>
                  </label>
                  <InputText
                    maxLength={40}
                    id="officialEmail"
                    placeholder="Please Enter Official Email"
                    name="officialEmail"
                    value={formik?.values?.officialEmail || ""}
                    onChange={formik.handleChange}
                    className={classNames(
                      { "p-invalid": isFormFieldValid("officialEmail") },
                      "Input__Round"
                    )}
                  />

                  {getFormErrorMessage("officialEmail")}
                </div>
              </div>
              <div className="p-field col-6 md:col-4">
                <div className="p-field">
                  <label
                    htmlFor="reportingManager"
                    className={classNames(
                      { "p-error": isFormFieldValid("reportingManager") },
                      "Label__Text"
                    )}
                  >
                    Reporting Manager<span className="Label__Required">*</span>
                  </label>
                  <InputText
                    maxLength={40}
                    id="reportingManager"
                    placeholder="Please Enter Manager's Email"
                    name="reportingManager"
                    value={formik?.values?.reportingManager || ""}
                    onChange={formik.handleChange}
                    className={classNames(
                      { "p-invalid": isFormFieldValid("reportingManager") },
                      "Input__Round"
                    )}
                  />

                  {getFormErrorMessage("reportingManager")}
                </div>
              </div>
              <div className="p-field col-6 md:col-4">
                <div className="p-field">
                  <label
                    htmlFor="departmentId"
                    className={classNames(
                      { "p-error": isFormFieldValid("departmentId") },
                      "Label__Text"
                    )}
                  >
                    Department<span className="Label__Required">*</span>
                  </label>
                  <Dropdown
                    options={departmentList}
                    optionValue="id"
                    optionLabel="departmentName"
                    maxLength={20}
                    id="departmentId"
                    placeholder="Please Select Department"
                    name="departmentId"
                    value={formik?.values?.departmentId || ""}
                    onChange={formik.handleChange}
                    className={classNames(
                      { "p-invalid": isFormFieldValid("departmentId") },
                      "Dropdown__Round"
                    )}
                  />
                  <small className="p-error">
                    {formik.errors.departmentId}
                  </small>
                </div>
              </div>
              <div className="p-field col-6 md:col-4">
                <div className="p-field">
                  <label
                    htmlFor="experience"
                    className={classNames(
                      { "p-error": isFormFieldValid("experience") },
                      "Label__Text"
                    )}
                  >
                    Experience<span className="Label__Required">*</span>
                  </label>
                  <InputText
                    disabled
                    maxLength={20}
                    id="experience"
                    placeholder="Please Enter Experience"
                    name="experience"
                    value={formik?.values?.experience?.toString() || ""}
                    onChange={formik.handleChange}
                    className={classNames(
                      { "p-invalid": isFormFieldValid("experience") },
                      "Input__Round"
                    )}
                  />
                  {getFormErrorMessage("experience")}
                </div>
              </div>
              <div className="p-field col-6 md:col-4">
                <div className="p-field">
                  <label
                    htmlFor="dateOfJoiningAis"
                    className={classNames(
                      { "p-error": isFormFieldValid("dateOfJoiningAis") },
                      "Label__Text"
                    )}
                  >
                    Date of Joining AIS
                    <span className="Label__Required">*</span>
                  </label>
                  <InputText
                    disabled={editable ? true : false}
                    type="date"
                    id="dateOfJoiningAis"
                    name="dateOfJoiningAis"
                    value={formik?.values?.dateOfJoiningAis || ""}
                    onChange={(e) => {
                      formik.handleChange(e);
                      const dateOfJoining = e.target.value;

                      const probationEndDate = new Date(dateOfJoining);
                      probationEndDate.setMonth(
                        probationEndDate.getMonth() + 3
                      );
                      formik.setFieldValue(
                        "probationEndDate",
                        probationEndDate.toISOString().split("T")[0]
                      );
                    }}
                    className={classNames(
                      { "p-invalid": isFormFieldValid("dateOfJoiningAis") },
                      "Input__Round"
                    )}
                  />
                  <small className="p-error">
                    {formik.errors.dateOfJoiningAis}
                  </small>
                </div>
              </div>
              <div className="p-field col-6 md:col-4">
                <div className="p-field">
                  <label
                    htmlFor="probationEndDate"
                    className={classNames(
                      { "p-error": isFormFieldValid("probationEndDate") },
                      "Label__Text"
                    )}
                  >
                    Probation End Date<span className="Label__Required">*</span>
                  </label>
                  <InputText
                    type="date"
                    id="probationEndDate"
                    name="probationEndDate"
                    value={formik?.values?.probationEndDate || ""}
                    onChange={formik.handleChange}
                    className={classNames(
                      { "p-invalid": isFormFieldValid("probationEndDate") },
                      "Input__Round"
                    )}
                  />
                  <small className="p-error">
                    {formik.errors.probationEndDate}
                  </small>
                </div>
              </div>
              <div className="p-field col-6 md:col-4">
                <div className="p-field">
                  <label
                    htmlFor="dateOfDischarge"
                    className={classNames(
                      { "p-error": isFormFieldValid("dateOfDischarge") },
                      "Label__Text"
                    )}
                  >
                    Please specify date of separation
                  </label>
                  <InputText
                    type="date"
                    id="dateOfDischarge"
                    name="dateOfDischarge"
                    value={formik?.values?.dateOfDischarge || ""}
                    onChange={formik.handleChange}
                    className={classNames(
                      { "p-invalid": isFormFieldValid("dateOfDischarge") },
                      "Input__Round"
                    )}
                  />

                  {getFormErrorMessage("dateOfDischarge")}
                </div>
              </div>

              <Divider align="left">
                <div className="inline-flex align-items-center">
                  <b>Salary Details</b>
                </div>
              </Divider>
              <div className="p-field col-6 md:col-4">
                <div className="p-field">
                  <label
                    htmlFor="grossSalary"
                    className={classNames(
                      { "p-error": isFormFieldValid("grossSalary") },
                      "Label__Text"
                    )}
                  >
                    Gross Salary<span className="Label__Required">*</span>
                  </label>
                  <InputText
                    disabled={editable ? true : false}
                    keyfilter="int"
                    maxLength={10}
                    id="salary"
                    placeholder="Please Enter Gross Salary"
                    name="salary"
                    value={formik?.values?.salary || ""}
                    onChange={(e) => {
                      formik.handleChange(e);
                      const grossSalary = parseFloat(e.target.value) || 0;

                      formik.setFieldValue(
                        "basicSalary",
                        (grossSalary * 0.6).toFixed(2)
                      );
                      formik.setFieldValue(
                        "hra",
                        (grossSalary * 0.2).toFixed(2)
                      );
                      formik.setFieldValue(
                        "utilityAllowance",
                        (grossSalary * 0.1).toFixed(2)
                      );
                      formik.setFieldValue(
                        "colaAllowance",
                        (grossSalary * 0.1).toFixed(2)
                      );
                    }}
                    className={classNames(
                      { "p-invalid": isFormFieldValid("salary") },
                      "Input__Round"
                    )}
                  />
                  {getFormErrorMessage("salary")}
                </div>
              </div>

              <div className="p-field col-6 md:col-4">
                <div className="p-field">
                  <label htmlFor="basicSalary" className="Label__Text">
                    Basic Salary
                  </label>
                  <InputText
                    disabled
                    value={formik?.values?.basicSalary || ""}
                    className="Input__Round"
                  />
                </div>
              </div>

              <div className="p-field col-6 md:col-4">
                <div className="p-field">
                  <label htmlFor="hra" className="Label__Text">
                    House Rent Allowance
                  </label>
                  <InputText
                    disabled
                    value={formik?.values?.hra || ""}
                    className="Input__Round"
                  />
                </div>
              </div>

              <div className="p-field col-6 md:col-4">
                <div className="p-field">
                  <label htmlFor="utilityAllowance" className="Label__Text">
                    Utility Allowance
                  </label>
                  <InputText
                    disabled
                    value={formik?.values?.utilityAllowance || ""}
                    className="Input__Round"
                  />
                </div>
              </div>

              <div className="p-field col-6 md:col-4">
                <div className="p-field">
                  <label htmlFor="colaAllowance" className="Label__Text">
                    Cola Allowance
                  </label>
                  <InputText
                    disabled
                    value={formik?.values?.colaAllowance || ""}
                    className="Input__Round"
                  />
                </div>
              </div>
              {formik.values.incrementDetails?.map((item, index) => {
                return (
                  <div className="grid col-12 mb-5">
                    <div
                      className="p-field flex align-item-center pt-5"
                      key={`id-${index}`}
                    >
                      <h5 className="mt-2">{index + 1}:</h5>
                    </div>
                    <div className="p-field col-6 md:col-4">
                      <label
                        htmlFor={`incrementDetails${index}`}
                        className={classNames(
                          {
                            "p-error": isFormFieldValid(
                              `incrementDetails[${index}].increment`
                            ),
                          },
                          "Label__Text"
                        )}
                      >
                        Increment Amount
                        <span className="Label__Required">*</span>
                      </label>
                      <InputText
                        disabled={editable ? true : false}
                        maxLength={8}
                        keyfilter="int"
                        placeholder="Please Enter Increment Amount"
                        id={`incrementDetails${index}`}
                        name={`incrementDetails[${index}].increment`}
                        value={item.increment || ""}
                        onChange={formik.handleChange}
                        className={classNames(
                          {
                            "p-invalid": isFormFieldValid(
                              `incrementDetails[${index}].increment`
                            ),
                          },
                          "Input__Round"
                        )}
                      />
                    </div>
                    <div className="p-field col-6 md:col-4" key={`id-${index}`}>
                      <label
                        htmlFor={`incrementDate${index}`}
                        className={classNames(
                          {
                            "p-error": isFormFieldValid(
                              `incrementDetails[${index}].incrementDate`
                            ),
                          },
                          "Label__Text"
                        )}
                      >
                        Increment Date
                        <span className="Label__Required">*</span>
                      </label>
                      <InputText
                        disabled={editable ? true : false}
                        type="date"
                        id={`incrementDate${index}`}
                        name={`incrementDetails[${index}].incrementDate`}
                        value={item.incrementDate || ""}
                        onChange={formik.handleChange}
                        className={classNames(
                          {
                            "p-invalid": isFormFieldValid(
                              `incrementDetails[${index}].incrementDate`
                            ),
                          },
                          "Input__Round"
                        )}
                      />
                      {formik.touched.incrementDetails?.[0]?.incrementDate &&
                      formik.errors.incrementDetails?.[0]?.incrementDate ? (
                        <small className="p-error">
                          {formik.errors.incrementDetails[0].incrementDate}
                        </small>
                      ) : null}
                    </div>
                    <div className="flex flex-column  align-items-center justify-content-center col-1">
                      <Button
                        icon="pi pi-plus"
                        className="p-button-rounded"
                        aria-label="Filter"
                        tooltip="Add new version"
                        style={{ border: "none" }}
                        onClick={(e) => {
                          e.preventDefault();
                          addIncrementCard();
                        }}
                      />
                      <Button
                        icon="pi pi-minus"
                        className="p-button-rounded mt-2 p-button-danger"
                        aria-label="Filter"
                        tooltip="Delete this version"
                        disabled={formik.values.incrementDetails.length <= 1}
                        onClick={(e) => {
                          e.preventDefault();
                          removeIncrementCard(index);
                        }}
                      />
                    </div>
                  </div>
                );
              })}

              <Divider align="left">
                <div className="inline-flex align-items-center">
                  <b>Education Details</b>
                </div>
              </Divider>
              <div className="p-field col-6 md:col-4">
                <div className="p-field">
                  <label
                    htmlFor="matricInstitute"
                    className={classNames(
                      {
                        "p-error": isFormFieldValid(
                          "educationalDetails[0].matricInstitute"
                        ),
                      },
                      "Label__Text"
                    )}
                  >
                    Matric / O-Level Institute
                    <span className="Label__Required">*</span>
                  </label>
                  <InputText
                    keyfilter={/^[a-zA-Z\s]*$/}
                    maxLength={30}
                    id="matricInstitute"
                    placeholder="Please Enter Institute Name"
                    name="educationalDetails[0].matricInstitute"
                    value={
                      formik?.values?.educationalDetails[0]?.matricInstitute ||
                      ""
                    }
                    onChange={formik.handleChange}
                    className={classNames(
                      {
                        "p-invalid": isFormFieldValid(
                          "educationalDetails[0].matricInstitute"
                        ),
                      },
                      "Input__Round"
                    )}
                  />
                  {formik.touched.educationalDetails?.[0]?.matricInstitute &&
                  formik.errors.educationalDetails?.[0]?.matricInstitute ? (
                    <small className="p-error">
                      {formik.errors.educationalDetails[0].matricInstitute}
                    </small>
                  ) : null}
                </div>
              </div>

              <div className="p-field col-6 md:col-4">
                <div className="p-field">
                  <label
                    htmlFor="matricPassingYear"
                    className={classNames(
                      {
                        "p-error": isFormFieldValid(
                          "educationalDetails[0].matricPassingYear"
                        ),
                      },
                      "Label__Text"
                    )}
                  >
                    Matric / O-Level Passing Year
                    <span className="Label__Required">*</span>
                  </label>
                  <InputText
                    type="date"
                    id="matricPassingYear"
                    name="educationalDetails[0].matricPassingYear"
                    value={
                      formik?.values?.educationalDetails[0]
                        ?.matricPassingYear || ""
                    }
                    onChange={formik.handleChange}
                    className={classNames(
                      {
                        "p-invalid": isFormFieldValid(
                          "educationalDetails[0].matricPassingYear"
                        ),
                      },
                      "Input__Round"
                    )}
                  />
                  {formik.touched.educationalDetails?.[0]?.matricPassingYear &&
                  formik.errors.educationalDetails?.[0]?.matricPassingYear ? (
                    <small className="p-error">
                      {formik.errors.educationalDetails[0].matricPassingYear}
                    </small>
                  ) : null}
                </div>
              </div>

              <div className="p-field col-6 md:col-4">
                <div className="p-field">
                  <label
                    htmlFor="matricResult"
                    className={classNames(
                      {
                        "p-error": isFormFieldValid(
                          "educationalDetails[0].matricResult"
                        ),
                      },
                      "Label__Text"
                    )}
                  >
                    Matric / O-Level Result
                    <span className="Label__Required">*</span>
                  </label>
                  <InputText
                    keyfilter="int"
                    maxLength={4}
                    id="matricResult"
                    placeholder="Please Enter Marks Percentage"
                    name="educationalDetails[0].matricResult"
                    value={
                      formik?.values?.educationalDetails[0]?.matricResult || ""
                    }
                    onChange={formik.handleChange}
                    className={classNames(
                      {
                        "p-invalid": isFormFieldValid(
                          "educationalDetails[0].matricResult"
                        ),
                      },
                      "Input__Round"
                    )}
                  />
                  {formik.touched.educationalDetails?.[0]?.matricResult &&
                  formik.errors.educationalDetails?.[0]?.matricResult ? (
                    <small className="p-error">
                      {formik.errors.educationalDetails[0].matricResult}
                    </small>
                  ) : null}
                </div>
              </div>

              <div className="p-field col-6 md:col-4">
                <div className="p-field">
                  <label
                    htmlFor="interInstitute"
                    className={classNames(
                      {
                        "p-error": isFormFieldValid(
                          "educationalDetails[0].interInstitute"
                        ),
                      },
                      "Label__Text"
                    )}
                  >
                    Intermediate / A-Level Institute
                    <span className="Label__Required">*</span>
                  </label>
                  <InputText
                    keyfilter={/^[a-zA-Z\s]*$/}
                    maxLength={30}
                    id="interInstitute"
                    placeholder="Please Enter Institute Name"
                    name="educationalDetails[0].interInstitute"
                    value={
                      formik?.values?.educationalDetails[0]?.interInstitute ||
                      ""
                    }
                    onChange={formik.handleChange}
                    className={classNames(
                      {
                        "p-invalid": isFormFieldValid(
                          "educationalDetails[0].interInstitute"
                        ),
                      },
                      "Input__Round"
                    )}
                  />
                  {formik.touched.educationalDetails?.[0]?.interInstitute &&
                  formik.errors.educationalDetails?.[0]?.interInstitute ? (
                    <small className="p-error">
                      {formik.errors.educationalDetails[0].interInstitute}
                    </small>
                  ) : null}
                </div>
              </div>

              <div className="p-field col-6 md:col-4">
                <div className="p-field">
                  <label
                    htmlFor="interPassingYear"
                    className={classNames(
                      {
                        "p-error": isFormFieldValid(
                          "educationalDetails[0].interPassingYear"
                        ),
                      },
                      "Label__Text"
                    )}
                  >
                    Intermediate / A-Level Passing Year
                    <span className="Label__Required">*</span>
                  </label>
                  <InputText
                    type="date"
                    id="interPassingYear"
                    name="educationalDetails[0].interPassingYear"
                    value={
                      formik?.values?.educationalDetails[0]?.interPassingYear ||
                      ""
                    }
                    onChange={formik.handleChange}
                    className={classNames(
                      {
                        "p-invalid": isFormFieldValid(
                          "educationalDetails[0].interPassingYear"
                        ),
                      },
                      "Input__Round"
                    )}
                  />
                  {formik.touched.educationalDetails?.[0]?.interPassingYear &&
                  formik.errors.educationalDetails?.[0]?.interPassingYear ? (
                    <small className="p-error">
                      {formik.errors.educationalDetails[0].interPassingYear}
                    </small>
                  ) : null}
                </div>
              </div>

              <div className="p-field col-6 md:col-4">
                <div className="p-field">
                  <label
                    htmlFor="interResult"
                    className={classNames(
                      {
                        "p-error": isFormFieldValid(
                          "educationalDetails[0].interResult"
                        ),
                      },
                      "Label__Text"
                    )}
                  >
                    Intermediate / A-Level Result
                    <span className="Label__Required">*</span>
                  </label>
                  <InputText
                    keyfilter="int"
                    maxLength={4}
                    id="interResult"
                    placeholder="Please Enter Marks Percentage"
                    name="educationalDetails[0].interResult"
                    value={
                      formik?.values?.educationalDetails[0]?.interResult || ""
                    }
                    onChange={formik.handleChange}
                    className={classNames(
                      {
                        "p-invalid": isFormFieldValid(
                          "educationalDetails[0].interResult"
                        ),
                      },
                      "Input__Round"
                    )}
                  />
                  {formik.touched.educationalDetails?.[0]?.interResult &&
                  formik.errors.educationalDetails?.[0]?.interResult ? (
                    <small className="p-error">
                      {formik.errors.educationalDetails[0].interResult}
                    </small>
                  ) : null}
                </div>
              </div>

              <div className="p-field col-6 md:col-4">
                <div className="p-field">
                  <label
                    htmlFor="bachelorsInstitute"
                    className={classNames(
                      {
                        "p-error": isFormFieldValid(
                          "educationalDetails[0].bachelorsInstitute"
                        ),
                      },
                      "Label__Text"
                    )}
                  >
                    Bachelors Institute
                    <span className="Label__Required">*</span>
                  </label>
                  <InputText
                    keyfilter={/^[a-zA-Z\s]*$/}
                    maxLength={30}
                    id="bachelorsInstitute"
                    placeholder="Please Enter Institute Name"
                    name="educationalDetails[0].bachelorsInstitute"
                    value={
                      formik?.values?.educationalDetails[0]
                        ?.bachelorsInstitute || ""
                    }
                    onChange={formik.handleChange}
                    className={classNames(
                      {
                        "p-invalid": isFormFieldValid(
                          "educationalDetails[0].bachelorsInstitute"
                        ),
                      },
                      "Input__Round"
                    )}
                  />
                  {formik.touched.educationalDetails?.[0]?.bachelorsInstitute &&
                  formik.errors.educationalDetails?.[0]?.bachelorsInstitute ? (
                    <small className="p-error">
                      {formik.errors.educationalDetails[0].bachelorsInstitute}
                    </small>
                  ) : null}
                </div>
              </div>

              <div className="p-field col-6 md:col-4">
                <div className="p-field">
                  <label
                    htmlFor="degree"
                    className={classNames(
                      { "p-error": isFormFieldValid("degree") },
                      "Label__Text"
                    )}
                  >
                    Degree Title<span className="Label__Required">*</span>
                  </label>
                  <InputText
                    keyfilter={/^[a-zA-Z\s]*$/}
                    maxLength={20}
                    id="degree"
                    placeholder="Please Enter Degree Name"
                    name="educationalDetails[0].degree"
                    value={formik?.values?.educationalDetails[0]?.degree || ""}
                    onChange={formik.handleChange}
                    className={classNames(
                      { "p-invalid": isFormFieldValid("degree") },
                      "Input__Round"
                    )}
                  />

                  {formik.touched.educationalDetails?.[0]?.degree &&
                  formik.errors.educationalDetails?.[0]?.degree ? (
                    <small className="p-error">
                      {formik.errors.educationalDetails[0].degree}
                    </small>
                  ) : null}
                </div>
              </div>
              <div className="p-field col-6 md:col-4">
                <div className="p-field">
                  <label
                    htmlFor="bachelorsPassingYear"
                    className={classNames(
                      { "p-error": isFormFieldValid("bachelorsPassingYear") },
                      "Label__Text"
                    )}
                  >
                    Bachelors Passing Year
                    <span className="Label__Required">*</span>
                  </label>
                  <InputText
                    type="date"
                    id="bachelorsPassingYear"
                    name="educationalDetails[0].bachelorsPassingYear"
                    value={
                      formik?.values?.educationalDetails[0]
                        ?.bachelorsPassingYear || ""
                    }
                    onChange={formik.handleChange}
                    className={classNames(
                      { "p-invalid": isFormFieldValid("bachelorsPassingYear") },
                      "Input__Round"
                    )}
                  />

                  {formik.touched.educationalDetails?.[0]
                    ?.bachelorsPassingYear &&
                  formik.errors.educationalDetails?.[0]
                    ?.bachelorsPassingYear ? (
                    <small className="p-error">
                      {formik.errors.educationalDetails[0].bachelorsPassingYear}
                    </small>
                  ) : null}
                </div>
              </div>
              <div className="p-field col-6 md:col-4">
                <div className="p-field">
                  <label
                    htmlFor="bachelorsCgpa"
                    className={classNames(
                      { "p-error": isFormFieldValid("bachelorsCgpa") },
                      "Label__Text"
                    )}
                  >
                    Bachelors CGPA<span className="Label__Required">*</span>
                  </label>
                  <InputText
                    keyfilter={/^[0-3]?[.]?\d{0,2}$|^4([.]00?)?$/}
                    maxLength={4}
                    id="bachelorsCgpa"
                    placeholder="Please Enter CGPA"
                    name="educationalDetails[0].bachelorsCgpa"
                    value={
                      formik?.values?.educationalDetails[0]?.bachelorsCgpa || ""
                    }
                    onChange={formik.handleChange}
                    className={classNames(
                      { "p-invalid": isFormFieldValid("bachelorsCgpa") },
                      "Input__Round"
                    )}
                  />
                  {formik.touched.educationalDetails?.[0]?.bachelorsCgpa &&
                  formik.errors.educationalDetails?.[0]?.bachelorsCgpa ? (
                    <small className="p-error">
                      {formik.errors.educationalDetails[0].bachelorsCgpa}
                    </small>
                  ) : null}
                </div>
              </div>
              <div className="p-field col-6 md:col-4">
                <div className="p-field">
                  <label
                    htmlFor="mastersInstitute"
                    className={classNames(
                      { "p-error": isFormFieldValid("mastersInstitute") },
                      "Label__Text"
                    )}
                  >
                    Masters Institute Name
                    <span className="Label__Required">*</span>
                  </label>
                  <InputText
                    keyfilter={/^[a-zA-Z\s]*$/}
                    maxLength={20}
                    id="mastersInstitute"
                    placeholder="Please Enter Degree Name"
                    name="educationalDetails[0].mastersInstitute"
                    value={
                      formik?.values?.educationalDetails[0]?.mastersInstitute ||
                      ""
                    }
                    onChange={formik.handleChange}
                    className={classNames(
                      { "p-invalid": isFormFieldValid("mastersInstitute") },
                      "Input__Round"
                    )}
                  />
                  {formik.touched.educationalDetails?.[0]?.mastersInstitute &&
                  formik.errors.educationalDetails?.[0]?.mastersInstitute ? (
                    <small className="p-error">
                      {formik.errors.educationalDetails[0].masters}
                    </small>
                  ) : null}
                </div>
              </div>
              <div className="p-field col-6 md:col-4">
                <div className="p-field">
                  <label
                    htmlFor="masters"
                    className={classNames(
                      { "p-error": isFormFieldValid("masters") },
                      "Label__Text"
                    )}
                  >
                    Masters Degree Title
                    <span className="Label__Required">*</span>
                  </label>
                  <InputText
                    keyfilter={/^[a-zA-Z\s]*$/}
                    maxLength={20}
                    id="masters"
                    placeholder="Please Enter Degree Name"
                    name="educationalDetails[0].masters"
                    value={formik?.values?.educationalDetails[0]?.masters || ""}
                    onChange={formik.handleChange}
                    className={classNames(
                      { "p-invalid": isFormFieldValid("masters") },
                      "Input__Round"
                    )}
                  />
                  {formik.touched.educationalDetails?.[0]?.masters &&
                  formik.errors.educationalDetails?.[0]?.masters ? (
                    <small className="p-error">
                      {formik.errors.educationalDetails[0].masters}
                    </small>
                  ) : null}
                </div>
              </div>
              <div className="p-field col-6 md:col-4">
                <div className="p-field">
                  <label
                    htmlFor="mastersPassingYear"
                    className={classNames(
                      { "p-error": isFormFieldValid("mastersPassingYear") },
                      "Label__Text"
                    )}
                  >
                    Masters Passing Year
                    <span className="Label__Required">*</span>
                  </label>
                  <InputText
                    type="date"
                    id="mastersPassingYear"
                    placeholder="Please Enter Degree Name"
                    name="educationalDetails[0].mastersPassingYear"
                    value={
                      formik?.values?.educationalDetails[0]
                        ?.mastersPassingYear || ""
                    }
                    onChange={formik.handleChange}
                    className={classNames(
                      { "p-invalid": isFormFieldValid("mastersPassingYear") },
                      "Input__Round"
                    )}
                  />
                  {formik.touched.educationalDetails?.[0]?.mastersPassingYear &&
                  formik.errors.educationalDetails?.[0]?.mastersPassingYear ? (
                    <small className="p-error">
                      {formik.errors.educationalDetails[0].mastersPassingYear}
                    </small>
                  ) : null}
                </div>
              </div>
              <div className="p-field col-6 md:col-4">
                <div className="p-field">
                  <label
                    htmlFor="mastersCGPA"
                    className={classNames(
                      { "p-error": isFormFieldValid("mastersCGPA") },
                      "Label__Text"
                    )}
                  >
                    Masters CGPA<span className="Label__Required">*</span>
                  </label>
                  <InputText
                    keyfilter="int"
                    maxLength={4}
                    id="mastersCGPA"
                    placeholder="Please Enter Degree Name"
                    name="educationalDetails[0].mastersCGPA"
                    value={
                      formik?.values?.educationalDetails[0]?.mastersCGPA || ""
                    }
                    onChange={formik.handleChange}
                    className={classNames(
                      { "p-invalid": isFormFieldValid("mastersCGPA") },
                      "Input__Round"
                    )}
                  />
                  {formik.touched.educationalDetails?.[0]?.mastersCGPA &&
                  formik.errors.educationalDetails?.[0]?.mastersCGPA ? (
                    <small className="p-error">
                      {formik.errors.educationalDetails[0].mastersCGPA}
                    </small>
                  ) : null}
                </div>
              </div>
              <Divider align="left">
                <div className="inline-flex align-items-center">
                  <b>Certifications Details</b>
                </div>
              </Divider>
              {formik.values.certificationDetails?.map((item, index) => {
                return (
                  <div className="grid col-12 mb-5">
                    <div className="p-field col-6 md:col-3" key={`id-${index}`}>
                      <label
                        htmlFor={`certificationName${index}`}
                        className={classNames(
                          {
                            "p-error": isFormFieldValid(
                              `certificationDetails[${index}].certificationName`
                            ),
                          },
                          "Label__Text"
                        )}
                      >
                        Certification Name
                        <span className="Label__Required">*</span>
                      </label>
                      <InputText
                        keyfilter={/^[a-zA-Z\s]*$/}
                        maxLength={50}
                        placeholder="Please Enter Certification Name"
                        id={`certificationName${index}`}
                        name={`certificationDetails[${index}].certificationName`}
                        value={item.certificationName || ""}
                        onChange={formik.handleChange}
                        className={classNames(
                          {
                            "p-invalid": isFormFieldValid(
                              `certificationDetails[${index}].certificationName`
                            ),
                          },
                          "Input__Round"
                        )}
                      />
                    </div>
                    <div className="p-field col-6 md:col-3" key={`id-${index}`}>
                      <label
                        htmlFor={`startDate${index}`}
                        className={classNames(
                          {
                            "p-error": isFormFieldValid(
                              `certificationDetails[${index}].startDate`
                            ),
                          },
                          "Label__Text"
                        )}
                      >
                        Start Date
                        <span className="Label__Required">*</span>
                      </label>
                      <InputText
                        type="date"
                        id={`startDate${index}`}
                        name={`certificationDetails[${index}].startDate`}
                        value={item.startDate || ""}
                        onChange={formik.handleChange}
                        className={classNames(
                          {
                            "p-invalid": isFormFieldValid(
                              `certificationDetails[${index}].startDate`
                            ),
                          },
                          "Input__Round"
                        )}
                      />
                      {formik.touched.certificationDetails?.[0]?.startDate &&
                      formik.errors.certificationDetails?.[0]?.startDate ? (
                        <small className="p-error">
                          {formik.errors.certificationDetails[0].startDate}
                        </small>
                      ) : null}
                    </div>
                    <div className="p-field col-6 md:col-3" key={`id-${index}`}>
                      <label
                        htmlFor={`endDate${index}`}
                        className={classNames(
                          {
                            "p-error": isFormFieldValid(
                              `certificationDetails[${index}].endDate`
                            ),
                          },
                          "Label__Text"
                        )}
                      >
                        End Date
                        <span className="Label__Required">*</span>
                      </label>
                      <InputText
                        type="date"
                        id={`endDate${index}`}
                        name={`certificationDetails[${index}].endDate`}
                        value={item.endDate || ""}
                        onChange={formik.handleChange}
                        className={classNames(
                          {
                            "p-invalid": isFormFieldValid(
                              `certificationDetails[${index}].endDate`
                            ),
                          },
                          "Input__Round"
                        )}
                      />
                      {formik.touched.certificationDetails?.[0]?.endDate &&
                      formik.errors.certificationDetails?.[0]?.endDate ? (
                        <small className="p-error">
                          {formik.errors.certificationDetails[0].endDate}
                        </small>
                      ) : null}
                    </div>
                    <div className="flex flex-column  align-items-center justify-content-center col-1">
                      <Button
                        icon="pi pi-plus"
                        className="p-button-rounded"
                        aria-label="Filter"
                        tooltip="Add new version"
                        style={{ border: "none" }}
                        onClick={(e) => {
                          e.preventDefault();
                          addProject();
                        }}
                      />
                      <Button
                        icon="pi pi-minus"
                        className="p-button-rounded mt-2 p-button-danger"
                        aria-label="Filter"
                        tooltip="Delete this version"
                        disabled={
                          formik.values.certificationDetails.length <= 1
                        }
                        onClick={(e) => {
                          e.preventDefault();
                          removeProject(index);
                        }}
                      />
                    </div>
                  </div>
                );
              })}
              <Divider align="left">
                <div className="inline-flex align-items-center">
                  <b>Experience Details</b>
                </div>
              </Divider>
              <div className="inline-flex align-items-center">
                <b>Starting from latest experience.</b>
              </div>
              {formik.values.experienceDetails.map((item, index) => {
                return (
                  <div className="grid col-12 mb-5">
                    <div className="p-field col-6 md:col-4" key={`id-${index}`}>
                      <label
                        htmlFor={`companyName${index}`}
                        className={classNames(
                          {
                            "p-error": isFormFieldValid(
                              `experienceDetails[${index}].companyName`
                            ),
                          },
                          "Label__Text"
                        )}
                      >
                        Company Name
                        <span className="Label__Required">*</span>
                      </label>
                      <InputText
                        maxLength={30}
                        placeholder="Please Enter Company Name"
                        id={`companyName${index}`}
                        name={`experienceDetails[${index}].companyName`}
                        value={item.companyName || ""}
                        onChange={formik.handleChange}
                        className={classNames(
                          {
                            "p-invalid": isFormFieldValid(
                              `experienceDetails[${index}].companyName`
                            ),
                          },
                          "Input__Round"
                        )}
                      />
                    </div>
                    <div className="p-field col-6 md:col-4" key={`id-${index}`}>
                      <label
                        htmlFor={`department${index}`}
                        className={classNames(
                          {
                            "p-error": isFormFieldValid(
                              `experienceDetails[${index}].department`
                            ),
                          },
                          "Label__Text"
                        )}
                      >
                        Department
                        <span className="Label__Required">*</span>
                      </label>
                      <InputText
                        keyfilter={/^[a-zA-Z\s]*$/}
                        maxLength={20}
                        placeholder="Please Enter Department Name"
                        id={`department${index}`}
                        name={`experienceDetails[${index}].department`}
                        value={item.department || ""}
                        onChange={formik.handleChange}
                        className={classNames(
                          {
                            "p-invalid": isFormFieldValid(
                              `experienceDetails[${index}].department`
                            ),
                          },
                          "Input__Round"
                        )}
                      />
                    </div>
                    <div className="p-field col-6 md:col-4" key={`id-${index}`}>
                      <label
                        htmlFor={`designation${index}`}
                        className={classNames(
                          {
                            "p-error": isFormFieldValid(
                              `experienceDetails[${index}].designation`
                            ),
                          },
                          "Label__Text"
                        )}
                      >
                        Designation
                        <span className="Label__Required">*</span>
                      </label>
                      <InputText
                        keyfilter={/^[a-zA-Z\s]*$/}
                        maxLength={30}
                        placeholder="Please Enter Company Name"
                        id={`designation${index}`}
                        name={`experienceDetails[${index}].designation`}
                        value={item.designation || ""}
                        onChange={formik.handleChange}
                        className={classNames(
                          {
                            "p-invalid": isFormFieldValid(
                              `experienceDetails[${index}].designation`
                            ),
                          },
                          "Input__Round"
                        )}
                      />
                    </div>
                    <div className="p-field col-6 md:col-4" key={`id-${index}`}>
                      <label
                        htmlFor={`fromDate${index}`}
                        className={classNames(
                          {
                            "p-error": isFormFieldValid(
                              `experienceDetails[${index}].fromDate`
                            ),
                          },
                          "Label__Text"
                        )}
                      >
                        Start Date
                        <span className="Label__Required">*</span>
                      </label>
                      <InputText
                        type="date"
                        id={`fromDate${index}`}
                        name={`experienceDetails[${index}].fromDate`}
                        value={item.fromDate || ""}
                        onChange={formik.handleChange}
                        className={classNames(
                          {
                            "p-invalid": isFormFieldValid(
                              `experienceDetails[${index}].fromDate`
                            ),
                          },
                          "Input__Round"
                        )}
                      />
                      {formik.touched.experienceDetails?.[0]?.fromDate &&
                      formik.errors.experienceDetails?.[0]?.fromDate ? (
                        <small className="p-error">
                          {formik.errors.experienceDetails[0].fromDate}
                        </small>
                      ) : null}
                    </div>
                    <div className="p-field col-6 md:col-4" key={`id-${index}`}>
                      <label
                        htmlFor={`toDate${index}`}
                        className={classNames(
                          {
                            "p-error": isFormFieldValid(
                              `experienceDetails[${index}].toDate`
                            ),
                          },
                          "Label__Text"
                        )}
                      >
                        End Date
                        <span className="Label__Required">*</span>
                      </label>
                      <InputText
                        type="date"
                        id={`toDate${index}`}
                        name={`experienceDetails[${index}].toDate`}
                        value={item.toDate || ""}
                        onChange={formik.handleChange}
                        className={classNames(
                          {
                            "p-invalid": isFormFieldValid(
                              `experienceDetails[${index}].toDate`
                            ),
                          },
                          "Input__Round"
                        )}
                      />
                      {formik.touched.experienceDetails?.[0]?.toDate &&
                      formik.errors.experienceDetails?.[0]?.toDate ? (
                        <small className="p-error">
                          {formik.errors.experienceDetails[0].toDate}
                        </small>
                      ) : null}
                    </div>
                    <div className="flex flex-column  align-items-center justify-content-center col-1">
                      <Button
                        icon="pi pi-plus"
                        className="p-button-rounded"
                        aria-label="Filter"
                        tooltip="Add new version"
                        style={{ border: "none" }}
                        onClick={(e) => {
                          e.preventDefault();
                          addExperience();
                        }}
                      />
                      <Button
                        icon="pi pi-minus"
                        className="p-button-rounded mt-2 p-button-danger"
                        aria-label="Filter"
                        tooltip="Delete this version"
                        disabled={formik.values.experienceDetails.length <= 1}
                        onClick={(e) => {
                          e.preventDefault();
                          removeExperience(index);
                        }}
                      />
                    </div>
                  </div>
                );
              })}
              <Divider align="left">
                <div className="inline-flex align-items-center">
                  <b>Employee Personal Details</b>
                </div>
              </Divider>
              <div className="p-field col-6 md:col-4">
                <div className="p-field">
                  <label
                    htmlFor="employeeEmail"
                    className={classNames(
                      {
                        "p-error": isFormFieldValid(
                          "personalDetails[0].employeeEmail"
                        ),
                      },
                      "Label__Text"
                    )}
                  >
                    Employee Personal Email
                    <span className="Label__Required">*</span>
                  </label>
                  <InputText
                    maxLength={40}
                    type="email"
                    id="employeeEmail"
                    placeholder="Please Enter Employee Personal Email"
                    name="personalDetails[0].employeeEmail"
                    value={
                      formik?.values?.personalDetails[0]?.employeeEmail || ""
                    }
                    onChange={formik.handleChange}
                    className={classNames(
                      {
                        "p-invalid": isFormFieldValid(
                          "personalDetails[0].employeeEmail"
                        ),
                      },
                      "Input__Round"
                    )}
                  />
                  {formik.touched.personalDetails?.[0]?.employeeEmail &&
                  formik.errors.personalDetails?.[0]?.employeeEmail ? (
                    <small className="p-error">
                      {formik.errors.personalDetails[0].employeeEmail}
                    </small>
                  ) : null}
                </div>
              </div>

              <div className="p-field col-6 md:col-4">
                <div className="p-field">
                  <label
                    htmlFor="mobileNo"
                    className={classNames(
                      {
                        "p-error": isFormFieldValid(
                          "personalDetails[0].mobileNo"
                        ),
                      },
                      "Label__Text"
                    )}
                  >
                    Employee Personal Contact Number
                    <span className="Label__Required">*</span>
                  </label>
                  <InputText
                    maxLength={11}
                    keyfilter="int"
                    id="mobileNo"
                    placeholder="Please Enter Employee Contact Number"
                    name="personalDetails[0].mobileNo"
                    value={formik?.values?.personalDetails[0]?.mobileNo || ""}
                    onChange={formik.handleChange}
                    className={classNames(
                      {
                        "p-invalid": isFormFieldValid(
                          "personalDetails[0].mobileNo"
                        ),
                      },
                      "Input__Round"
                    )}
                  />
                  {formik.touched.personalDetails?.[0]?.mobileNo &&
                  formik.errors.personalDetails?.[0]?.mobileNo ? (
                    <small className="p-error">
                      {formik.errors.personalDetails[0].mobileNo}
                    </small>
                  ) : null}
                </div>
              </div>

              <div className="p-field col-6 md:col-4">
                <div className="p-field">
                  <label
                    htmlFor="bloodGroup"
                    className={classNames(
                      {
                        "p-error": isFormFieldValid(
                          "personalDetails[0].bloodGroup"
                        ),
                      },
                      "Label__Text"
                    )}
                  >
                    Employee Blood Group
                    <span className="Label__Required">*</span>
                  </label>
                  <Dropdown
                    options={bloodGroupList}
                    optionLabel="label"
                    optionValue="value"
                    id="bloodGroup"
                    placeholder="Please Enter Employee Blood Group"
                    name="personalDetails[0].bloodGroup"
                    value={formik?.values?.personalDetails[0]?.bloodGroup || ""}
                    onChange={formik.handleChange}
                    className={classNames(
                      {
                        "p-invalid": isFormFieldValid(
                          "personalDetails[0].bloodGroup"
                        ),
                      },
                      "Drop__Down"
                    )}
                  />
                  {formik.touched.personalDetails?.[0]?.bloodGroup &&
                  formik.errors.personalDetails?.[0]?.bloodGroup ? (
                    <small className="p-error">
                      {formik.errors.personalDetails[0].bloodGroup}
                    </small>
                  ) : null}
                </div>
              </div>

              <div className="p-field col-6 md:col-4">
                <div className="p-field">
                  <label
                    htmlFor="maritalStatus"
                    className={classNames(
                      {
                        "p-error": isFormFieldValid(
                          "personalDetails[0].maritalStatus"
                        ),
                      },
                      "Label__Text"
                    )}
                  >
                    Employee Marital Status
                    <span className="Label__Required">*</span>
                  </label>
                  <Dropdown
                    options={maritalStatusLov}
                    optionLabel="label"
                    optionValue="value"
                    id="maritalStatus"
                    placeholder="Please Select Marital Status"
                    name="personalDetails[0].maritalStatus"
                    value={
                      formik?.values?.personalDetails[0]?.maritalStatus || ""
                    }
                    onChange={formik.handleChange}
                    className={classNames(
                      {
                        "p-invalid": isFormFieldValid(
                          "personalDetails[0].maritalStatus"
                        ),
                      },
                      "Dropdown__Round"
                    )}
                  />
                  {formik.touched.personalDetails?.[0]?.maritalStatus &&
                  formik.errors.personalDetails?.[0]?.maritalStatus ? (
                    <small className="p-error">
                      {formik.errors.personalDetails[0].maritalStatus}
                    </small>
                  ) : null}
                </div>
              </div>

              <div className="p-field col-6 md:col-4">
                <div className="p-field">
                  <label
                    htmlFor="address"
                    className={classNames(
                      {
                        "p-error": isFormFieldValid(
                          "personalDetails[0].address"
                        ),
                      },
                      "Label__Text"
                    )}
                  >
                    Employee Address<span className="Label__Required">*</span>
                  </label>
                  <InputText
                    maxLength={60}
                    id="address"
                    placeholder="Please Enter Employee Address"
                    name="personalDetails[0].address"
                    value={formik?.values?.personalDetails[0]?.address || ""}
                    onChange={formik.handleChange}
                    className={classNames(
                      {
                        "p-invalid": isFormFieldValid(
                          "personalDetails[0].address"
                        ),
                      },
                      "Input__Round"
                    )}
                  />
                  {formik.touched.personalDetails?.[0]?.address &&
                  formik.errors.personalDetails?.[0]?.address ? (
                    <small className="p-error">
                      {formik.errors.personalDetails[0].address}
                    </small>
                  ) : null}
                </div>
              </div>

              <div className="p-field col-6 md:col-4">
                <div className="p-field">
                  <label
                    htmlFor="dob"
                    className={classNames(
                      { "p-error": isFormFieldValid("personalDetails[0].dob") },
                      "Label__Text"
                    )}
                  >
                    Employee Date of Birth
                    <span className="Label__Required">*</span>
                  </label>
                  <InputText
                    type="date"
                    id="dob"
                    placeholder="Please Enter Date of Birth"
                    name="personalDetails[0].dob"
                    value={formik?.values?.personalDetails[0]?.dob || ""}
                    onChange={formik.handleChange}
                    className={classNames(
                      {
                        "p-invalid": isFormFieldValid("personalDetails[0].dob"),
                      },
                      "Input__Round"
                    )}
                  />
                  {formik.touched.personalDetails?.[0]?.dob &&
                  formik.errors.personalDetails?.[0]?.dob ? (
                    <small className="p-error">
                      {formik.errors.personalDetails[0].dob}
                    </small>
                  ) : null}
                </div>
              </div>
              <Divider align="left">
                <div className="inline-flex align-items-center">
                  <b>Employee Family Details</b>
                </div>
              </Divider>
              <div className="p-field col-6 md:col-4">
                <div className="p-field">
                  <label
                    htmlFor="nextOfKin"
                    className={classNames(
                      {
                        "p-error": isFormFieldValid(
                          "familyDetails[0].nextOfKin"
                        ),
                      },
                      "Label__Text"
                    )}
                  >
                    Next of Kin<span className="Label__Required">*</span>
                  </label>
                  <InputText
                    keyfilter={/^[a-zA-Z\s]*$/}
                    maxLength={30}
                    id="nextOfKin"
                    placeholder="Please Enter Next of Kin Name"
                    name="familyDetails[0].nextOfKin"
                    value={formik?.values?.familyDetails[0]?.nextOfKin || ""}
                    onChange={formik.handleChange}
                    className={classNames(
                      {
                        "p-invalid": isFormFieldValid(
                          "familyDetails[0].nextOfKin"
                        ),
                      },
                      "Input__Round"
                    )}
                  />
                  {formik.touched.familyDetails?.[0]?.nextOfKin &&
                  formik.errors.familyDetails?.[0]?.nextOfKin ? (
                    <small className="p-error">
                      {formik.errors.familyDetails[0].nextOfKin}
                    </small>
                  ) : null}
                </div>
              </div>
              <div className="p-field col-6 md:col-4">
                <div className="p-field">
                  <label
                    htmlFor="nextOfKinAddress"
                    className={classNames(
                      {
                        "p-error": isFormFieldValid(
                          "familyDetails[0].nextOfKinAddress"
                        ),
                      },
                      "Label__Text"
                    )}
                  >
                    Next of Kin Address
                    <span className="Label__Required">*</span>
                  </label>
                  <InputText
                    maxLength={70}
                    id="nextOfKinAddress"
                    placeholder="Please Enter Next of Kin Address"
                    name="familyDetails[0].nextOfKinAddress"
                    value={
                      formik?.values?.familyDetails[0]?.nextOfKinAddress || ""
                    }
                    onChange={formik.handleChange}
                    className={classNames(
                      {
                        "p-invalid": isFormFieldValid(
                          "familyDetails[0].nextOfKinAddress"
                        ),
                      },
                      "Input__Round"
                    )}
                  />
                  {formik.touched.familyDetails?.[0]?.nextOfKinAddress &&
                  formik.errors.familyDetails?.[0]?.nextOfKinAddress ? (
                    <small className="p-error">
                      {formik.errors.familyDetails[0].nextOfKinAddress}
                    </small>
                  ) : null}
                </div>
              </div>
              <div className="p-field col-6 md:col-4">
                <div className="p-field">
                  <label
                    htmlFor="nextOfKinContact"
                    className={classNames(
                      {
                        "p-error": isFormFieldValid(
                          "familyDetails[0].nextOfKinContact"
                        ),
                      },
                      "Label__Text"
                    )}
                  >
                    Next of Kin Contact No.
                    <span className="Label__Required">*</span>
                  </label>
                  <InputText
                    keyfilter="int"
                    maxLength={11}
                    id="nextOfKinContact"
                    placeholder="Please Enter Next of Kin Contact"
                    name="familyDetails[0].nextOfKinContact"
                    value={
                      formik?.values?.familyDetails[0]?.nextOfKinContact || ""
                    }
                    onChange={formik.handleChange}
                    className={classNames(
                      {
                        "p-invalid": isFormFieldValid(
                          "familyDetails[0].nextOfKinContact"
                        ),
                      },
                      "Input__Round"
                    )}
                  />
                  {formik.touched.familyDetails?.[0]?.nextOfKinContact &&
                  formik.errors.familyDetails?.[0]?.nextOfKinContact ? (
                    <small className="p-error">
                      {formik.errors.familyDetails[0].nextOfKinContact}
                    </small>
                  ) : null}
                </div>
              </div>

              <div className="p-field col-6 md:col-4">
                <div className="p-field">
                  <label
                    htmlFor="spouseName"
                    className={classNames(
                      {
                        "p-error": isFormFieldValid(
                          "familyDetails[0].spouseName"
                        ),
                      },
                      "Label__Text"
                    )}
                  >
                    Spouse Name<span className="Label__Required">*</span>
                  </label>
                  <InputText
                    keyfilter={/^[a-zA-Z\s]*$/}
                    maxLength={50}
                    disabled={
                      formik.values.personalDetails[0].maritalStatus === "false"
                    }
                    id="spouseName"
                    placeholder="Please Enter Spouse Name"
                    name="familyDetails[0].spouseName"
                    value={formik?.values?.familyDetails[0]?.spouseName || ""}
                    onChange={formik.handleChange}
                    className={classNames(
                      {
                        "p-invalid": isFormFieldValid(
                          "familyDetails[0].spouseName"
                        ),
                      },
                      "Input__Round"
                    )}
                  />
                  {formik.touched.familyDetails?.[0]?.spouseName &&
                  formik.errors.familyDetails?.[0]?.spouseName ? (
                    <small className="p-error">
                      {formik.errors.familyDetails[0].spouseName}
                    </small>
                  ) : null}
                </div>
              </div>
              <div className="p-field col-6 md:col-4">
                <div className="p-field">
                  <label
                    htmlFor="spouseDob"
                    className={classNames(
                      {
                        "p-error": isFormFieldValid(
                          "familyDetails[0].spouseDob"
                        ),
                      },
                      "Label__Text"
                    )}
                  >
                    Spouse Date of Birth
                    <span className="Label__Required">*</span>
                  </label>
                  <InputText
                    disabled={
                      formik.values.personalDetails[0].maritalStatus === "false"
                    }
                    type="date"
                    id="spouseDob"
                    name="familyDetails[0].spouseDob"
                    value={formik?.values?.familyDetails[0]?.spouseDob || ""}
                    onChange={formik.handleChange}
                    className={classNames(
                      {
                        "p-invalid": isFormFieldValid(
                          "familyDetails[0].spouseDob"
                        ),
                      },
                      "Input__Round"
                    )}
                  />
                  {formik.touched.familyDetails?.[0]?.spouseDob &&
                  formik.errors.familyDetails?.[0]?.spouseDob ? (
                    <small className="p-error">
                      {formik.errors.familyDetails[0].spouseDob}
                    </small>
                  ) : null}
                </div>
              </div>
              <div className="p-field col-6 md:col-4">
                <div className="p-field">
                  <label
                    htmlFor="spouseContact"
                    className={classNames(
                      {
                        "p-error": isFormFieldValid(
                          "familyDetails[0].spouseContact"
                        ),
                      },
                      "Label__Text"
                    )}
                  >
                    Spouse Contact
                  </label>
                  <InputText
                    disabled={
                      formik.values.personalDetails[0].maritalStatus === "false"
                    }
                    maxLength={11}
                    keyfilter="int"
                    id="spouseContact"
                    placeholder="Please Enter Spouse Contact"
                    name="familyDetails[0].spouseContact"
                    value={
                      formik?.values?.familyDetails[0]?.spouseContact || ""
                    }
                    onChange={formik.handleChange}
                    className={classNames(
                      {
                        "p-invalid": isFormFieldValid(
                          "familyDetails[0].spouseContact"
                        ),
                      },
                      "Input__Round"
                    )}
                  />
                  {formik.touched.familyDetails?.[0]?.spouseContact &&
                  formik.errors.familyDetails?.[0]?.spouseContact ? (
                    <small className="p-error">
                      {formik.errors.familyDetails[0].spouseContact}
                    </small>
                  ) : null}
                </div>
              </div>
              {formik.values.personalDetails[0].maritalStatus === "true" && (
                <>
                  <Divider align="left">
                    <div className="inline-flex align-items-center">
                      <b>Kids Information</b>
                    </div>
                  </Divider>
                  {formik.values.kidsInfo?.map((item, index) => {
                    return (
                      <>
                        <div key={`id-${index}`} className="grid col-12 mb-5">
                          <div className="p-field col-6 md:col-3">
                            <label
                              htmlFor={`kidName${index}`}
                              className={classNames(
                                {
                                  "p-error": isFormFieldValid(
                                    `kidsInfo[${index}].kidName`
                                  ),
                                },
                                "Label__Text"
                              )}
                            >
                              Kid Name
                              <span className="Label__Required">*</span>
                            </label>
                            <InputText
                              keyfilter={/^[a-zA-Z\s]*$/}
                              id={`kidName${index}`}
                              name={`kidsInfo[${index}].kidName`}
                              value={item.kidName || ""}
                              onChange={formik.handleChange}
                              className={classNames(
                                {
                                  "p-invalid": isFormFieldValid(
                                    `kidsInfo[${index}].kidName`
                                  ),
                                },
                                "Input__Round"
                              )}
                            />
                          </div>
                          <div className="p-field col-6 md:col-3">
                            <label
                              htmlFor={`gender${index}`}
                              className={classNames(
                                {
                                  "p-error": isFormFieldValid(
                                    `kidsInfo[${index}].gender`
                                  ),
                                },
                                "Label__Text"
                              )}
                            >
                              Gender
                              <span className="Label__Required">*</span>
                            </label>
                            <Dropdown
                              options={genderList}
                              optionLabel="label"
                              optionValue="value"
                              placeholder="Please Select Gender"
                              id={`gender${index}`}
                              name={`kidsInfo[${index}].gender`}
                              value={item.gender || ""}
                              onChange={formik.handleChange}
                              className={classNames(
                                {
                                  "p-invalid": isFormFieldValid(
                                    `kidsInfo[${index}].gender`
                                  ),
                                },
                                "Dropdown__Round"
                              )}
                            />
                          </div>
                          <div className="p-field col-6 md:col-3">
                            <label
                              htmlFor={`dob${index}`}
                              className={classNames(
                                {
                                  "p-error": isFormFieldValid(
                                    `kidsInfo[${index}].dob`
                                  ),
                                },
                                "Label__Text"
                              )}
                            >
                              Kid DOB
                              <span className="Label__Required">*</span>
                            </label>
                            <InputText
                              type="date"
                              id={`dob${index}`}
                              name={`kidsInfo[${index}].dob`}
                              value={item.dob || ""}
                              onChange={formik.handleChange}
                              className={classNames(
                                {
                                  "p-invalid": isFormFieldValid(
                                    `kidsInfo[${index}].dob`
                                  ),
                                },
                                "Input__Round"
                              )}
                            />
                          </div>
                          <div className="flex flex-column  align-items-center justify-content-center col-1">
                            <Button
                              icon="pi pi-plus"
                              className="p-button-rounded"
                              aria-label="Filter"
                              tooltip="Add new version"
                              style={{ border: "none" }}
                              onClick={(e) => {
                                e.preventDefault();
                                addPartner();
                              }}
                            />
                            <Button
                              icon="pi pi-minus"
                              className="p-button-rounded mt-2 p-button-danger"
                              aria-label="Filter"
                              tooltip="Delete this version"
                              disabled={formik.values.kidsInfo.length <= 1}
                              onClick={(e) => {
                                e.preventDefault();
                                removeCurrentCard(index);
                              }}
                            />
                          </div>
                        </div>
                      </>
                    );
                  })}
                </>
              )}
            </div>
            <div className="flex justify-content-center">
              <Button
                className="update__button"
                label={editable ? "Update" : "Save"}
                type="submit"
                style={{ border: "none" }}
              />
              <Button
                className="cancel__button ml-2"
                label="Cancel"
                onClick={(e) => {
                  e.preventDefault();
                  onHideDialog();
                }}
              />
            </div>
          </form>
        </div>
      </>
    </>
  );
}

export default ResourcesDialog;
