import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import React, { useEffect, useState } from 'react';
import { handleGetRequest } from '../../services/GetTemplate';
import { InputText } from 'primereact/inputtext';
import image from '../../assets/images/dummyImage.png';
import { FilterMatchMode } from 'primereact/api';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import {Dialog} from 'primereact/dialog'
import AdditionDialog from './AdditionDialog';
import DeductionDialog from './DeductionDialog';

function Finance() {
    const [employeeList, setEmployeeList] = useState();
    const [rankList, setRankList] = useState();
    const [departments, setDepartments] = useState();
    const [deductionDialog, setDeductionDialog] = useState(false)
    const [additionDialog, setAdditionDialog] = useState(false)
    const [dialogData, setDialogData] = useState({})
    const [employeeId, setEmployeeId]= useState(null)
    const [expandedRows, setExpandedRows] = useState(null);
    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(5);
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS }
    });
    const formik = useFormik({
        initialValues: {
            days: 30
        }
    });

    const daysList = [
        {
            label: '1',
            value: 1
        },
        {
            label: '2',
            value: 2
        },
        {
            label: '3',
            value: 3
        },
        {
            label: '4',
            value: 4
        },
        {
            label: '5',
            value: 5
        },
        {
            label: '6',
            value: 6
        },
        {
            label: '7',
            value: 7
        },
        {
            label: '8',
            value: 8
        },
        {
            label: '9',
            value: 9
        },
        {
            label: '10',
            value: 10
        },
        {
            label: '11',
            value: 11
        },
        {
            label: '12',
            value: 12
        },
        {
            label: '13',
            value: 13
        },
        {
            label: '14',
            value: 14
        },
        {
            label: '15',
            value: 15
        },
        {
            label: '16',
            value: 16
        },
        {
            label: '17',
            value: 17
        },
        {
            label: '18',
            value: 18
        },
        {
            label: '19',
            value: 19
        },
        {
            label: '20',
            value: 20
        },
        {
            label: '21',
            value: 21
        },
        {
            label: '22',
            value: 22
        },
        {
            label: '23',
            value: 23
        },
        {
            label: '24',
            value: 24
        },
        {
            label: '25',
            value: 25
        },
        {
            label: '26',
            value: 26
        },
        {
            label: '27',
            value: 27
        },
        {
            label: '28',
            value: 28
        },
        {
            label: '29',
            value: 29
        },
        {
            label: '30',
            value: 30
        }
    ];

    const getAllEmployees = async () => {
        const response = await handleGetRequest('/employee/getEmployeesFinance', true);
        setEmployeeList(response?.data);
    };
    const getRanks = async () => {
        const response = await handleGetRequest('/list/getallRanks', true);
        setRankList(response?.data);
    };
    const getDepartments = async () => {
        const response = await handleGetRequest('/list/alldepartments', true);
        setDepartments(response?.data)
    }

    useEffect(() => {
        getAllEmployees();
        getRanks();
        getDepartments()
    }, []);

    const imageTemplate = () => {
        return <img src={image} style={{ height: '50px', width: '50px' }} />;
    };
    const nameTemplate = (rowData) => {
        return (
            <p>
                {rowData?.firstName}&nbsp;{rowData?.lastName}
            </p>
        );
    };

    const handleAdditionDialog = (rowData) =>{
        setAdditionDialog(true)
        setDialogData(rowData)
    }
    const actionTemplate = (rowData) => {
        return (
            <div style={{ display: 'flex' }}>
                <Button className="p-button-rounded p-button-info mr-1" style={{ background: '#6495ED' }} icon="pi pi-pencil" tooltip="Add Benifits " 
                onClick={()=>handleAdditionDialog(rowData)}
                />
            </div>
        );
    };

    const designationTemplate = (rowData) => {
        const rankName = rankList?.filter((item) => {
            if (item?.id === rowData?.rank) {
                return item?.rankName;
            }
        });
        return <p>{rankName ? rankName[0]?.rankName : 'no department'}</p>;
    };
    const departmentTemplate = (rowData) => {
        const departmentName = departments?.filter((item) => {
            if (item?.id === rowData?.departmentId) {
                return item?.departmentName;
            }
        })
        return <p>{departmentName ? departmentName[0]?.departmentName : 'No department'}</p>
    }
    const onPageChange = (e) => {
        setFirst(e.first);
        setRows(e.rows);
    };
    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };
        _filters['global'].value = value;
        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const renderHeader = () => {
        return (
            <div className="Global__Header">
                <h5 className="m-0"></h5>
                <span className="block mt-2 md:mt-0 p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Search..." />
                </span>
            </div>
        );
    };

    const header = () => renderHeader();
    const allowExpansion = (rowData) => {
        return rowData?.salaryDetails;
    };


    const rowExpansionTemplate = (rowData) => {
        const id = rowData?.id
        const dateOfDischarge = rowData?.dateOfDischarge?.slice(0, 10);
        const eobiAmount = rowData?.eobi
        const incomeTaxAmount = rowData?.incomeTax
        const grossSalary = rowData?.salary

    //    const additions = rowData?.additions && !Array.isArray(rowData.additions) ? [rowData.additions] : rowData?.additions || [];
        console.log(grossSalary)
        const grossSalaryTemplate = (rowData) => {
            return <p>{rowData?.Basic + rowData?.HRA + rowData?.Utility + rowData?.Cola}</p>;
        };
       const daysTemplate = () => {
           if (dateOfDischarge) {
               const dischargeDate = new Date(dateOfDischarge);
               const firstDayOfMonth = new Date(dischargeDate.getFullYear(), dischargeDate.getMonth(), 1);

               const diffInMilliseconds = dischargeDate - firstDayOfMonth;
               const daysDiff = Math.floor(diffInMilliseconds / (1000 * 60 * 60 * 24)) + 1; 
               return <p>{daysDiff}</p>;
           } else {
               return <p>30</p>;
           }
       };

        const ftmTemplate = (rowData) => {
            const grossSalary = rowData?.Basic + rowData?.HRA + rowData?.Utility + rowData?.Cola;
            let daysWorked;
            if (dateOfDischarge) {
                const dischargeDate = new Date(dateOfDischarge);
                const firstDayOfMonth = new Date(dischargeDate.getFullYear(), dischargeDate.getMonth(), 1);
                const diffInMilliseconds = dischargeDate - firstDayOfMonth;
                daysWorked = Math.floor(diffInMilliseconds / (1000 * 60 * 60 * 24)) + 1;
            } else {
                daysWorked = 30; 
            }

            const perDaySalary = grossSalary/30; 

            const finalSalary = perDaySalary * daysWorked; 

            return <p>{finalSalary.toString()}</p>; 
        };
        const perDayCost = (rowData) => {
            const grossSalary = rowData?.Basic + rowData?.HRA + rowData?.Utility + rowData?.Cola;
            const finalValue = Math.round(grossSalary/30)
            return <p>{finalValue}</p>;
        };
        const eobiTemplate = () => {
            return <p>{eobiAmount}</p>
        }
        const incomeTaxTemplate = () => {
            return <p>{incomeTaxAmount}</p>;
        };
        const handleDeductionsDialog = (rowData, id)=>{
            setDeductionDialog(true)
            setDialogData(rowData)
            setEmployeeId(id)
        }
        const deductionActionTemplate = (id,rowData) => {
            return (
                 <div style={{ display: 'flex' }}>
                     <Button className="p-button-rounded p-button-info mr-1" style={{ background: '#6495ED' }} icon="pi pi-pencil" tooltip="Add Benifits " onClick={() => handleDeductionsDialog(rowData, id)} />
                 </div>
             );
        }
        return (
            <>
                <div className="p-3">
                    <div className="flex justify-content-center">
                        <h5>Salary Details</h5>
                    </div>
                    <DataTable value={[rowData?.salaryDetails]}>
                        <Column field="Basic" header="Basic Salary" />
                        <Column field="HRA" header="H.R.A" />
                        <Column field="Utility" header="Utilities Allownce" />
                        <Column field="Cola" header="Cola Allownce" />
                        <Column body={grossSalaryTemplate} header="Gross Salary" />
                        <Column body={daysTemplate} header="No of Days" />
                        <Column body={ftmTemplate} header="Salary FTM" />
                        <Column body={perDayCost} header="Per Day Cost" />
                    </DataTable>
                </div>
                <div>
                    <div className="flex justify-content-center">
                        <h5>Advances/Benefits Details</h5>
                    </div>
                    <DataTable value={[rowData?.additions]}>
                        <Column field="incrementFTM" header="Increment for Month" />
                        <Column field="incrementActual" header="Increment at Actual" />
                        <Column field="bonus" header="Bonus" />
                        <Column field="overTime" header="Overtime" />
                        <Column field="arrears" header="Arrears" />
                    </DataTable>
                </div>
                <div className="p-3">
                    <div className="flex justify-content-center">
                        <h5>Deduction Details</h5>
                    </div>
                    <DataTable value={[rowData?.deductions]}>
                        <Column field="lwp" header="LWP" />
                        <Column field="advances" header="Advances/Loan" />
                        <Column field="incomeTax" header="Income Tax" />
                        <Column field="eobi" header="EOBI" />
                        <Column body={() => deductionActionTemplate(id, rowData)} header="Actions" />
                    </DataTable>
                </div>
                <div className="p-3">
                    <h5>
                        Net salary after addition & deduction:
                        <span>
                            {grossSalary +
                                rowData?.additions?.incrementFTM +
                                rowData?.additions?.bonus +
                                rowData?.additions?.overTime +
                                rowData?.additions?.arrears -
                                rowData?.deductions?.lwp -
                                rowData?.deductions?.advances -
                                rowData?.deductions?.eobi -
                                rowData?.deductions?.incomeTax}
                        </span>
                    </h5>
                </div>
            </>
        );
    };

    const onHideAdditionDialog = () => {
        setAdditionDialog(false);
        setDialogData();
    };
    const onHideDeductionDialog = () => {
        setDeductionDialog(false)
        setDialogData()
    }

    return (
        <>
            <Dialog header="Add Benifits" visible={additionDialog} style={{ width: '30vw' }} draggable={false} onHide={onHideAdditionDialog}>
                <AdditionDialog onHideAdditionDialog={onHideAdditionDialog} dialogData={dialogData} />
            </Dialog>
            <Dialog header="Add Benifits" visible={deductionDialog} style={{ width: '30vw' }} draggable={false} onHide={onHideDeductionDialog}>
                <DeductionDialog onHideDeductionDialog={onHideDeductionDialog} dialogData={dialogData} employeeId={employeeId} />
            </Dialog>
            <div className="card">
                <div className="flex justify-content-between align-items-center pb-5">
                    <h4 className="m-0">Resources Finance Details</h4>
                </div>
                <DataTable
                    value={employeeList}
                    paginator
                    rows={rows}
                    first={first}
                    onPage={onPageChange}
                    globalFilterFields={['firstName', 'lastName', 'cnic', 'officialEmail', 'reportingManager', 'location']}
                    filters={filters}
                    filterDisplay="menu"
                    header={header}
                    expandedRows={expandedRows}
                    onRowToggle={(e) => setExpandedRows(e.data)}
                    rowExpansionTemplate={rowExpansionTemplate}
                    dataKey="id"
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                    rowsPerPageOptions={[5, 10, 20]}
                >
                    <Column expander={allowExpansion} style={{ width: '3rem' }} />
                    <Column body={imageTemplate} header="Image" />
                    <Column field="cnic" header="CNIC" />
                    <Column body={nameTemplate} header="Name" />
                    <Column field="officialEmail" header="Email" />
                    <Column body={departmentTemplate} header="Department" />
                    <Column body={designationTemplate} header="Designation" />
                    <Column field="reportingManager" header="Reporting Manager" />
                    <Column field="location" header="Location" />
                    <Column field="experience" header="Experience" />
                    <Column field="emergencyNumber" header="Emergency Contact" />
                    {/* <Column body={statusTemplate} header="Status" /> */}
                    <Column body={actionTemplate} header="Add Benifits" />
                </DataTable>
            </div>
        </>
    );
}

export default Finance;
