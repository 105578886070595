import { FilterMatchMode } from "primereact/api";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import React, { useEffect, useState } from "react";
import { handleGetRequest } from "../../services/GetTemplate";
import { Dialog } from "primereact/dialog";
import AddNewDepartmentDialog from "./AddNewDepartmentDialog";
import AddNewRankDialog from "./AddNewRankDialog";
import AddNewGradeDialog from "./AddNewGradeDialog";
import { useDispatch } from "react-redux";
import { handlePostRequest } from "../../services/PostTemplate";
import AddNewLocationDialog from "./LocationDialog";

function GeneralData() {
  const [list, setList] = useState();
  const [expandedRows, setExpandedRows] = useState(null);
  const [globalFilter, setGlobalFilter] = useState("");
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(5);
  const [tableData, setTableData] = useState();
  const [departments, setDepartments] = useState(true);
  const [grades, setGrades] = useState(false);
  const [ranks, setRanks] = useState(false);
    const [locations, setLocations] = useState(false);
    const [locationDialog , setLocationDialog]= useState(false)
  const [gradesList, setGradesList] = useState();
  const [ranksList, setRanksList] = useState();
    const [departmentList, setDepartmentList] = useState();
    const [departmentDialog, setDepartmentDialog] = useState(false)
    const [rankDialog, setRankDialog] = useState(false)
    const [gradeDialog , setGradeDialog] = useState(false)
  const [locationList, setLocationList] = useState();
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

    
    const dispatch = useDispatch()
    
  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };
    _filters["global"].value = value;
    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const renderHeader = () => {
    return (
      <div className="Global__Header">
        <h5 className="m-0"></h5>
        <span className="block mt-2 md:mt-0 p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Search..."
          />
        </span>
      </div>
    );
  };

  const header = () => renderHeader();
  const onPageChange = (e) => {
    setFirst(e.first);
    setRows(e.rows);
  };

  const handleDepartments = () => {
    setDepartments(true);
    setRanks(false);
    setGrades(false);
    setLocations(false);
  };
  const handleRanks = () => {
    setDepartments(false);
    setRanks(true);
    setGrades(false);
    setLocations(false);
  };
  const handleGrades = () => {
    setDepartments(false);
    setRanks(false);
    setGrades(true);
    setLocations(false);
  };
  const handleLocations = () => {
    setDepartments(false);
    setRanks(false);
    setGrades(false);
    setLocations(true);
  };

  const getDepartments = async () => {
    const response = await handleGetRequest("/list/alldepartments", true);
    setDepartmentList(response?.data);
  };
  const getGrades = async () => {
    const response = await handleGetRequest("/list/getAllGrades", true);
    setGradesList(response?.data);
  };
  const getRanks = async () => {
    const response = await handleGetRequest("/list/getAllRanks", true);
    setRanksList(response?.data);
  };
   const getLocations= async () => {
       const response = await handleGetRequest('/list/getlocations', true);
       setLocationList(response?.data);
  };
  useEffect(() => {
    getDepartments();
    getGrades();
      getRanks();
      getLocations()
  }, []);

    const actionTemplate = (rowData) => {
        
        const removeDepartment = async (id) => {
            const data = {
                departmentId:id
            }
            const response = await dispatch(handlePostRequest(data, `/generaldata/removedepartment`, true))
            if (response?.responseCode === 201) {
                setTimeout(() => {
                    window.location.reload();
                }, 1000);
            }
        }
    return (
      <Button
        className="p-button-rounded p-button-info mr-1"
        icon="pi pi-trash"
            tooltip="Delete Department"
            onClick={()=>removeDepartment(rowData?.id)}
      />
    );
    };
    const actionTemplateLocation = (rowData) => {
        const removeLocation = async (id) => {
            const data = {
                locationId: id
            };
            const response = await dispatch(handlePostRequest(data, `/generaldata/removelocation`, true));
            if (response?.responseCode === 201) {
                setTimeout(() => {
                    window.location.reload();
                }, 1000);
            }
        };
        return <Button className="p-button-rounded p-button-info mr-1" icon="pi pi-trash" tooltip="Delete Location" onClick={() => removeLocation(rowData?.id)} />;
    };
     const actionTemplateRank = (rowData) => {
         const removeRank = async (id) => {
             const data = {
                 rankId: id
             };
             const response = await dispatch(handlePostRequest(data, `/generaldata/removerank`, true));
             if (response?.responseCode === 201) {
                 setTimeout(() => {
                     window.location.reload();
                 }, 1000);
             }
         };
         return <Button className="p-button-rounded p-button-info mr-1" icon="pi pi-trash" tooltip="Delete Rank" onClick={() => removeRank(rowData?.id)} />;
    };
    const actionTemplateGrade = (rowData) => {
        const removeGrade = async (id) => {
            const data = {
                gradeId: id
            };
            const response = await dispatch(handlePostRequest(data, `/generaldata/removegrade`, true));
            if (response?.responseCode === 201) {
                setTimeout(() => {
                    window.location.reload()
                }, 1000);
            }
        };
        return <Button className="p-button-rounded p-button-info mr-1" icon="pi pi-trash" tooltip="Delete Grade" onClick={() => removeGrade(rowData?.id)} />;
    };
    
    const handleNewDepartment = () => {
        setDepartmentDialog(true)
    }
    const handleNewRank = () => {
        setRankDialog(true);
    };
    const handleNewGrade = () => {
        setGradeDialog(true);
    };
    const hideDepartmentDialog = () => {
        setDepartmentDialog(false)
    }
    const hideRankDialog = () => {
        setRankDialog(false)
    }
    const hideGradeDialog = () => {
        setGradeDialog(false)
    }
    const handleNewLocation = () => {
        setLocationDialog(true)
    }
    const hideLocationDialog = () => {
        setLocationDialog(false)
    }

  return (
      <>
          <Dialog visible={departmentDialog} onHide={hideDepartmentDialog} style={{ width: '30vw' }} header="Add new department">
              <AddNewDepartmentDialog hideDepartmentDialog={hideDepartmentDialog} />
          </Dialog>
          <Dialog visible={rankDialog} onHide={hideRankDialog} style={{ width: '30vw' }} header="Add new Rank">
              <AddNewRankDialog hideRankDialog={hideRankDialog} />
          </Dialog>
          <Dialog visible={gradeDialog} onHide={hideGradeDialog} style={{ width: '30vw' }} header="Add new Grade">
              <AddNewGradeDialog hideGradeDialog={hideGradeDialog} />
          </Dialog>
          <Dialog visible={locationDialog} onHide={hideLocationDialog} style={{ width: '30vw' }} header="Add new Location">
              <AddNewLocationDialog hideLocationDialog={hideLocationDialog} />
          </Dialog>
          <div className="Button__Card">
              <div>
                  <Button label="Departments" className={departments ? 'cancel__button mr-1' : 'update__button  mr-1'} iconPos="left" onClick={handleDepartments} />
                  <Button label="Grades" className={grades ? 'cancel__button mr-1' : 'update__button mr-1'} onClick={handleGrades} />
                  <Button label="Ranks" className={ranks ? 'cancel__button mr-1' : 'update__button mr-1'} onClick={handleRanks} />
                  <Button label="Locations" className={locations ? 'cancel__button mr-1' : 'update__button mr-1'} onClick={handleLocations} />
              </div>
          </div>
          <div className="card">
              <div className="flex justify-content-between align-items-center pb-5">
                  <h4 className="m-0">General Details</h4>
                  <Button label="Add New" className="Btn__Add__" onClick={departments ? handleNewDepartment : ranks ? handleNewRank : grades ? handleNewGrade : locations? handleNewLocation : null} />
              </div>
              {departments && (
                  <DataTable
                      value={departmentList}
                      paginator
                      rows={rows}
                      first={first}
                      onPage={onPageChange}
                      globalFilterFields={['departmentName']}
                      filters={filters}
                      filterDisplay="menu"
                      header={header}
                      dataKey="id"
                      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                      currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                      rowsPerPageOptions={[5, 10, 20]}
                  >
                      <Column field="id" header="Department Id" />
                      <Column field="departmentName" header="Department Name" />
                      <Column body={actionTemplate} header="Actions" />
                  </DataTable>
              )}
              {grades && (
                  <DataTable
                      value={gradesList}
                      paginator
                      rows={rows}
                      first={first}
                      onPage={onPageChange}
                      globalFilterFields={['gradeName']}
                      filters={filters}
                      filterDisplay="menu"
                      header={header}
                      dataKey="id"
                      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                      currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                      rowsPerPageOptions={[5, 10, 20]}
                  >
                      <Column field="id" header="Grade Id" />
                      <Column field="gradeName" header="Grade Name" />
                      <Column body={actionTemplateGrade} header="Actions" />
                  </DataTable>
              )}
              {ranks && (
                  <DataTable
                      value={ranksList}
                      paginator
                      rows={rows}
                      first={first}
                      onPage={onPageChange}
                      globalFilterFields={['rankName']}
                      filters={filters}
                      filterDisplay="menu"
                      header={header}
                      dataKey="id"
                      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                      currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                      rowsPerPageOptions={[5, 10, 20]}
                  >
                      <Column field="id" header="Rank Id" />
                      <Column field="rankName" header="Rank Name" />
                      <Column body={actionTemplateRank} header="Actions" />
                  </DataTable>
              )}
              {locations && (
                  <DataTable
                      value={locationList}
                      paginator
                      rows={rows}
                      first={first}
                      onPage={onPageChange}
                      globalFilterFields={['departmentName']}
                      filters={filters}
                      filterDisplay="menu"
                      header={header}
                      dataKey="id"
                      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                      currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                      rowsPerPageOptions={[5, 10, 20]}
                  >
                      <Column field="id" header="Department Id" />
                      <Column field="locationName" header="Location Name" />
                      <Column body={actionTemplateLocation} header="Actions" />
                  </DataTable>
              )}
          </div>
      </>
  );
}

export default GeneralData;
