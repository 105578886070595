import React, { useEffect, useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import { classNames } from 'primereact/utils';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import coverImage from '../assets/images/image__.png'
import { loginAction } from '../redux/actions/authAction';

const Login = () => {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('');
  
    const navigate = useNavigate();
    const dispatch = useDispatch()

    const formik = useFormik({
        initialValues: {
            userName: '',
            password: ''
        },
        validate: (data) => {
            let error = {}
            if (!data?.userName) {
                error.userName='This field is required'
            } else if (!data?.password) {
                error.password = 'This field is required'
            }
            return error
        },
        onSubmit: async(data) => {
            const res = await dispatch(loginAction(data));
            
        }
    });

     const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

     const getFormErrorMessage = (name) => {
         return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
     };

    return (

        <div className="login-body">
            <div className="col-6 image__section">
                <img src={coverImage} />
            </div>
            <div className="col-6 data__section">
              
                    <div className="login-panel p-fluid centered__login">
                        <form onSubmit={formik.handleSubmit} className="">
                            <div className="flex flex-column">
                                <div className="flex align-items-center justify-content-center my-5 logo-container">
                                    <p className="Signin__Heading">Login</p>
                                </div>
                                <div className="form-container">
                                    <div className="flex flex-column w-12">
                                        <span className="p-input-icon-left">
                                            <i className="pi pi-envelope"></i>
                                            <InputText
                                                id="userName"
                                                className={classNames({ 'p-invalid': isFormFieldValid('userName') }, 'Login__Input mb-0')}
                                                name="userName"
                                                value={formik.values.userName}
                                                placeholder="Enter User Name"
                                                onChange={formik.handleChange}
                                                autoFocus
                                            />
                                            {getFormErrorMessage('userName')}
                                        </span>
                                    </div>
                                    <div className="flex flex-column w-12 mt-3">
                                        <span className="p-input-icon-left">
                                            <i className="pi pi-key"></i>
                                            <InputText
                                                className={classNames({ 'p-invalid': isFormFieldValid('password') }, 'Login__Input mb-0')}
                                                name="password"
                                                placeholder="Enter Password"
                                                value={formik.values.password}
                                                onChange={formik.handleChange}
                                                type="password"
                                            />
                                            {getFormErrorMessage('password')}
                                        </span>
                                    </div>
                                </div>
                                <div className={`button-container flex justify-content-center align-items-center mt-4 mb-5`}>
                                    <Button label="Login" icon={loadingIcon || ''} iconPos="right" className={`Login__Button`} />
                                </div>
                            </div>
                        </form>
                    </div>
            </div>
        </div>
    );
};

export default Login;
