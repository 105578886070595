import React, { useState } from 'react'
import { Dropdown } from 'primereact/dropdown';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { Chart } from 'primereact/chart';
import { MultiSelect } from 'primereact/multiselect';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

function ResourcePNL() {
     const [pieChartData] = useState({
         labels: ['Allocated Hours', 'Total Hours', 'Hours to allocate'],
         datasets: [
             {
                 data: [300, 50, 100],
                 backgroundColor: ['#42A5F5', '#66BB6A', '#FFA726'],
                 hoverBackgroundColor: ['#64B5F6', '#81C784', '#FFB74D']
             }
         ]
     });
       const [basicData] = useState({
           labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
           datasets: [
               {
                   label: 'Example Project',
                   data: [65, 59, 80, 81, 56, 55, 40],
                   fill: false,
                   borderColor: '#42A5F5',
                   tension: 0.4
               },
               {
                   label: 'Example Project',
                   data: [28, 48, 40, 19, 86, 27, 90],
                   fill: false,
                   borderColor: '#FFA726',
                   tension: 0.4
               }
           ]
       });

     const [lightOptions] = useState({
         plugins: {
             legend: {
                 labels: {
                     color: '#495057'
                 }
             }
         }
     });
    
     const [barChartData] = useState({
         labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
         datasets: [
             {
                 label: 'Hours Allocated',
                 backgroundColor: '#49464d',
                 data: [65, 59, 80, 81, 56, 55, 40, 33, 25, 69, 87, 20]
             },
             {
                 label: 'Hours Worked',
                 backgroundColor: '#f8a91b',
                 data: [28, 48, 40, 19, 86, 27, 90, 33, 25, 69, 87, 50]
             }
         ]
     });

    const formik = useFormik({
        initialValues: {
            name: '',
            month: '',
            year: '',
            department:''
        }
    });
     const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
     const getFormErrorMessage = (name) => {
         return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };
    
    const employeeList = [
        {
            label: 'Example Employee',
            value: '1'
        },
        {
            label: 'Example Employee 2',
            value: '2'
        },
        {
            label: 'Example Employee 3',
            value:'3'
        },
    ];
 const monthsLov = [
     {
         label: 'January',
         value: '1'
     },
     {
         label: 'Febraury',
         value: '2'
     },
     {
         label: 'March',
         value: '3'
     },
     {
         label: 'April',
         value: '4'
     },
     {
         label: 'May',
         value: '5'
     },
     {
         label: 'June',
         value: '6'
     },
     {
         label: 'July',
         value: '7'
     },
     {
         label: 'August',
         value: '8'
     },
     {
         label: 'September',
         value: '9'
     },
     {
         label: 'October',
         value: '10'
     },
     {
         label: 'November',
         value: '11'
     },
     {
         label: 'December',
         value: '12'
     }
 ];
 const yearsLov = [
     {
         label: '2018',
         value: '1'
     },
     {
         label: '2019',
         value: '2'
     },
     {
         label: '2020',
         value: '3'
     },
     {
         label: '2021',
         value: '4'
     },
     {
         label: '2022',
         value: '5'
     },
     {
         label: '2023',
         value: '6'
     },
     {
         label: '2024',
         value: '7'
     }
    ];
    
    const departmentLov = [
        {
            label: 'Java',
            value: '1'
        },
        {
            label: 'Database',
            value: '2'
        },
        {
            label: 'Android',
            value: '3'
        },
        {
            label: 'IOS',
            value: '4'
        },
        {
            label: 'DotNet',
            value: '5'
        },
        {
            label: 'React',
            value: '6'
        },
        {
            label: 'Flutter',
            value: '7'
        },
        {
            label: 'DevOps',
            value: '8'
        }
    ];

    const resourceData = [
        {
            project: 'Example Project',
            startDate: '01-01-2024',
            endDate: '20-12-2024'
        },
        {
            project: 'Example Project 1',
            startDate: '01-01-2024',
            endDate: '20-12-2024'
        }
    ];
  return (
      <>
          <div className="card Card__Round">
              <form onSubmit={formik.handleSubmit} className="p-fluid">
                  <div className="p-fluid p-formgrid grid mb-5">
                      <div className="p-field col-12 md:col-3">
                          <div className="p-field">
                              <label htmlFor="department" className={classNames({ 'p-error': isFormFieldValid('department') }, 'Label__Text')}>
                                  Department<span className="Label__Required">*</span>
                              </label>
                              <Dropdown
                                  filter
                                  id="department"
                                  placeholder="Please select department"
                                  options={departmentLov}
                                  optionLabel="label"
                                  optionValue="value"
                                  name="department"
                                  value={formik.values.department || ''}
                                  onChange={formik.handleChange}
                                  className={classNames({ 'p-invalid': isFormFieldValid('department') }, 'Dropdown__Round')}
                              />
                              {getFormErrorMessage('department')}
                          </div>
                      </div>
                      <div className="p-field col-12 md:col-3">
                          <div className="p-field">
                              <label htmlFor="name" className={classNames({ 'p-error': isFormFieldValid('name') }, 'Label__Text')}>
                                  Employee Name<span className="Label__Required">*</span>
                              </label>
                              <Dropdown
                                  filter
                                  id="name"
                                  placeholder="Please select employee name"
                                  options={employeeList}
                                  optionLabel="label"
                                  optionValue="value"
                                  name="name"
                                  value={formik.values.name || ''}
                                  onChange={formik.handleChange}
                                  className={classNames({ 'p-invalid': isFormFieldValid('name') }, 'Dropdown__Round')}
                              />
                              {getFormErrorMessage('name')}
                          </div>
                      </div>
                      <div className="p-field col-12 md:col-3">
                          <div className="p-field">
                              <label htmlFor="month" className={classNames({ 'p-error': isFormFieldValid('month') }, 'Label__Text')}>
                                  Month<span className="Label__Required">*</span>
                              </label>
                              <MultiSelect
                                  filter
                                  id="month"
                                  placeholder="Please select month"
                                  options={monthsLov}
                                  optionLabel="label"
                                  optionValue="value"
                                  name="month"
                                  value={formik.values.month || ''}
                                  onChange={formik.handleChange}
                                  className={classNames({ 'p-invalid': isFormFieldValid('month') }, 'Dropdown__Round')}
                              />
                              {getFormErrorMessage('month')}
                          </div>
                      </div>
                      <div className="p-field col-12 md:col-3">
                          <div className="p-field">
                              <label htmlFor="year" className={classNames({ 'p-error': isFormFieldValid('year') }, 'Label__Text')}>
                                  Year<span className="Label__Required">*</span>
                              </label>
                              <Dropdown
                                  filter
                                  id="year"
                                  placeholder="Please select year"
                                  options={yearsLov}
                                  optionLabel="label"
                                  optionValue="value"
                                  name="year"
                                  value={formik.values.year || ''}
                                  onChange={formik.handleChange}
                                  className={classNames({ 'p-invalid': isFormFieldValid('year') }, 'Dropdown__Round')}
                              />
                              {getFormErrorMessage('year')}
                          </div>
                      </div>
                  </div>
              </form>
          </div>
          <div className="card">
              <div className="gird flex">
                  <div className="col-4">
                      <Chart type="pie" data={pieChartData} options={lightOptions} style={{ position: 'relative', width: '100%' }} />
                  </div>
                  <div className="col-7 flex justify-content-center">
                      <Chart type="line" data={basicData} style={{ position: 'relative', width: '100%' }} />
                  </div>
              </div>
          </div>
          <div className="card">
              <Chart type="bar" data={barChartData} />
          </div>
          <div className="card">
              <h4>Employee Projects Details</h4>
              <DataTable value={resourceData} className="datatable-responsive" responsiveLayout="scroll">
                  <Column field='project' header="Project Name" />
                  <Column field="startDate" header="Start Date" />
                  <Column field="endDate" header="End Date" />
              </DataTable>
          </div>
      </>
  );
}

export default ResourcePNL