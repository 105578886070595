import { baseURL } from '../Config';
import axios from 'axios';
import { toast } from 'react-toastify';
import { trimData } from '../utilities/TrimPayloadData';
import { loadingAction } from '../redux/actions/loadingAction';
import { showMessageAction } from '../redux/actions/showMessageAction';
import store from '../redux/store';

export const handlePostRequest =
    (data, url, isShowLoad = false, isShowToast = true, nextPageToLoad = '', responseCode = '') =>
        async (dispatch) => {
            data = await trimData(data);
            try {
                if (isShowLoad) dispatch(loadingAction(true));
                const response = await axios({
                    method: 'post',
                    url: `${baseURL + url}`,
                    data: data,
                    headers: {
                        Authorization: sessionStorage.getItem('token')
                        // Authorization: `Bearer oBMLUgzWWOl5xl4K0KZRZpxpVUTj9Wd8jlxjJEY+q3Ga1rBKVisip76IKq52cnKp/qRyecP4NIzs+zETO7izpvqN7pRIex/CMDLco63aVdMYNpe+E31KQTBeZ00uiCdCcWgE0CPRRupo6+BJhWiQKQ==`
                    }
                });
                if (response?.data?.message === 'Invalid Token' || response?.data?.message === 'Session Expired') {
                    localStorage.clear();
                    setTimeout(() => {
                        window.location.href = '/';
                    }, 1000);
                }

                if (isShowToast) {
                    if (response?.data?.message === 'Validations Failed') {
                        // Display a warning toast with validation errors
                        toast.warn(
                            response?.data?.errors
                                ?.filter((value) => value?.errorDescr === 'Select Any One Option')
                                ?.map((filteredError) => filteredError?.errorDescr)
                                .join(', '),
                            { autoClose: 2000 }
                        );
                    } else if (response?.data?.message === 'This record is already pending for approval' || response?.data?.message === 'Record Not Found' || response?.data?.message === 'Record Already Exist' || response?.data?.message === 'Mobile Number Already Exist' || response?.data?.message === 'Cnic Already Exist' || response?.data?.message === "Ntn Already Exist" || response?.data?.message === "Email Already Exist") {
                        toast.warn(response?.data?.message || response?.data?.messages || response?.data?.error || 'Something went wrong !!', { autoClose: 2000 });
                    } else {
                        if (responseCode == '' || responseCode === response?.data?.responseCode) {
                        // Display a success toast
                        toast.success(response?.data?.message || response?.data?.messages || response?.data?.error || 'Something went wrong !!', { autoClose: 2000 });
                    }else{
                        toast.warn(response?.data?.message || response?.data?.messages || response?.data?.error || 'Something went wrong !!', { autoClose: 2000 });
                    }
                    }
                } else if (response?.data?.message === 'Validations Failed') {
                    store.dispatch(
                        showMessageAction({
                            isShowModal: true,
                            message: (response?.data?.message === 'Validations Failed' ? response?.data?.errors?.map((value) => value?.errorDescr) : response?.data?.message) || 'Something went wrong !!',
                            isError: true
                        })
                    );
                } else {
                    if (responseCode == '' || responseCode === response?.data?.responseCode) {
                        store.dispatch(
                            showMessageAction({
                                isShowModal: true,
                                message: response?.data?.message || 'Success',
                                isError: false,
                                url: nextPageToLoad
                            })
                        );
                    } else {
                        store.dispatch(
                            showMessageAction({
                                isShowModal: true,
                                message: response?.data?.message || 'Something Went Wrong',
                                isError: true
                            })
                        );
                    }
                }
                if (isShowLoad) dispatch(loadingAction(false));

                return response?.data;
            } catch (error) {
                if (error?.response?.data?.message === 'Invalid Token' || error?.response?.data?.message === 'Session Expired') {
                    localStorage.clear();
                    setTimeout(() => {
                        window.location.href = '/';
                    }, 1000);
                }
                if (isShowLoad) dispatch(loadingAction(false));
                if (isShowToast === false) {
                    store.dispatch(
                        showMessageAction({
                            isShowModal: true,
                            message: (error?.response?.data?.message === 'Validations Failed' ? error?.response?.data?.errors?.map((value) => value?.errorDescr) : error?.response?.data?.message) || 'Something went wrong !!',
                            isError: true
                        })
                    );
                }
                else if (error?.response?.status === 500 && isShowToast) {
                    toast.warn(error?.response?.data?.message || error?.response?.data?.messages || error?.response?.data?.error || 'Something went wrong !!', { autoClose: 2000 });
                } else if (error?.response?.status === 400 && isShowToast)
                    toast.warn(
                        error?.response?.data?.message === 'Validations Failed'
                            ? error?.response?.data?.errors
                                ?.filter((value) => value?.errorDescr === 'Select Any One Option')
                                ?.map((filteredError) => filteredError?.errorDescr)
                                .join(', ') || error?.response?.data?.errors?.map((filteredError) => filteredError?.errorDescr).join(', ')
                            : error?.response?.data?.message || error?.response?.data?.messages || error?.response?.data?.error || 'Something went wrong !!',
                        { autoClose: 2000 }
                    );
                else toast.warn(error?.response?.data?.message || 'Something went wrong !!');
                return error?.response;
            }
        };
