import React, { useState } from 'react';
import { Chart } from 'primereact/chart';
import classNames from 'classnames';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import { useFormik } from 'formik';

const Dashboard = () => {
    const [basicData] = useState({
        labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
        datasets: [
            {
                label: 'Invoicing Sent',
                backgroundColor: '#49464d',
                data: [65, 59, 80, 81, 56, 55, 40, 33, 25, 69, 87 ,20]
            },
            {
                label: 'Amount Recieved',
                backgroundColor: '#f8a91b',
                data: [28, 48, 40, 19, 86, 27, 90, 33, 25, 69, 87 , 50]
            }
        ]
    });

    const basicOptions = {
        maintainAspectRatio: false,
        aspectRatio: 0.8,
        plugins: {
            legend: {
                labels: {
                    color: '#495057'
                }
            },
            tooltips: {
                mode: 'index',
                intersect: true
            }
        },
        scales: {
            x: {
                ticks: {
                    color: '#495057'
                },
                grid: {
                    color: '#ebedef'
                }
            },
            y: {
                type: 'linear',
                display: true,
                position: 'left',
                ticks: {
                    min: 0,
                    max: 100,
                    color: '#495057'
                },
                grid: {
                    color: '#ebedef'
                }
            },
            y1: {
                type: 'linear',
                display: true,
                position: 'right',
                grid: {
                    drawOnChartArea: false,
                    color: '#ebedef'
                },
                ticks: {
                    min: 0,
                    max: 100,
                    color: '#495057'
                }
            }
        }
    };

     const [donoughtChartData] = useState({
         labels: ['A', 'B', 'C'],
         datasets: [
             {
                 data: [300, 50, 100],
                 backgroundColor: ['#42A5F5', '#66BB6A', '#FFA726'],
                 hoverBackgroundColor: ['#64B5F6', '#81C784', '#FFB74D']
             }
         ]
     });

     const [lightOptions] = useState({
         plugins: {
             legend: {
                 labels: {
                     color: '#495057'
                 }
             }
         }
     });
       const [polarChartData] = useState({
           datasets: [
               {
                   data: [11, 16, 7, 3, 14],
                   backgroundColor: ['#42A5F5', '#66BB6A', '#FFA726', '#26C6DA', '#7E57C2'],
                   label: 'My dataset'
               }
           ],
           labels: ['Red', 'Green', 'Yellow', 'Grey', 'Blue']
       });
    
     const [pieChartData] = useState({
         labels: ['A', 'B', 'C'],
         datasets: [
             {
                 data: [300, 50, 100],
                 backgroundColor: ['#42A5F5', '#66BB6A', '#FFA726'],
                 hoverBackgroundColor: ['#64B5F6', '#81C784', '#FFB74D']
             }
         ]
     });
    
     const [comboChartData] = useState({
         labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
         datasets: [
             {
                 type: 'line',
                 label: 'Dataset 1',
                 borderColor: '#49464d',
                 borderWidth: 2,
                 fill: false,
                 tension: 0.4,
                 data: [50, 25, 12, 48, 56, 76, 42, 30, 40 ,50 ,35,45]
             },
             {
                 type: 'bar',
                 label: 'Dataset 2',
                 backgroundColor: '#f8a91b',
                 data: [21, 84, 24, 75, 37, 65, 34, 25, 50, 54, 23, 50],
                 borderColor: 'white',
                 borderWidth: 2
             },
             {
                 type: 'bar',
                 label: 'Dataset 3',
                 backgroundColor: '#49464d',
                 data: [41, 52, 24, 74, 23, 21, 32, 20, 40, 50, 13, 58]
             }
         ]
     });
    
    const formik = useFormik({
        initialValues: {
            project: '',
            month: '',
            year: '',
        }
    });
    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

     const monthsLov = [
         {
             label: 'January',
             value: '1'
         },
         {
             label: 'Febraury',
             value: '2'
         },
         {
             label: 'March',
             value: '3'
         },
         {
             label: 'April',
             value: '4'
         },
         {
             label: 'May',
             value: '5'
         },
         {
             label: 'June',
             value: '6'
         },
         {
             label: 'July',
             value: '7'
         },
         {
             label: 'August',
             value: '8'
         },
         {
             label: 'September',
             value: '9'
         },
         {
             label: 'October',
             value: '10'
         },
         {
             label: 'November',
             value: '11'
         },
         {
             label: 'December',
             value: '12'
         }
     ];
     const yearsLov = [
         {
             label: '2018',
             value: '1'
         },
         {
             label: '2019',
             value: '2'
         },
         {
             label: '2020',
             value: '3'
         },
         {
             label: '2021',
             value: '4'
         },
         {
             label: '2022',
             value: '5'
         },
         {
             label: '2023',
             value: '6'
         },
         {
             label: '2024',
             value: '7'
         }
    ];
    const projectLov = [
        {
            label: 'Example Project 1',
            value: '1'
        },
        {
            label: 'Example Project 2',
            value: '2'
        },
        {
            label: 'Example Project 3',
            value: '3'
        }
    ];

    return (
        <div>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4" style={{ marginLeft: 'auto' }}>
                            <div className="p-field">
                                <label htmlFor="project" className={classNames({ 'p-error': isFormFieldValid('project') }, 'Label__Text')}>
                                    Project<span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    filter
                                    id="project"
                                    placeholder="Please select project"
                                    options={projectLov}
                                    optionLabel="label"
                                    optionValue="value"
                                    name="project"
                                    value={formik.values.project || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('project') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('project')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4" style={{ marginLeft: 'auto' }}>
                            <div className="p-field">
                                <label htmlFor="month" className={classNames({ 'p-error': isFormFieldValid('month') }, 'Label__Text')}>
                                    Month<span className="Label__Required">*</span>
                                </label>
                                <MultiSelect
                                    filter
                                    id="month"
                                    placeholder="Please select month"
                                    options={monthsLov}
                                    optionLabel="label"
                                    optionValue="value"
                                    name="month"
                                    value={formik.values.month || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('month') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('month')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="year" className={classNames({ 'p-error': isFormFieldValid('year') }, 'Label__Text')}>
                                    Year<span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    filter
                                    id="year"
                                    placeholder="Please select year"
                                    options={yearsLov}
                                    optionLabel="label"
                                    optionValue="value"
                                    name="year"
                                    value={formik.values.year || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('year') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('year')}
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div className="card">
                <h5>Stats</h5>
                <Chart type="bar" data={basicData} options={basicOptions} />
            </div>
            <div className="card">
                <div className="grid">
                    <div className="col-6 flex justify-content-center">
                        <Chart type="doughnut" data={donoughtChartData} options={lightOptions} style={{ position: 'relative', width: '80%' }} />
                    </div>
                    <div className="col-6">
                        <div className="w-full flex justify-content-center">
                            <Chart type="polarArea" data={polarChartData} style={{ position: 'relative', width: '40%' }} />
                        </div>
                        <div className="w-full flex justify-content-center">
                            <Chart type="pie" data={pieChartData} style={{ position: 'relative', width: '40%' }} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="card">
                <h5>Example Heading</h5>
                <Chart type="bar" data={comboChartData} options={lightOptions} />
            </div>
        </div>
    );
};

export default Dashboard;
