import { useFormik } from "formik";
import { InputText } from "primereact/inputtext";
import React, { useEffect } from "react";
import classNames from "classnames";
import { Button } from "primereact/button";
import * as Yup from 'yup'
import { useDispatch } from "react-redux";
import { handlePostRequest } from "../../services/PostTemplate";

function DeductionDialog({ dialogData, onHideDeductionDialog, employeeId }) {
   
    const dispatch = useDispatch();
    const validationSchema = Yup.object().shape({
        month: Yup.string().required('This fiels is required')
    });
    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            lwp: null,
            advances: null,
            incomeTax: null,
            eobi: null,
            arrears: null,
            month: ''
        },
        onSubmit: async (data) => {
            data['employeeId'] = employeeId;
            const date = new Date(data?.month);
            const month = date.getMonth() + 1;
            const year = date.getFullYear();
            data['month'] = `${month}/${year}`;

            const response = await dispatch(handlePostRequest(data, '/payroll/savesalarydeduction', true));
            if (response?.responseCode === 200) {
                onHideDeductionDialog();
                window.location.reload();
            }
        }
    });

    const mappValues = () => {
        formik.setFieldValue('eobi', dialogData?.eobi);
        formik.setFieldValue('incomeTax', dialogData?.incomeTax);
    };
    useEffect(() => {
        mappValues()
    }, [dialogData])
    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };
    return (
        <>
            <div className="card">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-12">
                            <div className="p-field mb-2">
                                <label htmlFor="lwp" className={classNames({ 'p-error': isFormFieldValid('lwp') }, 'Label__Text')}>
                                    Unpaid Leaves charges
                                </label>
                                <InputText
                                    keyfilter="int"
                                    maxLength={6}
                                    id="lwp"
                                    placeholder="Please Enter Unpaid Leaves Amount"
                                    name="lwp"
                                    onChange={formik.handleChange}
                                    value={formik?.values?.lwp || ''}
                                    className={classNames({ 'p-invalid': isFormFieldValid('lwp') }, 'Input__Round')}
                                />
                            </div>
                            <div className="p-field mb-2">
                                <div className="p-field">
                                    <label htmlFor="advances" className={classNames({ 'p-error': isFormFieldValid('advances') }, 'Label__Text')}>
                                        Advance/Loan
                                    </label>
                                    <InputText
                                        keyfilter="int"
                                        maxLength={6}
                                        id="advances"
                                        placeholder="Please Enter Advance/Loan Amount"
                                        name="advances"
                                        value={formik?.values?.advances || ''}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('advances') }, 'Input__Round')}
                                    />
                                </div>
                            </div>
                            <div className="p-field mb-2">
                                <div className="p-field">
                                    <label htmlFor="incomeTax" className={classNames({ 'p-error': isFormFieldValid('incomeTax') }, 'Label__Text')}>
                                        Income Tax
                                    </label>
                                    <InputText
                                        disabled
                                        keyfilter="int"
                                        maxLength={6}
                                        id="incomeTax"
                                        placeholder="Please Enter Income Tax"
                                        name="incomeTax"
                                        value={formik?.values?.incomeTax || ''}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('incomeTax') }, 'Input__Round')}
                                    />
                                </div>
                            </div>
                            <div className="p-field mb-2">
                                <div className="p-field">
                                    <label htmlFor="eobi" className={classNames({ 'p-error': isFormFieldValid('eobi') }, 'Label__Text')}>
                                        EOBI
                                    </label>
                                    <InputText
                                        disabled
                                        keyfilter="int"
                                        maxLength={6}
                                        id="eobi"
                                        placeholder="Please Enter EOBI"
                                        name="eobi"
                                        value={formik?.values?.eobi || ''}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('eobi') }, 'Input__Round')}
                                    />
                                </div>
                            </div>
                            <div className="p-field mb-2">
                                <div className="p-field">
                                    <label htmlFor="month" className={classNames({ 'p-error': isFormFieldValid('month') }, 'Label__Text')}>
                                        Select Month
                                    </label>
                                    <InputText
                                        type="date"
                                        id="month"
                                        placeholder="Please Enter month"
                                        name="month"
                                        value={formik?.values?.month || ''}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('month') }, 'Input__Round')}
                                    />
                                </div>
                                {getFormErrorMessage('month')}
                            </div>
                        </div>
                    </div>
                    <div className="flex justify-content-center">
                        <Button className="update__button" label="Save" type="submit" style={{ border: 'none' }} />
                        <Button
                            className="cancel__button ml-2"
                            label="Cancel"
                            onClick={(e) => {
                                e.preventDefault();
                                onHideDeductionDialog();
                            }}
                        />
                    </div>
                </form>
            </div>
        </>
    );
}

export default DeductionDialog