import React, { useState } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { MultiSelect } from 'primereact/multiselect';
import { Chart } from 'primereact/chart';

function ProjectsStats() {
    const [projectStats] = useState({
        labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
        datasets: [
            {
                label: 'Invoicing Sent',
                backgroundColor: '#49464d',
                data: [65, 59, 80, 81, 56, 55, 40, 55, 48, 88, 44, 22]
            },
            {
                label: 'Amount Recieved',
                backgroundColor: '#f8a91b',
                data: [28, 48, 40, 19, 86, 27, 90, 77, 44, 66, 88, 70]
            }
        ]
    });

    const basicOptions = {
        maintainAspectRatio: false,
        aspectRatio: 0.8,
        plugins: {
            legend: {
                labels: {
                    color: '#495057'
                }
            },
            tooltips: {
                mode: 'index',
                intersect: true
            }
        },
        scales: {
            x: {
                ticks: {
                    color: '#495057'
                },
                grid: {
                    color: '#ebedef'
                }
            },
            y: {
                type: 'linear',
                display: true,
                position: 'left',
                ticks: {
                    min: 0,
                    max: 100,
                    color: '#495057'
                },
                grid: {
                    color: '#ebedef'
                }
            },
            y1: {
                type: 'linear',
                display: true,
                position: 'right',
                grid: {
                    drawOnChartArea: false,
                    color: '#ebedef'
                },
                ticks: {
                    min: 0,
                    max: 100,
                    color: '#495057'
                }
            }
        }
    };

     const [pieChartData] = useState({
         labels: ['Total Resources Required', 'Resources Assigned', 'Resources Required'],
         datasets: [
             {
                 data: [300, 50, 100],
                 backgroundColor: ['#42A5F5', '#66BB6A', '#FFA726'],
                 hoverBackgroundColor: ['#64B5F6', '#81C784', '#FFB74D']
             }
         ]
     });
     const [basicData] = useState({
         labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
         datasets: [
             {
                 label: 'Tech Stack 1',
                 data: [65, 59, 80, 81, 56, 55, 40],
                 fill: false,
                 borderColor: '#42A5F5',
                 tension: 0.4
             },
             {
                 label: 'Tech Stack 2',
                 data: [28, 48, 40, 19, 86, 27, 90],
                 fill: false,
                 borderColor: '#FFA726',
                 tension: 0.4
             }
         ]
     });

     const [lightOptions] = useState({
         plugins: {
             legend: {
                 labels: {
                     color: '#495057'
                 }
             }
         }
     });

    const formik = useFormik({
        initialValues: {
            name: '',
            month: '',
            year: ''
        }
    });
    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

     const projectList = [
         {
             label: 'Example Project',
             value: '1'
         },
         {
             label: 'Example Project 2',
             value: '2'
         },
         {
             label: 'Example Project 3',
             value: '3'
         }
    ];
    const monthsLov = [
        {
            label: 'January',
            value: '1'
        },
        {
            label: 'Febraury',
            value: '2'
        },
        {
            label: 'March',
            value: '3'
        },
        {
            label: 'April',
            value: '4'
        },
        {
            label: 'May',
            value: '5'
        },
        {
            label: 'June',
            value: '6'
        },
        {
            label: 'July',
            value: '7'
        },
        {
            label: 'August',
            value: '8'
        },
        {
            label: 'September',
            value: '9'
        },
        {
            label: 'October',
            value: '10'
        },
        {
            label: 'November',
            value: '11'
        },
        {
            label: 'December',
            value: '12'
        }
    ];
    const yearsLov = [
        {
            label: '2018',
            value: '1'
        },
        {
            label: '2019',
            value: '2'
        },
        {
            label: '2020',
            value: '3'
        },
        {
            label: '2021',
            value: '4'
        },
        {
            label: '2022',
            value: '5'
        },
        {
            label: '2023',
            value: '6'
        },
        {
            label: '2024',
            value: '7'
        }
    ];
    return (
        <>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="name" className={classNames({ 'p-error': isFormFieldValid('name') }, 'Label__Text')}>
                                    Project<span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    filter
                                    id="name"
                                    placeholder="Please select project"
                                    options={projectList}
                                    optionLabel="label"
                                    optionValue="value"
                                    name="name"
                                    value={formik.values.name || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('name') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('name')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="month" className={classNames({ 'p-error': isFormFieldValid('month') }, 'Label__Text')}>
                                    Month<span className="Label__Required">*</span>
                                </label>
                                <MultiSelect
                                    filter
                                    id="month"
                                    placeholder="Please select month"
                                    options={monthsLov}
                                    optionLabel="label"
                                    optionValue="value"
                                    name="month"
                                    value={formik.values.month || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('month') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('month')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="year" className={classNames({ 'p-error': isFormFieldValid('year') }, 'Label__Text')}>
                                    Year<span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    filter
                                    id="year"
                                    placeholder="Please select year"
                                    options={yearsLov}
                                    optionLabel="label"
                                    optionValue="value"
                                    name="year"
                                    value={formik.values.year || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('year') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('year')}
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div className="card">
                <h5>Project Stats</h5>
                <Chart type="bar" data={projectStats} options={basicOptions} />
            </div>
            <div className="card">
                <div className="gird flex">
                    <div className="col-4">
                        <Chart type="pie" data={pieChartData} options={lightOptions} style={{ position: 'relative', width: '100%' }} />
                    </div>
                    <div className="col-7 flex justify-content-center">
                        <Chart type="line" data={basicData} style={{ position: 'relative', width: '100%' }} />
                    </div>
                </div>
            </div>
        </>
    );
}

export default ProjectsStats;
