import React, { useEffect, useState } from 'react'
import image from '../../assets/images/dummyImage.png'
import { useFormik } from 'formik';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import classNames from 'classnames';
import { Dropdown } from 'primereact/dropdown';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { MultiSelect } from 'primereact/multiselect';
import { handleGetRequest } from '../../services/GetTemplate';

function ConsolidatedData() {
    const [employeeData, setEmployeeData] = useState();
    const [consolidatedList, setConsolidatedList] = useState();
    const [projectList, setProjectList] = useState();

     const formik = useFormik({
         initialValues: {
             officeId: '',
             name: '',
             image: '',
             cnic: '',
             department: '',
             designation: '',
             location: '',
             monthlyHours: '',
             ratePerHour: '',
             experience: '',
             status: '',
             allocatedProjects: ''
         }
     });

     const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
     const getFormErrorMessage = (name) => {
         return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };
    

    const projectLov = [
        {
            label: 'Example Project 1',
            value: '1'
        },
        {
            label: 'Example Project 2',
            value: '2'
        },
        {
            label: 'Example Project 3',
            value: '3'
        }
    ];

    const oldEntriesData = [
        {
            cnic: '23254125485',
            name: 'Example name',
            department: 'Example Department',
            designation: 'Example designation',
            location: 'Isb',
            experience: '1.4',
            ratePerHour: '123',
            monthlyHours: '184',
            projects: 'Example Project',
            projectId: '1'
        },
        {
            cnic: '23254125485',
            name: 'Example name',
            department: 'Example Department',
            designation: 'Example designation',
            location: 'Isb',
            experience: '1.4',
            ratePerHour: '123',
            monthlyHours: '184',
            projects: 'Example Project',
            projectId: '2'
        },
        {
            cnic: '23254125485',
            name: 'Example name',
            department: 'Example Department',
            designation: 'Example designation',
            location: 'Isb',
            experience: '1.4',
            ratePerHour: '123',
            monthlyHours: '184',
            projects: 'Example Project',
            projectId: '3'
        },
        {
            cnic: '23254125485',
            name: 'Example name',
            department: 'Example Department',
            designation: 'Example designation',
            location: 'Isb',
            experience: '1.4',
            ratePerHour: '123',
            monthlyHours: '184',
            projects: 'Example Project',
            projectId: '2'
        },
        {
            cnic: '23254125485',
            name: 'Example name',
            department: 'Example Department',
            designation: 'Example designation',
            location: 'Isb',
            experience: '1.4',
            ratePerHour: '123',
            monthlyHours: '184',
            projects: 'Example Project',
            projectId: '1'
        },
        {
            cnic: '23254125485',
            name: 'Example name',
            department: 'Example Department',
            designation: 'Example designation',
            location: 'Isb',
            experience: '1.4',
            ratePerHour: '123',
            monthlyHours: '184',
            projects: 'Example Project',
            projectId: '3'
        }
    ];

    const handleEdit = (rowData) => {
        formik.setFieldValue('cnic', rowData?.cnic)
        formik.setFieldValue('name', rowData?.name);
        formik.setFieldValue('department', rowData?.department);
        formik.setFieldValue('designation', rowData?.designation);
        formik.setFieldValue('location', rowData?.location);
        formik.setFieldValue('experience', rowData?.experience);
        formik.setFieldValue('ratePerHour', rowData?.ratePerHour);
        formik.setFieldValue('monthlyHours', rowData?.monthlyHours);
        formik.setFieldValue('allocatedProjects', rowData?.projectId);
    }

    const actionTemplate = (rowData) => {
        return (
            <div className="flex">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-info mr-1" onClick={()=>handleEdit(rowData)}/>
                <Button icon="pi pi-trash" style={{ border: 'none' }} className="p-button-rounded p-button-danger mr-1" />
            </div>
        );
    }

       const monthsLov = [
           {
               label: 'January',
               value: '1'
           },
           {
               label: 'Febraury',
               value: '2'
           },
           {
               label: 'March',
               value: '3'
           },
           {
               label: 'April',
               value: '4'
           },
           {
               label: 'May',
               value: '5'
           },
           {
               label: 'June',
               value: '6'
           },
           {
               label: 'July',
               value: '7'
           },
           {
               label: 'August',
               value: '8'
           },
           {
               label: 'September',
               value: '9'
           },
           {
               label: 'October',
               value: '10'
           },
           {
               label: 'November',
               value: '11'
           },
           {
               label: 'December',
               value: '12'
           }
       ];
       const yearsLov = [
           {
               label: '2018',
               value: '1'
           },
           {
               label: '2019',
               value: '2'
           },
           {
               label: '2020',
               value: '3'
           },
           {
               label: '2021',
               value: '4'
           },
           {
               label: '2022',
               value: '5'
           },
           {
               label: '2023',
               value: '6'
           },
           {
               label: '2024',
               value: '7'
           }
    ];
    
    const getEmployeeData = async () => {
        const response = await handleGetRequest(`/consolidationdata/getdatabycnic/${formik.values.cnic}`, true); 
        setEmployeeData(response?.data);
    }
    
    const getAllProjects = async () => {
        const response = await handleGetRequest('/list/getallprojects', true);
        if (response?.responseCode === 200) {
            setProjectList(response?.data)
        }
    }
    const [departmentList , setDepartmentList] = useState()
    const getDepartmentList = async () => {
        const response = await handleGetRequest('/list/alldepartments', true);
        if (response?.responseCode === 200) {
            setDepartmentList(response?.data)
        }
    }
    const getAllConsolidatedData = async () => {
        const res = await handleGetRequest('/consolidationdata/getallconsolidationdata', true);
        if (res?.responseCode === 200) {
            setConsolidatedList(res?.data);
        }
    };
    useEffect(() => {
        getAllProjects()
        getDepartmentList()
        getDepartmentList()
        getAllConsolidatedData()
    }, [])

    useEffect(() => {
        if (employeeData) {
            formik.setFieldValue('name', `${employeeData?.firstName} ${employeeData.lastName}`)
            formik.setFieldValue('departmentId', employeeData.departmentId)
            formik.setFieldValue('designation', employeeData.rank);
            formik.setFieldValue('location', employeeData.location);
            formik.setFieldValue('experience', employeeData.experience);
            formik.setFieldValue('ratePerHour', employeeData.ratePerHour);
        }
    }, [employeeData])

    const projects = employeeData?.projectDetails?.map((item) => {
        return {
            projectId: item?.projectId,
        }
    })
    
    const selectedProjects = projectList?.filter((project) => projects?.some((empProject) => empProject.projectId === project.id));

    const locationLov = [
        {
            label: 'Islamabad',
            value: 'isb'
        },
        {
            label: 'Lahore',
            value: 'lhr'
        },
        {
            label: 'Karachi',
            value: 'khi'
        }
    ];

    const getAllocatedHours = () => {
        if (employeeData) {
            const selectedProject = formik.values.allocatedProjects;
            const filterHours = employeeData?.projectDetails?.filter((item) => item.projectId === selectedProject)
            formik.setFieldValue('allocatedHours', filterHours[0]?.allocatedHours);
        }
    }
    useEffect(() => {
        getAllocatedHours();
    }, [formik.values.allocatedProjects]);

    const departmentTemplate = (rowData) => {
        const departmentName = departmentList?.filter((item) => item.id === rowData?.department);
        return <p>{departmentName[0].departmentName}</p>;
    }
    const projectTemplate = (rowData) => {
        const projectName = projectList?.filter((item) => item.id === rowData?.projectId);
        return <p>{projectName[0].projectName}</p>;
    };

      
  return (
      <>
          <div className="card Card__Round">
              <h4 className="mt-3">Consolidated Data</h4>
          </div>

          <div className="card">
              <div className="grid">
                  <div className="col-8">
                      <div className="Card__Round">
                          <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                              <div className="p-fluid p-formgrid grid mb-5">
                                  <div className="p-field col-6 md:col-6">
                                      <div className="p-field">
                                          <label htmlFor="cnic" className={classNames({ 'p-error': isFormFieldValid('cnic') }, 'Label__Text')}>
                                              CNIC<span className="Label__Required">*</span>
                                          </label>
                                          <InputText
                                              maxLength={20}
                                              id="cnic"
                                              placeholder="Please Enter CNIC"
                                              name="cnic"
                                              value={formik?.values?.cnic?.toString() || ''}
                                              onChange={formik.handleChange}
                                              className={classNames({ 'p-invalid': isFormFieldValid('cnic') }, 'Input__Round')}
                                          />

                                          {getFormErrorMessage('cnic')}
                                      </div>
                                  </div>
                                  <div className="p-field col-6 md:col-6">
                                      <Button disabled={!formik.values.cnic} label="Get Data Against CNIC" className="update__button mt-4" onClick={getEmployeeData} />
                                  </div>
                                  <div className="p-field col-12 md:col-6">
                                      <div className="p-field">
                                          <label htmlFor="month" className={classNames({ 'p-error': isFormFieldValid('month') }, 'Label__Text')}>
                                              Month<span className="Label__Required">*</span>
                                          </label>
                                          <Dropdown
                                              filter
                                              id="month"
                                              placeholder="Please select month"
                                              options={monthsLov}
                                              optionLabel="label"
                                              optionValue="value"
                                              name="month"
                                              value={formik.values.month || ''}
                                              onChange={formik.handleChange}
                                              className={classNames({ 'p-invalid': isFormFieldValid('month') }, 'Dropdown__Round')}
                                          />
                                          {getFormErrorMessage('month')}
                                      </div>
                                  </div>
                                  <div className="p-field col-6 md:col-6">
                                      <div className="p-field">
                                          <label htmlFor="name" className={classNames({ 'p-error': isFormFieldValid('name') }, 'Label__Text')}>
                                              Name<span className="Label__Required">*</span>
                                          </label>
                                          <InputText
                                              disabled
                                              maxLength={20}
                                              id="name"
                                              placeholder="Please Enter Name"
                                              name="name"
                                              value={formik?.values?.name?.toString() || ''}
                                              onChange={formik.handleChange}
                                              className={classNames({ 'p-invalid': isFormFieldValid('name') }, 'Input__Round')}
                                          />

                                          {getFormErrorMessage('name')}
                                      </div>
                                  </div>
                                  <div className="p-field col-6 md:col-6">
                                      <div className="p-field">
                                          <label htmlFor="departmentId" className={classNames({ 'p-error': isFormFieldValid('departmentId') }, 'Label__Text')}>
                                              Department<span className="Label__Required">*</span>
                                          </label>
                                          <Dropdown
                                              disabled
                                              maxLength={20}
                                              options={departmentList}
                                              optionLabel="departmentName"
                                              optionValue="id"
                                              id="departmentId"
                                              placeholder="Please Enter Name"
                                              name="departmentId"
                                              value={formik?.values?.departmentId || ''}
                                              onChange={formik.handleChange}
                                              className={classNames({ 'p-invalid': isFormFieldValid('departmentId') }, 'Dropdown__Round')}
                                          />

                                          {getFormErrorMessage('departmentId')}
                                      </div>
                                  </div>
                                  <div className="p-field col-6 md:col-6">
                                      <div className="p-field">
                                          <label htmlFor="designation" className={classNames({ 'p-error': isFormFieldValid('designation') }, 'Label__Text')}>
                                              Designation<span className="Label__Required">*</span>
                                          </label>
                                          <InputText
                                              disabled
                                              maxLength={20}
                                              id="designation"
                                              placeholder="Please Enter Office Id"
                                              name="designation"
                                              value={formik?.values?.designation?.toString() || ''}
                                              onChange={formik.handleChange}
                                              className={classNames({ 'p-invalid': isFormFieldValid('designation') }, 'Input__Round')}
                                          />

                                          {getFormErrorMessage('designation')}
                                      </div>
                                  </div>
                                  <div className="p-field col-6 md:col-6">
                                      <div className="p-field">
                                          <label htmlFor="location" className={classNames({ 'p-error': isFormFieldValid('location') }, 'Label__Text')}>
                                              Location<span className="Label__Required">*</span>
                                          </label>
                                          <Dropdown
                                              disabled
                                              options={locationLov}
                                              optionLabel="label"
                                              optionValue="value"
                                              maxLength={20}
                                              id="location"
                                              placeholder="Please Enter location"
                                              name="location"
                                              value={formik?.values?.location || ''}
                                              onChange={formik.handleChange}
                                              className={classNames({ 'p-invalid': isFormFieldValid('location') }, 'Dropdown__Round')}
                                          />

                                          {getFormErrorMessage('location')}
                                      </div>
                                  </div>

                                  <div className="p-field col-6 md:col-6">
                                      <div className="p-field">
                                          <label htmlFor="experience" className={classNames({ 'p-error': isFormFieldValid('experience') }, 'Label__Text')}>
                                              Experience<span className="Label__Required">*</span>
                                          </label>
                                          <InputText
                                              disabled
                                              maxLength={20}
                                              id="experience"
                                              placeholder="Please Enter Rate Per Hour"
                                              name="experience"
                                              value={formik?.values?.experience?.toString() || ''}
                                              onChange={formik.handleChange}
                                              className={classNames({ 'p-invalid': isFormFieldValid('experience') }, 'Input__Round')}
                                          />

                                          {getFormErrorMessage('experience')}
                                      </div>
                                  </div>
                                  <div className="p-field col-6 md:col-6">
                                      <div className="p-field">
                                          <label htmlFor="ratePerHour" className={classNames({ 'p-error': isFormFieldValid('ratePerHour') }, 'Label__Text')}>
                                              Rate Per Hour<span className="Label__Required">*</span>
                                          </label>
                                          <InputText
                                              disabled
                                              maxLength={20}
                                              id="ratePerHour"
                                              placeholder="Please Enter Rate Per Hour"
                                              name="ratePerHour"
                                              value={formik?.values?.ratePerHour?.toString() || ''}
                                              onChange={formik.handleChange}
                                              className={classNames({ 'p-invalid': isFormFieldValid('ratePerHour') }, 'Input__Round')}
                                          />

                                          {getFormErrorMessage('ratePerHour')}
                                      </div>
                                  </div>

                                  <div className="p-field col-6 md:col-6">
                                      <div className="p-field">
                                          <label htmlFor="allocatedProjects" className={classNames({ 'p-error': isFormFieldValid('allocatedProjects') }, 'Label__Text')}>
                                              Allocated Projects<span className="Label__Required">*</span>
                                          </label>
                                          <Dropdown
                                              filter
                                              options={selectedProjects}
                                              optionLabel="projectName"
                                              optionValue="id"
                                              id="allocatedProjects"
                                              placeholder="Please Select Project"
                                              name="allocatedProjects"
                                              value={formik?.values?.allocatedProjects || ''}
                                              onChange={formik.handleChange}
                                              className={classNames({ 'p-invalid': isFormFieldValid('allocatedProjects') }, 'Dropdown__Round')}
                                          />

                                          {getFormErrorMessage('allocatedProjects')}
                                      </div>
                                  </div>
                                  <div className="p-field col-6 md:col-6">
                                      <div className="p-field">
                                          <label htmlFor="allocatedHours" className={classNames({ 'p-error': isFormFieldValid('allocatedHours') }, 'Label__Text')}>
                                              Allocated Hours<span className="Label__Required">*</span>
                                          </label>
                                          <InputText
                                              disabled
                                              keyfilter="int"
                                              maxLength={20}
                                              id="allocatedHours"
                                              placeholder="Please Enter Monthly Hours"
                                              name="allocatedHours"
                                              value={formik?.values?.allocatedHours || ''}
                                              onChange={formik.handleChange}
                                              className={classNames({ 'p-invalid': isFormFieldValid('allocatedHours') }, 'Input__Round')}
                                          />

                                          {getFormErrorMessage('allocatedHours')}
                                      </div>
                                  </div>
                                  <div className="p-field col-6 md:col-6">
                                      <div className="p-field">
                                          <Button label="Update" className="update__button mt-4" style={{ border: 'none' }} />
                                      </div>
                                  </div>
                              </div>
                          </form>
                      </div>
                  </div>
                  <div className="col-4 flex justify-content-center align-item-center">
                      <img src={image} style={{ height: '70%' }} />
                  </div>
              </div>
          </div>

          <div className="card Card__Round">
              <h4 className="mt-3">Recent Entries</h4>
          </div>
          <div className="card Card__Round">
              <form onSubmit={formik.handleSubmit} className="p-fluid">
                  <div className="p-fluid p-formgrid grid mb-5">
                      <div className="p-field col-12 md:col-4" style={{ marginLeft: 'auto' }}>
                          <div className="p-field">
                              <label htmlFor="project" className={classNames({ 'p-error': isFormFieldValid('project') }, 'Label__Text')}>
                                  Project<span className="Label__Required">*</span>
                              </label>
                              <Dropdown
                                  filter
                                  id="project"
                                  placeholder="Please select project"
                                  options={projectLov}
                                  optionLabel="label"
                                  optionValue="value"
                                  name="project"
                                  value={formik.values.project || ''}
                                  onChange={formik.handleChange}
                                  className={classNames({ 'p-invalid': isFormFieldValid('project') }, 'Dropdown__Round')}
                              />
                              {getFormErrorMessage('project')}
                          </div>
                      </div>
                      <div className="p-field col-12 md:col-4" style={{ marginLeft: 'auto' }}>
                          <div className="p-field">
                              <label htmlFor="month" className={classNames({ 'p-error': isFormFieldValid('month') }, 'Label__Text')}>
                                  Month<span className="Label__Required">*</span>
                              </label>
                              <MultiSelect
                                  filter
                                  id="month"
                                  placeholder="Please select month"
                                  options={monthsLov}
                                  optionLabel="label"
                                  optionValue="value"
                                  name="month"
                                  value={formik.values.month || ''}
                                  onChange={formik.handleChange}
                                  className={classNames({ 'p-invalid': isFormFieldValid('month') }, 'Dropdown__Round')}
                              />
                              {getFormErrorMessage('month')}
                          </div>
                      </div>
                      <div className="p-field col-12 md:col-4">
                          <div className="p-field">
                              <label htmlFor="year" className={classNames({ 'p-error': isFormFieldValid('year') }, 'Label__Text')}>
                                  Year<span className="Label__Required">*</span>
                              </label>
                              <Dropdown
                                  filter
                                  id="year"
                                  placeholder="Please select year"
                                  options={yearsLov}
                                  optionLabel="label"
                                  optionValue="value"
                                  name="year"
                                  value={formik.values.year || ''}
                                  onChange={formik.handleChange}
                                  className={classNames({ 'p-invalid': isFormFieldValid('year') }, 'Dropdown__Round')}
                              />
                              {getFormErrorMessage('year')}
                          </div>
                      </div>
                  </div>
              </form>
          </div>
          <div className="card">
              <DataTable value={consolidatedList}>
                  <Column field="cnic" header="CNIC" />
                  <Column field="employeeName" header="Employee Name" />
                  <Column body={departmentTemplate} header="Department" />
                  <Column field="designation" header="Designation" />
                  <Column field="location" header="Location" />
                  <Column field="experience" header="Experience" />
                  <Column field="ratePerHour" header="Rate per Hour" />
                  <Column field="monthlyHours" header="Monthly Hours" />
                  <Column body={projectTemplate} header="Project" />
                  <Column body={actionTemplate} header="Actions" />
              </DataTable>
          </div>
      </>
  );
}

export default ConsolidatedData